import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Switch, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import DialogTitle from '../custom/dialog-title'
import { componentsInfo } from '../../helpers/constants'
import TextButton from '../custom/text-button'
import LITERALS from '../../helpers/literals'
import Dropdown from '../custom/dropdown'

const DelayNodeSettings = ({ language, toast, node, update, cancel }) => {
	const [id, setId] = useState(null)
	const [days, setDays] = useState(Array(7).fill(false))
	const [times, setTimes] = useState(Array(7).fill({ start: '00:00', end: '00:00' }))
	const [delay, setDelay] = useState(5)
	const [items, setItems] = useState([])
	const [type, setType] = useState(1)

	const theme = useTheme()

	useEffect(() => {
		if (node?.id !== id) {
			setDays(node?.data?.days || Array(7).fill(false))
			setTimes(node?.data?.times || Array(7).fill({ start: '00:00', end: '00:00' }))
			setDelay(node?.data?.delay || 5)
			setType(node?.data?.type || 60)
			setId(node?.id)
		}
	}, [id, node])


	useEffect(() => {
		setItems(Array(48).fill(0).map((_, i) => ({
			id: `${`${Math.floor(i / 2)}`.padStart(2, '0')}:${i % 2 === 0 ? '00' : '30'}`,
			name: `${`${Math.floor(i / 2)}`.padStart(2, '0')}:${i % 2 === 0 ? '00' : '30'}`
		})))
	}, [])

	const save = useCallback(() => {
		update({ ...node, data: { ...node.data, days, times, delay, type } })
	}, [days, delay, node, times, type, update])

	return (
		<Box sx={styles.main}>
			<DialogTitle
				title={componentsInfo[node.type].title[language]}
				description={componentsInfo[node.type].description[language]}
				cancel={cancel}
			/>

			<Box sx={styles.row}>
				<Typography>{LITERALS.DELAY[language]}</Typography>

				<Box>

				</Box>
			</Box>

			<Box sx={styles.middle}>
				<Box sx={styles.items}>
					<TextButton title={LITERALS.WORKING_TIME[language]} active color={theme.palette.primary.delayColor} onClick={() => { }} />
				</Box>
				<Box sx={styles.body}>
					{
						days.map((day, idx) => <Box key={idx} sx={styles.row}>
							<Box sx={styles.switch}>
								<Switch
									checked={!!day}
									onChange={e => {
										setDays(_days => [..._days.map((d, i) => i === idx ? e.target.checked : d)])
									}}
								/>
								<Typography variant='h6'>{LITERALS.WEEKDAYS[language][idx]}</Typography>
							</Box>
							<Box sx={styles.dropdown}>
								<Typography variant='h6' sx={styles.dropdownText}>{LITERALS.FROM[language]}: </Typography>
								<Dropdown
									container={{ width: 100 }}
									selected={times[idx].start}
									items={items}
									onChange={it => setTimes(_times => [..._times.map((d, i) => ({ start: i === idx ? it : d.start, end: d.end }))])}
								/>
							</Box>
							<Box sx={styles.dropdown}>
								<Typography variant='h6' sx={styles.dropdownText}>{LITERALS.TO[language]}: </Typography>
								<Dropdown
									container={{ width: 100 }}
									selected={times[idx].end}
									items={items}
									onChange={it => setTimes(_times => [..._times.map((d, i) => ({ start: d.start, end: i === idx ? it : d.end }))])}
								/>
							</Box>
						</Box>)
					}
				</Box>
			</Box>

			<Box sx={styles.buttonsContainer}>
				<Button variant='contained' color='primary' sx={styles.button} onClick={save}>{LITERALS.SAVE[language]}</Button>
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2,
		mb: 2
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	middle: {
		width: '100%',
		height: 'calc(100% - 239px)',
		py: 2,
		display: 'flex',
		flexDirection: 'column'
	},
	items: {
		width: '100%',
		height: 49,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 1,
		px: 1.5,
		overflowX: 'auto'
	},
	body: {
		width: '100%',
		height: 'calc(100% - 49px)',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		alignItems: 'center',
		px: 2,
		py: 1
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	},
	switch: {
		width: '35%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'start'
	},
	dropdown: {
		width: '30%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end'
	},
	dropdownText: {
		mr: 1
	}
}

export default DelayNodeSettings