import React, { useEffect, useState } from 'react'
import { Box, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from '@mui/material'
import { DeleteForeverRounded, Visibility, VisibilityOff } from '@mui/icons-material'

import LITERALS from '../../helpers/literals'

const KeyValueItem = ({ language, title, divider, name, nameChanged, value, valueChanged, onDelete }) => {
	const [show, setShow] = useState(false)

	useEffect(() => { if (!value) setShow(true) }, [value])

	return <Box sx={styles.main}>
		{divider && <Box sx={styles.divider} />}
		<Typography fontWeight='bold' variant='h6'>{title}</Typography>
		<Box sx={styles.container}>
			<TextField
				sx={styles.textField}
				placeholder={LITERALS.NAME_2[language]}
				value={name}
				onChange={e => nameChanged(e.target.value)}
			/>

			<OutlinedInput
				size='small'
				sx={styles.input}
				disabled={!show}
				placeholder={LITERALS.VALUE[language]}
				value={show ? value : '●'.repeat(value.length)}
				onChange={e => valueChanged(e.target.value)}
				endAdornment={
					<InputAdornment position='end'>
						<IconButton sx={styles.iconButton} aria-label='toggle password visibility' onClick={() => { setShow(!show) }} onMouseDown={() => { }} edge='end'>
							{show ? <VisibilityOff sx={styles.icon2} /> : <Visibility sx={styles.icon2} />}
						</IconButton>
					</InputAdornment>
				}
			/>

			<Box sx={styles.iconContainer} onClick={onDelete}>
				<DeleteForeverRounded sx={styles.icon} />
			</Box>
		</Box>
	</Box>
}

const styles = {
	main: {
		py: 1,
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	container: {
		pt: 1.25,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	textField: {
		width: 'calc(50% - 30px)'
	},
	iconContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		fontSize: 28,
		color: theme => theme.palette.error.main
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		mb: 2
	},
	input: {
		pr: 0,
		width: 'calc(50% - 30px)'
	},
	icon2: {
		fontSize: 18,
		color: theme => theme.palette.secondary.dark
	},
	iconButton: {
		mr: 1
	}
}

export default KeyValueItem