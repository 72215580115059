import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { v4 as uuidv4 } from 'uuid'
import {
	ReactFlow,
	ReactFlowProvider,
	applyNodeChanges,
	applyEdgeChanges,
	addEdge,
	Background,
	useReactFlow,
	getIncomers,
	getOutgoers,
	getConnectedEdges
} from '@xyflow/react'
import '@xyflow/react/dist/style.css'

import Navigation from './navigation'
import { Provider, useDnD } from './context'
import StartNode from '../nodes/start'
import ApiNode from '../nodes/api'
import CheckParamsNode from '../nodes/check-params'
import CheckConditionsNode from '../nodes/check-conditions'
import ChatAINode from '../nodes/chat-ai'
import FilterNode from '../nodes/filter'
import MapperNode from '../nodes/mapper'
import RunAPINode from '../nodes/run-api'
import ResultNode from '../nodes/result'
import DelayNode from '../nodes/delay'
import SaveVariablesNode from '../nodes/save-variables'
import Settings from './settings'
import { arraysEqual, checkEdges, initialData } from '../../helpers/utils'
import CustomControl from './custom-control'
import { ModeEditRounded } from '@mui/icons-material'
import UpsertDialog from '../scenarios/upsert-dialog'
import GroupButton from '../custom/group-button'
import TestDialog from './test'
import LITERALS from '../../helpers/literals'
import UpsertVersionDialog from '../scenarios/upsert-version-dialog'
import VersionsDialog from '../scenarios/versions-dialog'

const nodeTypes = {
	start: StartNode,
	api: ApiNode,
	checkParams: CheckParamsNode,
	runScenario: RunAPINode,
	checkConditions: CheckConditionsNode,
	mapper: MapperNode,
	filter: FilterNode,
	delay: DelayNode,
	save_variables: SaveVariablesNode,
	getResult: ResultNode,
	chatAI: ChatAINode
}

const Dataflow = ({
	history,
	match,
	language,
	scenarioPrivileges,
	scenarios,
	scenario,
	assistants,
	models,
	fetching,
	updating,
	recipient,
	testHistory,
	testing,
	versions,
	versionLoading,
	error,
	message,
	code,
	generating,
	aiError,
	aiMessage,
	optimizedCode,
	checking,
	toast,
	files,
	filesMessage,
	filesError,
	filesFetching,
	filesUploading,
	filesUpdating,
	filesDeleting,
	fetchFiles,
	upload,
	updateFile,
	removeFile,
	generate,
	check,
	get,
	update,
	getVersions,
	getVersion,
	upsertVersion,
	removeVersion,
	test,
	reset
}) => {
	const [initialized, setInitialized] = useState(false)
	const [_scenario, setScenario] = useState(null)
	const [nodeId, setNodeId] = useState(null)
	const [nodeIds, setNodeIds] = useState([])
	const [errorNodeId, setErrorNodeId] = useState('')
	const [nodes, setNodes] = useState([])
	const [edges, setEdges] = useState([])
	const [disabled, setDisabled] = useState(false)
	const [lastSelected, setLastSelected] = useState([null])
	const [showUpsertDialog, setShowUpsertDialog] = useState(false)
	const [testClicked, setTestClicked] = useState(false)
	const [showTestDialog, setShowTestDialog] = useState(false)
	const [showVersionSaveDialog, setShowVersionSaveDialog] = useState(false)
	const [versionClicked, setVersionClicked] = useState(false)
	const [showVersionsDialog, setShowVersionsDialog] = useState(false)
	const reactFlowWrapper = useRef(null)
	const { screenToFlowPosition } = useReactFlow()
	const [type] = useDnD()
	const theme = useTheme()

	useEffect(() => {
		if (!initialized) {
			setInitialized(true)
			const scen = scenarios.find(s => s.id === match.params.id)
			if (scen && (scenarioPrivileges.includes(`${match.params.id}-e`) || scenarioPrivileges.includes(`${match.params.id}-v`))) {
				setScenario(scen)
				get(scen.id)
				fetchFiles(scen.id)
			} else {
				history.push('/')
			}
		}
	}, [fetchFiles, get, history, initialized, match.params.id, scenarios, scenarioPrivileges])

	useEffect(() => {
		setScenario(scenarios.find(s => s.id === match.params.id))
	}, [match.params.id, scenarios])

	useEffect(() => {
		if (!scenarioPrivileges.includes(`${match.params.id}-e`))
			setDisabled(true)
	}, [match.params.id, scenarioPrivileges])

	useEffect(() => {
		const scen = [...scenario].findLast(sc => sc.name === 'init')
		try {
			setNodes(JSON.parse(JSON.stringify(scen?.parameters?.dataflow_data?.nodes || [])))
			setEdges(JSON.parse(JSON.stringify(scen?.parameters?.dataflow_data?.edges || [])))
		} catch { }
	}, [scenario])

	useEffect(() => {
		if (aiError)
			toast(aiError, { type: 'error' })
	}, [aiError, toast])

	useEffect(() => {
		if (aiMessage)
			toast(aiMessage, { type: 'success' })
	}, [aiMessage, toast])

	useEffect(() => {
		if (filesError)
			toast(filesError, { type: 'error' })
	}, [filesError, toast])

	useEffect(() => {
		if (filesMessage)
			toast(filesMessage, { type: 'success' })
	}, [filesMessage, toast])

	useEffect(() => {
		if (error)
			toast(error, { type: 'error' })
	}, [error, toast])

	useEffect(() => {
		if (message === LITERALS.SCENARIO_UPDATED[language] && testClicked) {
			setTestClicked(false)
			setShowTestDialog(true)
		} else if (message === LITERALS.SCENARIO_UPDATED[language] && versionClicked) {
			setVersionClicked(false)
			setShowVersionSaveDialog(true)
		} else if (message === LITERALS.SCENARIO_VERSION_CREATED[language]) {
			setShowVersionSaveDialog(false)
		}
	}, [language, message, testClicked, versionClicked])

	useEffect(() => {
		if (message) {
			if (message !== LITERALS.SCENARIO_CREATED[language])
				toast(message, { type: 'success' })

			if (message === LITERALS.SCENARIO_UPDATED[language]) {
				setShowUpsertDialog(false)
			}
		}
	}, [language, message, toast])

	useEffect(() => {
		if (testHistory.length > 1 && testHistory[testHistory.length - 1].role === 'error' && testHistory[testHistory.length - 2].role === 'log') {
			const log = JSON.parse(testHistory[testHistory.length - 2].content)
			setErrorNodeId(log.nodeId || '')
		} else {
			setErrorNodeId('')
		}

		const userIdxs = testHistory.map((h, i) => h.role === 'user' ? i : -1).filter(h => h > -1)
		const idx = userIdxs.length > 0 ? userIdxs[userIdxs.length - 1] : -1

		const result = []
		if (idx > -1) {
			for (let i = idx; i < testHistory.length; i++) {
				try {
					if (testHistory[i].role === 'log') {
						const log = JSON.parse(testHistory[i].content)
						if (log.edgeSourceHandle) {
							const edge = edges.find(ed => ed.sourceHandle === log.edgeSourceHandle)
							if (edge)
								result.push(edge.id)
						} else if (log.nodeId) {
							result.push(log.nodeId)
						}
					}
				} catch { }
			}

			for (const edge of edges) {
				if (result.includes(edge.source) &&
					result.includes(edge.target) &&
					!['start', 'checkConditions'].includes(nodes.find(nd => nd.id === edge.source)?.type))
					result.push(edge.id)
			}
		}

		if (!arraysEqual(nodeIds, result))
			setNodeIds(result)
	}, [edges, nodeIds, nodes, testHistory])

	useEffect(() => {
		setNodes(nds => JSON.parse(JSON.stringify([...nds.map(nd => ({
			...nd,
			data: {
				...nd.data,
				language,
				logged: nodeIds.includes(nd.id), inError: nd.id === errorNodeId
			}
		}))])))
		setEdges(eds => JSON.parse(JSON.stringify([...eds.map(ed => ({
			...ed,
			style: {
				strokeWidth: 4,
				stroke: nodeIds.includes(ed.id) ? theme.palette.success.main : theme.palette.primary.border
			}
		}))])))
	}, [errorNodeId, language, nodeIds, scenario, theme])

	const onNodesChange = useCallback(changes => {
		if (disabled) return
		setNodes(nds => applyNodeChanges(changes, nds))
	}, [disabled, setNodes])

	const onNodesDelete = useCallback(deleted => {
		if (disabled) return
		setEdges(deleted.reduce((acc, node) => {
			const incomers = getIncomers(node, nodes, edges)
			const outgoers = getOutgoers(node, nodes, edges)
			const connectedEdges = getConnectedEdges([node], edges)

			return [
				...acc.filter(edge => !connectedEdges.includes(edge)),
				...incomers.flatMap(({ id: source }) =>
					outgoers.map(({ id: target }) => ({
						id: `${source}->${target}`,
						source,
						target,
					}))
				)
			]
		}, edges))
	}, [disabled, nodes, edges])

	const onEdgesChange = useCallback(changes => {
		if (disabled) return
		setEdges(eds => applyEdgeChanges(changes, eds))
	}, [disabled, setEdges])

	const onDrop = useCallback(evt => {
		evt.preventDefault()
		if (disabled || !type) return

		if (type === 'start' && nodes.find(node => node.type === 'start')) {
			toast(LITERALS.CAN_ADD_ONLY_ONE_START_COMPONENT[language], { type: 'error' })
			return
		}

		const position = screenToFlowPosition({ x: evt.clientX, y: evt.clientY })
		setNodes(nds => nds.concat({ id: uuidv4(), type, position, data: { ...initialData(type), language } }))
	}, [disabled, language, nodes, screenToFlowPosition, toast, type])

	const onDragOver = useCallback(evt => {
		evt.preventDefault()
		evt.dataTransfer.dropEffect = 'move'
	}, [])

	const onSelectionChange = useCallback(({ nodes: nds }) => {
		const lastS = nds.map(nd => nd.id)
		if (arraysEqual(lastS, lastSelected)) return
		setLastSelected([...lastS])

		if (nodeId) {
			const node = nodes.find(node => node.id === nodeId)
			if (!node || (node.selected && node.data.focused))
				return

			setNodes([...nodes.map(node => ({ ...node, selected: node.id === nodeId, data: { ...node.data, focused: node.id === nodeId } }))])
		} else if (nds.length === 0) {
			if (nodes.filter(node => !node.selected && node.data.focused).length === nodes.length)
				return

			setNodes([...nodes.map(node => ({ ...node, selected: false, data: { ...node.data, focused: true } }))])
		} else {
			const a = nds.map(node => node.id)
			const b = nodes.filter(node => node.data.focused).map(node => node.id)

			if (arraysEqual(a, b)) return

			setNodes([...nodes.map(node => ({
				...node,
				selected: a.includes(node.id),
				data: {
					...node.data,
					focused: a.includes(node.id)
				}
			}))])
		}
	}, [lastSelected, nodeId, nodes])

	const onNodeClick = useCallback((event, node) => {
		if (disabled || event.ctrlKey) return

		setNodeId(node?.id)
		onSelectionChange({ nodes: [node] })
	}, [disabled, onSelectionChange])

	const onConnect = useCallback(params => {
		if (disabled) return
		const source = nodes.find(node => node.id === params.source)
		const target = nodes.find(node => node.id === params.target)

		setEdges(eds => {
			const error = checkEdges(language, source, target, eds, params)
			if (error) {
				toast(error, { type: 'error' })
				return eds
			}

			return addEdge(params, eds)
		})
	}, [disabled, language, nodes, toast])

	const save = useCallback((openTestDialog = false, openVersionDialog = false) => {
		const startNode = nodes.find(node => node.type === 'start')
		if (!startNode) {
			toast(LITERALS.YOU_DID_NOT_ADD_START_COMPONENT[language], { type: 'error' })
			return
		}

		update({
			id: _scenario.id,
			data: { nodes: [...nodes], edges: [...edges], scenarios: [...scenario] }
		})
		if (openTestDialog) setTestClicked(true)
		if (openVersionDialog) setVersionClicked(true)
	}, [edges, language, nodes, update, scenario, _scenario, toast])

	const exportData = useCallback(() => {
		const data = JSON.stringify({ nodes, edges }, null, 2)
		const blob = new Blob([data], { type: 'application/json' })
		const href = URL.createObjectURL(blob)
		const link = document.createElement('a')
		link.href = href
		link.download = `integration-${new Date().toISOString()}.json`
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
		URL.revokeObjectURL(href)
	}, [nodes, edges])

	const importData = useCallback(event => {
		event.preventDefault()
		const reader = new FileReader()
		reader.onload = async e => {
			try {
				const data = JSON.parse(e.target.result)
				if (Array.isArray(data.nodes) && Array.isArray(data.edges)) {
					setNodes(data.nodes)
					setEdges(data.edges)
				} else {
					toast(`${LITERALS.SOMETHING_WENT_WRONG[language]} ${LITERALS.ERROR[language]}: ${LITERALS.BLOCKS_INVALID[language]}.`, { type: 'error' })
				}
			} catch (err) {
				toast(`${LITERALS.SOMETHING_WENT_WRONG[language]} ${LITERALS.ERROR[language]}: ${err.message}`, { type: 'error' })
			}
			event.target.value = ''
		}
		reader.readAsText(event.target.files[0])
	}, [language, toast])

	const copyToClipboard = useCallback(async () => {
		await navigator.clipboard.writeText(JSON.stringify({ nodes, edges }, null, 2))
		toast(LITERALS.BLOCKS_COPIED[language], { type: 'success' })
	}, [edges, language, nodes, toast])

	const pasteFromClipboard = useCallback(async () => {
		try {
			const data = JSON.parse(await navigator.clipboard.readText())
			if (Array.isArray(data.nodes) && Array.isArray(data.edges)) {
				setNodes(data.nodes)
				setEdges(data.edges)
				toast(LITERALS.BLOCKS_IMPORTED[language], { type: 'success' })
			} else {
				toast(`${LITERALS.SOMETHING_WENT_WRONG[language]} ${LITERALS.ERROR[language]}: ${LITERALS.BLOCKS_INVALID[language]}.`, { type: 'error' })
			}
		} catch (err) {
			toast(`${LITERALS.SOMETHING_WENT_WRONG[language]} ${LITERALS.ERROR[language]}: ${err.message}`, { type: 'error' })
		}
	}, [language, toast])

	return (
		<Box component='main' sx={styles.main}>
			<Box sx={styles.titleContainer}>
				<Box sx={styles.nameContainer}>
					<Typography fontWeight='bold' noWrap>{_scenario?.name || '---'}</Typography>
					<Typography variant='body3' color='primary.disabled' noWrap>{_scenario?.description || '---'}</Typography>
				</Box>
				{scenarioPrivileges.includes(`${match.params.id}-e`) && (
					<Box sx={styles.iconContainer} onClick={() => { setShowUpsertDialog(true) }}>
						<ModeEditRounded sx={styles.editIcon} />
					</Box>
				)}
			</Box>

			{scenarioPrivileges.includes(`${match.params.id}-e`) && <Box sx={styles.buttonsContainer}>
				<GroupButton
					main={{ title: updating ? LITERALS.SAVING[language] : LITERALS.SAVE[language], disabled: updating, onClick: () => { save() } }}
					secondary={[
						{ title: LITERALS.VERSION_SAVE[language], onClick: () => { save(false, true) } },
						{ title: LITERALS.VERSION_MANAGE[language], onClick: () => { setShowVersionsDialog(true) } },
						{ title: LITERALS.TEST[language], onClick: () => { save(true) } },
						{ title: LITERALS.EXPORT[language], onClick: exportData },
						{ title: LITERALS.IMPORT[language], input: 'upload-file', accept: '.json', onClick: importData },
						{ title: LITERALS.COPY[language], onClick: copyToClipboard },
						{ title: LITERALS.PASTE[language], onClick: pasteFromClipboard }
					]}
				/>
			</Box>}

			{(updating || fetching) && <Box sx={styles.loader}>
				<CircularProgress />
			</Box>}
			<Box sx={styles.navigation}><Navigation language={language} /></Box>
			<Box sx={styles.container} ref={reactFlowWrapper}>
				<ReactFlow
					nodes={nodes}
					edges={edges}
					onNodesChange={onNodesChange}
					onNodesDelete={onNodesDelete}
					onNodeClick={onNodeClick}
					onEdgesChange={onEdgesChange}
					onConnect={onConnect}
					nodeTypes={nodeTypes}
					connectionLineStyle={styles.edge}
					onSelectionChange={onSelectionChange}
					fitView
					onDrop={onDrop}
					onDragOver={onDragOver}
					edgesReconnectable={!disabled}
					edgesFocusable={!disabled}
					nodesDraggable={!disabled}
					nodesConnectable={!disabled}
					nodesFocusable={!disabled}
					elementsSelectable={!disabled}
				>
					<CustomControl
						disabled={disabled}
						setDisabled={val => {
							if (scenarioPrivileges.includes(`${match.params.id}-e`))
								setDisabled(val)
						}}
					/>
					<Background variant='dots' gap={12} size={1} />
				</ReactFlow>
			</Box>

			<Settings
				scenarioId={_scenario?.id}
				canWrite={scenarioPrivileges.includes(`${match.params.id}-e`)}
				language={language}
				code={code}
				generating={generating}
				generate={generate}
				optimizedCode={optimizedCode}
				checking={checking}
				check={check}
				toast={toast}
				open={!!nodeId}
				node={nodes.find(n => n.id === nodeId) || null}
				nodes={nodes}
				update={node => {
					setNodes([...nodes.map(n => n.id === node.id ? node : n)])
					setNodeId(null)
				}}
				cancel={() => { setNodeId(null) }}
				files={JSON.parse(JSON.stringify(files))}
				filesFetching={filesFetching}
				filesUploading={filesUploading}
				filesUpdating={filesUpdating}
				filesDeleting={filesDeleting}
				upload={upload}
				updateFile={updateFile}
				removeFile={removeFile}
				assistants={assistants}
				models={models}
			/>

			{showUpsertDialog && <UpsertDialog
				language={language}
				id={_scenario.id}
				name={_scenario.name}
				description={_scenario.description}
				toast={toast}
				onClose={() => { setShowUpsertDialog(false) }}
				updating={updating}
				error={error}
				update={update}
			/>}

			<TestDialog
				language={language}
				open={showTestDialog}
				cancel={() => {
					setShowTestDialog(false)
					setTestClicked(false)
				}}
				scenarioId={_scenario?.id}
				scenarioData={{ nodes: [...nodes], edges: [...edges], scenarios: [...scenario] }}
				recipient={recipient}
				history={testHistory}
				testing={testing}
				test={test}
				reset={reset}
				toast={toast}
			/>

			{showVersionSaveDialog && <UpsertVersionDialog
				language={language}
				scenarioId={_scenario?.id}
				data={{ nodes, edges, scenarios: [...scenario] }}
				toast={toast}
				onClose={() => {
					setShowVersionSaveDialog(false)
					setVersionClicked(false)
				}}
				upserting={versionLoading}
				upsert={upsertVersion}
			/>}

			{showVersionsDialog && <VersionsDialog
				language={language}
				scenarioId={_scenario?.id}
				versions={versions}
				loading={versionLoading}
				onClose={() => { setShowVersionsDialog(false) }}
				get={getVersions}
				loadData={getVersion}
				remove={removeVersion}
			/>}
		</Box>
	)
}

const styles = {
	main: {
		alignItems: 'start',
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
		minHeight: '100%'
	},
	navigation: {
		position: 'fixed',
		top: 0,
		width: 280,
		height: '100vh',
		backgroundColor: theme => theme.palette.primary.contrastText,
		borderRight: theme => `1px solid ${theme.palette.primary.border}`
	},
	container: {
		position: 'fixed',
		top: 0,
		left: 280,
		width: 'calc(100vw - 280px)',
		height: '100vh'
	},
	edge: {
		stroke: '#212529'
	},
	loader: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'center',
		alignItems: 'center',
		position: 'fixed',
		backgroundColor: theme => `${theme.palette.primary.border}7A`,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1000
	},
	titleContainer: {
		position: 'fixed',
		left: 312,
		top: 16,
		width: 350,
		height: 40,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		zIndex: 3,
		px: 2,
		backgroundColor: theme => theme.palette.primary.contrastText,
		borderRadius: '12px'
	},
	nameContainer: {
		width: 'calc(100% - 32px)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'space-between'
	},
	iconContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	editIcon: {
		fontSize: 25,
		color: theme => theme.palette.warning.main
	},
	buttonsContainer: {
		position: 'fixed',
		right: 96,
		top: 16,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'end',
		zIndex: 3
	}
}

const Flow = props => (
	<ReactFlowProvider>
		<Provider>
			<Dataflow {...props} />
		</Provider>
	</ReactFlowProvider>
)

export default Flow