import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import { AttachFileRounded, AutoAwesome, CheckRounded, JavascriptRounded } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

import DialogTitle from '../custom/dialog-title'
import { componentsInfo } from '../../helpers/constants'
import TextButton from '../custom/text-button'
import TextArea from '../custom/text-area'
import LITERALS from '../../helpers/literals'
import TooltipTitle from '../custom/tooltip-title'
import GenerateCodeDialog from '../custom/generate-code-dialog'
import CheckCodeDialog from '../custom/check-code-dialog'
import Dropdown from '../custom/dropdown'
import FilesDialog from '../custom/files-dialog'
import ResultAIDialog from '../custom/result-ai-dialog'

const ResultNodeSettings = ({
	canWrite,
	scenarioId,
	language,
	toast,
	node,
	update,
	cancel,
	code,
	generating,
	generate,
	optimizedCode,
	checking,
	check,
	files,
	filesFetching,
	filesUploading,
	filesUpdating,
	filesDeleting,
	upload,
	updateFile,
	removeFile,
	assistants,
	models,
}) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [result, setResult] = useState('')
	const [aiSettings, setAISettings] = useState({ lang: 'auto', enabled: false, assistant: '', promt: '', models: [] })
	const [buttons, setButtons] = useState({ type: 'in', rows: '' })
	const [activeDialog, setActiveDialog] = useState('-')
	const [instruction, setInstruction] = useState('')
	const [example, setExample] = useState('')
	const [testCode, setTestCode] = useState('')
	const theme = useTheme()

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setResult(node?.data?.result || '')
			setAISettings(node?.data?.aiSettings || { lang: 'auto', enabled: false, assistant: '', promt: '', models: [] })
			setButtons(node?.data?.buttons || { type: 'in', rows: '' })
			setId(node?.id)
		}
	}, [id, node])

	const save = useCallback(() => {
		if (!result) {
			toast(LITERALS.PLEASE_ENTER_RESULT_TEXT[language], { type: 'error' })
			return
		}

		update({ ...node, data: { ...node.data, description, result, buttons, aiSettings } })
	}, [aiSettings, buttons, description, language, node, result, toast, update])

	return (
		<Box sx={styles.main}>
			<DialogTitle
				title={componentsInfo[node.type].title[language]}
				description={componentsInfo[node.type].description[language]}
				cancel={cancel}
			/>

			<Box sx={styles.row}>
				<TextArea
					placeholder={LITERALS.ENTER_COMPONENT_DESCRIPTION[language]}
					rows={3}
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
			</Box>

			<Box sx={styles.divider} />

			<Box sx={styles.middle}>
				<Box sx={styles.items}>
					<TextButton title={LITERALS.RESULT_TEXT[language]} active color={theme.palette.primary.resultColor} onClick={() => { }} />
					<Box>
						<Tooltip title={<TooltipTitle title={LITERALS.AI_SETTINGS[language]} />} arrow>
							<IconButton color='info' onClick={() => { setActiveDialog('ai') }} sx={styles.iconButton}>
								<AutoAwesome />
							</IconButton>
						</Tooltip>

						<Tooltip title={<TooltipTitle title={LITERALS.FILE_MANAGER[language]} />} arrow>
							<IconButton color='primary' onClick={() => { setActiveDialog('files') }} sx={styles.iconButton}>
								<AttachFileRounded />
							</IconButton>
						</Tooltip>

						<Tooltip title={<TooltipTitle title={LITERALS.GENERATE_CODE[language]} />} arrow>
							<Button variant='contained' size='small' color='primary' sx={styles.button2} onClick={() => { setActiveDialog('generate') }}>
								<JavascriptRounded sx={styles.icon} />
							</Button>
						</Tooltip>

						<Tooltip title={<TooltipTitle title={LITERALS.CHECK_CODE[language]} />} arrow>
							<IconButton color='success' onClick={() => { setTestCode(result); setActiveDialog('test') }} sx={styles.iconButton}>
								<CheckRounded />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>

				<Box sx={styles.body}>
					<TextArea
						rows={6}
						placeholder={`'What day and time would you like to sign up for instructor "' + this.instructor + '" lesson "' + this.lesson + '"?'`}
						value={result}
						onChange={e => setResult(e.target.value)}
					/>
				</Box>

				<Box sx={styles.items}>
					<TextButton title={LITERALS.BUTTONS[language]} active color={theme.palette.primary.resultColor} onClick={() => { }} />
					<Box sx={{ display: 'flex' }}>
						<Tooltip title={<TooltipTitle title={LITERALS.GENERATE_CODE[language]} />} arrow>
							<Dropdown
								box={{ height: 36 }}
								placeholder={LITERALS.BUTTONS_TYPE[language]}
								selected={buttons?.type || 'in'}
								items={[
									{ id: 'in', name: LITERALS.BUTTONS_INLINE[language] },
									{ id: 'out', name: LITERALS.BUTTONS_KEYBOARD[language] }
								]}
								onChange={type => setButtons({ type, rows: buttons.rows })}
							/>
						</Tooltip>
					</Box>
				</Box>

				<Box sx={styles.body}>
					<TextArea
						rows={6}
						placeholder={`[\n\t[\n\t\t{\n\t\t\t"type": "text",\n\t\t\t"title": "Text button",\n\t\t\t"payload": "first button"\n\t\t},\n\t\t{\n\t\t\t"type": "url",\n\t\t\t"title": "Url button",\n\t\t\t"payload": "https://example.com"\n\t\t}\n\t]\n]`}
						value={buttons.rows}
						onChange={e => setButtons({ type: buttons.type, rows: e.target.value })}
					/>
				</Box>

			</Box>

			<Box sx={styles.buttonsContainer}>
				<Button variant='contained' color='primary' sx={styles.button} onClick={save}>{LITERALS.SAVE[language]}</Button>
			</Box>

			{activeDialog === 'generate' && <GenerateCodeDialog
				language={language}
				type={node.type}
				instruction={instruction}
				setInstruction={setInstruction}
				example={example}
				setExample={setExample}
				code={code}
				generating={generating}
				cancel={() => { setActiveDialog('-') }}
				generate={generate}
				apply={() => { setResult(code); setActiveDialog('-') }}
			/>}

			{activeDialog === 'test' && <CheckCodeDialog
				language={language}
				type={node.type}
				code={testCode}
				setCode={setTestCode}
				optimized={optimizedCode}
				checking={checking}
				cancel={() => { setActiveDialog('-') }}
				check={check}
				apply={() => { setResult(optimizedCode); setActiveDialog('-') }}
			/>}

			{activeDialog === 'files' && <FilesDialog
				language={language}
				canWrite={canWrite}
				cancel={() => { setActiveDialog('-') }}
				files={files}
				loading={filesFetching || filesUploading}
				updating={filesUpdating}
				deleting={filesDeleting}
				upload={files => { upload(scenarioId, files) }}
				update={(id, tag) => { updateFile(scenarioId, id, tag) }}
				remove={id => { removeFile(scenarioId, id) }}
			/>}

			{activeDialog === 'ai' && <ResultAIDialog
				language={language}
				assistants={assistants}
				models={models}
				config={aiSettings}
				cancel={() => { setActiveDialog('-') }}
				update={config => { setAISettings({ ...config }); setActiveDialog('-') }}
			/>}
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2,
		mb: 2
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	middle: {
		width: '100%',
		height: 'calc(100% - 227px)',
		py: 2,
		display: 'flex',
		flexDirection: 'column'
	},
	items: {
		width: '100%',
		height: 49,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 1,
		px: 1.5,
		overflowX: 'auto'
	},
	body: {
		width: '100%',
		height: 'calc(50% - 49px)',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		alignItems: 'center',
		px: 2,
		py: 1
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	},
	button2: {
		mx: 2,
		p: 0,
		borderRadius: 10
	},
	icon: {
		fontSize: 30
	},
	iconButton: {
		p: 0.75
	}
}

export default ResultNodeSettings