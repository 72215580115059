const LITERALS = {
	ACTION: {
		ru: 'Действие',
		en: 'Action',
		de: 'Aktion',
		es: 'Acción',
		tr: 'Aksiyon',
		kk: 'Әрекет',
		uz: 'Harakat',
		az: 'Hərəkət',
		ka: 'მოქმედება',
		pt: 'Ação'
	},
	ACTIONS: {
		ru: 'Действия',
		en: 'Actions',
		de: 'Aktionen',
		es: 'Acciones',
		tr: 'Aksiyonlar',
		kk: 'Әрекеттер',
		uz: 'Harakatlar',
		az: 'Hərəkətlər',
		ka: 'მოქმედებები',
		pt: 'Ações'
	},
	ACTIVATE: {
		ru: 'Активировать',
		en: 'Activate',
		de: 'Aktivieren',
		es: 'Activar',
		tr: 'Etkinleştir',
		kk: 'Белсендіру',
		uz: 'Faollashtirish',
		az: 'Aktivləşdir',
		ka: 'გააქტიურება',
		pt: 'Ativar'
	},
	ADD: {
		ru: 'Добавить',
		en: 'Add',
		de: 'Hinzufügen',
		es: 'Agregar',
		tr: 'Ekle',
		kk: 'Қосу',
		uz: 'Qo‘shish',
		az: 'Əlavə etmək',
		ka: 'დამატება',
		pt: 'Adicionar'
	},
	ADD_ACTION_SET: {
		ru: 'Добавьте действия и настройте',
		en: 'Add actions and customize',
		de: 'Aktionen hinzufügen und anpassen',
		es: 'Agregue acciones y personalice',
		tr: 'Aksiyonları ekleyin ve özelleştirin',
		kk: 'Әрекеттерді қосып, баптаңыз',
		uz: `Harakatlarni qo'shing va sozlang`,
		az: 'Fəaliyyətləri əlavə edin və tənzimləyin',
		ka: 'დაამატეთ მოქმედებები და დააკონფიგურირეთ',
		pt: 'Adicione ações e personalize'
	},
	ADD_CONDITIONS_SET: {
		ru: 'Добавьте условия и настройте',
		en: 'Add conditions and customize',
		de: 'Bedingungen hinzufügen und anpassen',
		es: 'Agregue condiciones y personalice',
		tr: 'Koşulları ekleyin ve özelleştirin',
		kk: 'Шарттарды қосып, баптаңыз',
		uz: `Shartlarni qo'shing va sozlang`,
		az: 'Şərtləri əlavə edin və tənzimləyin',
		ka: 'დაამატეთ პირობები და დააკონფიგურირეთ',
		pt: 'Adicione condições e personalize'
	},
	ADD_DATA_SET: {
		ru: 'Добавьте данные и настройте',
		en: 'Add data and configure',
		de: 'Daten hinzufügen und konfigurieren',
		es: 'Añadir datos y configurar',
		tr: 'Veri ekleyin ve yapılandırın',
		kk: 'Деректер қосып, баптаңыз',
		uz: 'Ma’lumot qo‘shib sozlang',
		az: 'Məlumat əlavə edin və konfiqurasiya edin',
		ka: 'დამატეთ მონაცემები და დააკონფიგურირეთ',
		pt: 'Adicionar dados e configurar'
	},
	ADD_FUNCTION_SET: {
		ru: 'Добавьте функции и настройте',
		en: 'Add functions and configure',
		de: 'Funktionen hinzufügen und konfigurieren',
		es: 'Agrega funciones y configura',
		tr: 'Fonksiyonları ekleyin ve yapılandırın',
		kk: 'Функция қосыңыз және баптаңыз',
		uz: `Funksiyalar qo'shing va sozlang`,
		az: 'Funksiyaları əlavə edin və konfiqurasiya edin',
		ka: 'მოდიფიკაციების დამატება და კონფიგურირება',
		pt: 'Adicione funções e configure'
	},
	ADD_PROMPT_SET: {
		ru: 'Добавьте промты и настройте',
		en: 'Add prompts and configure',
		de: 'Eingabeaufforderungen hinzufügen und konfigurieren',
		es: 'Agrega indicaciones y configura',
		tr: 'İpuçları ekleyin ve yapılandırın',
		kk: 'Нұсқауларды қосыңыз және баптаңыз',
		uz: `Ko'rsatmalar qo'shing va sozlang`,
		az: 'Təkliflər əlavə edin və konfiqurasiya edin',
		ka: 'ინსტრუქციების დამატება და კონფიგურირება',
		pt: 'Adicione prompts e configure'
	},
	ADD_RULE_SET: {
		ru: 'Добавьте правило отображения и настройте его',
		en: 'Add a display rule and configure it',
		de: 'Fügen Sie eine Anzeigeregel hinzu und konfigurieren Sie sie',
		es: 'Agregue una regla de visualización y configúrela',
		tr: 'Bir görüntüleme kuralı ekleyin ve yapılandırın',
		kk: 'Көрсету ережесін қосып, оны баптаңыз',
		uz: `Ko'rsatish qoidasi qo'shing va uni sozlang`,
		az: 'Göstərmə qaydası əlavə edin və onu konfiqurasiya edin',
		ka: 'დამატეთ გამოჩენის წესი და დააკონფიგურირეთ იგი',
		pt: 'Adicione uma regra de exibição e configure-a'
	},
	ADD_VALUE_SET: {
		ru: 'Добавьте значение и настройте его',
		en: 'Add value and configure it',
		de: 'Wert hinzufügen und konfigurieren',
		es: 'Añadir valor y configurarlo',
		tr: 'Değer ekleyin ve yapılandırın',
		kk: 'Мән қосыңыз және баптаңыз',
		uz: `Qiymat qo'shing va sozlang`,
		az: 'Dəyər əlavə edin və tənzimləyin',
		ka: 'დაამატეთ მნიშვნელობა და დააყენეთ იგი',
		pt: 'Adicione o valor e configure-o'
	},
	ADD_VARIABLE_SET: {
		ru: 'Добавьте переменную и настройте её',
		en: 'Add a variable and configure it',
		de: 'Fügen Sie eine Variable hinzu und konfigurieren Sie sie',
		es: 'Agregue una variable y configúrela',
		tr: 'Bir değişken ekleyin ve yapılandırın',
		kk: 'Айнымалы қосып, оны баптаңыз',
		uz: `O'zgaruvchi qo'shing va uni sozlang`,
		az: 'Bir dəyişən əlavə edin və onu qurun',
		ka: 'მონაცემის დამატება და მისი კონფიგურირება',
		pt: 'Adicione uma variável e configure-a'
	},
	ADD_WEBHOOKS_SET: {
		ru: 'Добавьте вебхуки и настройте',
		en: 'Add webhooks and customize',
		de: 'Webhooks hinzufügen und anpassen',
		es: 'Agregue webhooks y personalice',
		tr: `Webhook'ları ekleyin ve özelleştirin`,
		kk: 'Webhook-тарды қосып, баптаңыз',
		uz: `Webhooklarni qo'shing va sozlang`,
		az: 'Webhook-ları əlavə edin və tənzimləyin',
		ka: 'დაამატეთ webhook-ები და დააკონფიგურირეთ',
		pt: 'Adicione webhooks e personalize'
	},
	ADD_SCENARIO: {
		ru: 'Добавить сценарий',
		en: 'Add scenario',
		de: 'Szenario hinzufügen',
		es: 'Añadir escenario',
		tr: 'Senaryo ekle',
		kk: 'Сценарий қосу',
		uz: `Ssenariy qo'shish`,
		az: 'Ssenari əlavə et',
		ka: 'დაიმატეთ სცენარი',
		pt: 'Adicionar cenário'
	},
	ADD_USER: {
		ru: 'Добавить пользователя',
		en: 'Add user',
		de: 'Benutzer hinzufügen',
		es: 'Agregar usuario',
		tr: 'Kullanıcı ekle',
		kk: 'Пайдаланушыны Қосу',
		uz: 'Foydalanuvchini qo‘shish',
		az: 'İstifadəçi əlavə etmək',
		ka: 'მომხმარების დამათება',
		pt: 'Adicionar usuário'
	},
	AI_REQUEST: {
		ru: 'ИИ запрос',
		en: 'AI request',
		de: 'KI-Anfrage',
		es: 'Solicitud de IA',
		tr: 'Yapay Zeka isteği',
		kk: 'AI суранысы',
		uz: 'AI so‘rovi',
		az: 'AI sorğu',
		ka: 'არტიფიციული ეთირი',
		pt: 'Requisição de IA'
	},
	AI_REQUEST_DESCRIPTION: {
		ru: 'Отправляет запрос в ChatGPT и сохраняет полученный ответ.',
		en: 'Sends a request to ChatGPT and saves the received response.',
		de: 'Sendet eine Anfrage an ChatGPT und speichert die erhaltene Antwort.',
		es: 'Envía una solicitud a ChatGPT y guarda la respuesta recibida.',
		tr: `ChatGPT'ye bir istek gönderir ve gelen yanıtı kaydeder.`,
		kk: 'ChatGPT-ға сұраныс жібереді және алынған жауапты сақтайды.',
		uz: 'ChatGPT-ga so‘rov yuboradi va olingan javobni saqlaydi.',
		az: 'ChatGPT-yə sorğu göndərir və alınan cavabı saxlayır.',
		ka: 'ChatGPT-ს მიინიღი გაუკვნებს და მიუღებულ ებრიერით შენაჰვასში სიწუოს.',
		pt: 'Envia uma requisição ao ChatGPT e salva a resposta recebida.'
	},
	AI_SETTINGS: {
		ru: 'Настройки ИИ',
		en: 'AI settings',
		de: 'KI-Einstellungen',
		es: 'Configuración de IA',
		tr: 'Yapay Zeka ayarları',
		kk: 'ЖИ баптаулары',
		uz: 'AI sozlamalari',
		az: 'Süni intellekt ayarları',
		ka: 'AI პარამეტრები',
		pt: 'Configurações de IA'
	},
	API_REQUEST: {
		ru: 'API запрос',
		en: 'API request',
		de: 'API-Anfrage',
		es: 'Solicitud de API',
		tr: 'API isteği',
		kk: 'API сұранысы',
		uz: 'API so‘rovi',
		az: 'API sorğu',
		ka: 'API ეთირი',
		pt: 'Requisição de API'
	},
	API_REQUEST_DESCRIPTION: {
		ru: 'API-сценарии взаимодействуют между системойинтеграции IntellecDialog и внешними сервисами. Сценарии содержат вебхуки для отправки и получения данных, а также действия, которые определяют последовательность выполнения задач.',
		en: 'API scenarios interact between the IntellecDialog integration system and external services. Scenarios include webhooks for sending and receiving data, as well as actions that define the sequence of task execution.',
		de: 'API-Szenarien interagieren zwischen dem IntellecDialog-Integrationssystem und externen Diensten. Szenarien enthalten Webhooks zum Senden und Empfangen von Daten sowie Aktionen, die die Reihenfolge der Aufgaben festlegen.',
		es: 'Los escenarios de API interactúan entre el sistema de integración IntellecDialog y los servicios externos. Los escenarios incluyen webhooks para enviar y recibir datos, así como acciones que definen la secuencia de ejecución de tareas.',
		tr: 'API senaryoları, IntellecDialog entegrasyon sistemi ile harici hizmetler arasında etkileşim sağlar. Senaryolar, veri gönderme ve alma için web kancalarını ve görevlerin yürütme sırasını belirleyen aksiyonları içerir.',
		kk: 'API сценарийлері IntellecDialog интеграциялық жүйесі мен сыртқы қызметтер арасында әрекеттеседі. Сценарийлер мәліметтерді жіберу және қабылдау үшін вебхуктар мен тапсырмаларды орындау ретін анықтайтын әрекеттерді қамтиды.',
		uz: `API-senariylari IntellecDialog integratsiya tizimi va tashqi xizmatlar o'rtasida o'zaro aloqa qiladi. Senariylar ma'lumotlarni yuborish va qabul qilish uchun veb-xooklar hamda vazifalarni bajarish ketma-ketligini belgilaydigan harakatlarni o'z ichiga oladi.`,
		az: 'API ssenariləri IntellecDialog inteqrasiya sistemi ilə xarici xidmətlər arasında qarşılıqlı əlaqə yaradır. Ssenarilər məlumatların göndərilməsi və alınması üçün vebhookları və tapşırıqların icra ardıcıllığını müəyyən edən hərəkətləri ehtiva edir.',
		ka: 'API სცენარები ურთიერთობენ IntellecDialog-ის ინტეგრაციის სისტემასა და გარე სერვისებს შორის. სცენარები შეიცავს ვებჰუკებს მონაცემების გაგზავნისა და მიღებისთვის, ასევე ქმედებებს, რომლებიც განსაზღვრავს დავალებების შესრულების თანმიმდევრობას.',
		pt: 'Os cenários de API interagem entre o sistema de integração IntellecDialog e serviços externos. Os cenários incluem webhooks para envio e recebimento de dados, bem como ações que definem a sequência de execução das tarefas.'
	},
	API_RUN: {
		ru: 'Запуск API',
		en: 'Run API',
		de: 'API ausführen',
		es: 'Ejecutar API',
		tr: 'API çalıştır',
		kk: 'API іске қосу',
		uz: 'API-ni ishga tushirish',
		az: 'API-i işə sal',
		ka: 'API-ის გაშვება',
		pt: 'Executar API'
	},
	API_RUN_DESCRIPTION: {
		ru: 'Запускает API-запрос',
		en: 'Executes an API request',
		de: 'Führt eine API-Anfrage aus',
		es: 'Ejecuta una solicitud API',
		tr: 'API isteğini çalıştırır',
		kk: 'API сұранысын іске қосады',
		uz: `API so'rovini ishga tushiradi`,
		az: 'API sorğusunu işə salır',
		ka: 'API მოთხოვნის გაშვება',
		pt: 'Executa uma solicitação de API'
	},
	API_SCENARIO: {
		ru: 'API сценарий',
		en: 'API scenario',
		de: 'API-Szenario',
		es: 'Escenario de API',
		tr: 'API senaryosu',
		kk: 'API сценарийі',
		uz: 'API ssenariysi',
		az: 'API ssenarisi',
		ka: 'API სცენარი',
		pt: 'Cenário de API'
	},
	API_SCENARIO_DESCRIPTION: {
		ru: 'Используют вебхуки для обмена данными и выполнения действий',
		en: 'Uses webhooks for data exchange and performing actions',
		de: 'Verwendet Webhooks für den Datenaustausch und die Ausführung von Aktionen',
		es: 'Utiliza webhooks para el intercambio de datos y la realización de acciones',
		tr: `Veri alışverişi ve aksiyonları gerçekleştirmek için webhook'lar kullanır`,
		kk: 'Деректер алмасу және әрекеттерді орындау үшін вебхук-тарды қолданады',
		uz: `Ma'lumotlar almashinuvi va harakatlarni bajarish uchun webhook-lar ishlatiladi`,
		az: 'Veri mübadiləsi və hərəkətlərin yerinə yetirilməsi üçün vebhook-lar istifadə olunur',
		ka: 'მონაცემების გაცვლისა და მოქმედებების შესრულებისათვის გამოიყენება ვებჰუკები',
		pt: 'Usa webhooks para troca de dados e execução de ações'
	},
	API_SCENARIO_RUN: {
		ru: 'Запуск API сценария',
		en: 'Run API scenario',
		de: 'API-Szenario ausführen',
		es: 'Ejecutar escenario de API',
		tr: 'API senaryosunu çalıştır',
		kk: 'API сценарийін іске қосу',
		uz: 'API ssenariysini ishga tushirish',
		az: 'API ssenarisini işə salmaq',
		ka: 'API სცენარის გაშვება',
		pt: 'Executar cenário de API'
	},
	API_SCENARIO_RUN_DESCRIPTION: {
		ru: 'Предназначен для инициирования выполнения API сценария',
		en: 'Used to initiate the execution of an API scenario',
		de: 'Dient dazu, die Ausführung eines API-Szenarios zu initiieren',
		es: 'Se utiliza para iniciar la ejecución de un escenario de API',
		tr: 'API senaryosunun çalıştırılmasını başlatmak için kullanılır',
		kk: 'API сценарийін іске қосу үшін қолданылады',
		uz: 'API ssenariysini ishga tushirishni boshlash uchun ishlatiladi',
		az: 'API ssenarisinin icrasını başlatmaq üçün istifadə olunur',
		ka: 'API სცენარის შესრულების დასაწყებად გამოიყენება',
		pt: 'Usado para iniciar a execução de um cenário de API'
	},
	ASSISTANT_SETTINGS: {
		ru: 'Настройки ассистента',
		en: 'Assistant settings',
		de: 'Assistenteneinstellungen',
		es: 'Configuración del asistente',
		tr: 'Asistan ayarları',
		kk: 'Ассистент баптаулары',
		uz: 'Assistent sozlamalari',
		az: 'Assistent parametrləri',
		ka: 'ასისტენტის პარამეტრები',
		pt: 'Configurações do assistente'
	},
	AUTO_DETECTION: {
		ru: 'Определить автоматически',
		en: 'Detect automatically',
		de: 'Automatisch erkennen',
		es: 'Detectar automáticamente',
		tr: 'Otomatik olarak algıla',
		kk: 'Автоматты түрде анықтау',
		uz: 'Avtomatik aniqlash',
		az: 'Avtomatik müəyyən et',
		ka: 'ავტომატურად განსაზღვრა',
		pt: 'Detectar automaticamente'
	},
	AUTOMATIC_SEND: {
		ru: 'Автоматическая отправка данных при событии',
		en: 'Automatic data send on event',
		de: 'Automatisches Senden von Daten bei Ereignis',
		es: 'Envío automático de datos en caso de evento',
		tr: 'Olayda otomatik veri gönderimi',
		kk: 'Оқиға кезінде деректерді автоматты түрде жіберу',
		uz: 'Hodisada ma’lumotlarni avtomatik yuborish',
		az: 'Hadisədə məlumatların avtomatik göndərilməsi',
		ka: 'ღონისძიებაზე ავტომატური მონაცემების გაგზავნა',
		pt: 'Envio automático de dados no evento'
	},
	BACK: {
		ru: 'Назад',
		en: 'Back',
		de: 'Zurück',
		es: 'Atrás',
		tr: 'Geri',
		kk: 'Артқа',
		uz: 'Orqaga',
		az: 'Geri',
		ka: 'უკან',
		pt: 'Voltar'
	},
	BLOCKS_COPIED: {
		ru: 'Блоки успешно скопированы в буфер обмена.',
		en: 'Blocks have been successfully copied to the clipboard.',
		de: 'Blöcke wurden erfolgreich in die Zwischenablage kopiert.',
		es: 'Los bloques se han copiado correctamente al portapapeles.',
		tr: 'Bloklar başarıyla panoya kopyalandı.',
		kk: 'Блоктар сәтті буферге көшірілді.',
		uz: 'Bloklar muvaffaqiyatli ravishda clipboardga nusxalandi.',
		az: 'Bloklar uğurla panoya kopyalandı.',
		ka: 'ბლოკები წარმატებით გადააკოპირეს ბუფერში.',
		pt: 'Blocos copiados com sucesso para a área de transferência.'
	},
	BLOCKS_IMPORTED: {
		ru: 'Блоки успешно импортированы из буфера обмена.',
		en: 'Blocks were successfully imported from the clipboard.',
		de: 'Blöcke wurden erfolgreich aus der Zwischenablage importiert.',
		es: 'Los bloques se importaron correctamente desde el portapapeles.',
		tr: 'Bloklar panodan başarıyla içe aktarıldı.',
		kk: 'Блоктар буферден сәтті импортталды.',
		uz: 'Bloklar clipborddan muvaffaqiyatli import qilindi.',
		az: 'Bloklar clipboard-dan uğurla idxal olundu.',
		ka: 'ბლოკები წარმატებით იქნა იმპორტირებული კლიპბორდიდან.',
		pt: 'Os blocos foram importados com sucesso da área de transferência.'
	},
	BLOCKS_INVALID: {
		ru: 'блоки недействительны',
		en: 'the blocks are invalid',
		de: 'die Blöcke sind ungültig',
		es: 'los bloques son inválidos',
		tr: 'bloklar geçersiz',
		kk: 'блоктар жарамсыз',
		uz: 'bloklar yaroqsiz',
		az: 'bloklar etibarsızdır',
		ka: 'ბლოკები არასწორია',
		pt: 'os blocos são inválidos'
	},
	BODY: {
		ru: 'Тело',
		en: 'Body',
		de: 'Körper',
		es: 'Cuerpo',
		tr: 'Gövde',
		kk: 'Дене',
		uz: 'Tanaga',
		az: 'Bədən',
		ka: 'ხორცი',
		pt: 'Corpo'
	},
	BUTTONS: {
		ru: 'Кнопки',
		en: 'Buttons',
		de: 'Tasten',
		es: 'Botones',
		tr: 'Düğmeler',
		kk: 'Түймелер',
		uz: 'Tugmalar',
		az: 'Düymələr',
		ka: 'ღილაკები',
		pt: 'Botões'
	},
	BUTTONS_INLINE: {
		ru: 'In-кнопки',
		en: 'In-buttons',
		de: 'In-Tasten',
		es: 'Botones internos',
		tr: 'İç düğmeler',
		kk: 'Ішкі түймелер',
		uz: 'Ichki tugmalar',
		az: 'Daxili düymələr',
		ka: 'შიდა ღილაკები',
		pt: 'Botões internos'
	},
	BUTTONS_KEYBOARD: {
		ru: 'Out-кнопки',
		en: 'Out-buttons',
		de: 'Out-Tasten',
		es: 'Botones externos',
		tr: 'Dış düğmeler',
		kk: 'Сыртқы түймелер',
		uz: 'Tashqi tugmalar',
		az: 'Xarici düymələr',
		ka: 'გარე ღილაკები',
		pt: 'Botões externos'
	},
	BUTTONS_TYPE: {
		ru: 'Тип кнопок',
		en: 'Buttons type',
		de: 'Schaltflächentyp',
		es: 'Tipo de botón',
		tr: 'Düğme türü',
		kk: 'Түйме түрі',
		uz: 'Tugma turi',
		az: 'Düymə növü',
		ka: 'ღილაკის ტიპი',
		pt: 'Tipo de botão'
	},
	CAN_ADD_ONLY_ONE_START_COMPONENT: {
		ru: 'Можно добавить только один "Стартовый сценарий"',
		en: 'You can only add one "Start Scenario"',
		de: 'Sie können nur ein "Start-Szenario" hinzufügen',
		es: 'Solo puedes agregar un "Escenario de inicio"',
		tr: 'Sadece bir "Başlangıç Senaryosu" ekleyebilirsiniz',
		kk: 'Тек бір "Бастапқы сценарий" қосуға болады',
		uz: `Faqat bitta "Boshlang'ich sahifa" qo'shishingiz mumkin`,
		az: 'Yalnızca bir "Başlanğıc Ssenarisi" əlavə edə bilərsiniz',
		ka: 'მხოლოდ ერთი "საწყისი სცენარი" შეგიძლიათ დაამატოთ',
		pt: 'Você só pode adicionar um "Cenário de Início"'
	},
	CHECK: {
		ru: 'Проверить',
		'en': 'Check',
		'de': 'Überprüfen',
		'es': 'Comprobar',
		'tr': 'Kontrol et',
		'kk': 'Тексеріңіз',
		'uz': 'Tekshirish',
		'az': 'Yoxla',
		'ka': 'შეამოწმეთ',
		'pt': 'Verificar'
	},
	CHECK_CODE: {
		ru: 'Проверить написанный код',
		en: 'Check the written code',
		de: 'Geschriebenen Code überprüfen',
		es: 'Comprobar el código escrito',
		tr: 'Yazılan kodu kontrol et',
		kk: 'Жазылған кодты тексеру',
		uz: 'Yozilgan kodni tekshirish',
		az: 'Yazılmış kodu yoxla',
		ka: 'დაწერილი კოდის შემოწმება',
		pt: 'Verificar o código escrito'
	},
	CHECK_CONDITIONS: {
		ru: 'Проверка условий',
		en: 'Check conditions',
		de: 'Bedingungen prüfen',
		es: 'Verificar condiciones',
		tr: 'Koşul kontrolü',
		kk: 'Шарттарды тексеру',
		uz: 'Shartlarni tekshirish',
		az: 'Şərtləri yoxlamaq',
		ka: 'პარამეტრების შემოწმება',
		pt: 'Verificar condições'
	},
	CHECK_CONDITIONS_DESCRIPTION: {
		ru: 'Проверяет условия для перехода на следующий шаг',
		en: 'Checks conditions to move to the next step',
		de: 'Prüft die Bedingungen, um zum nächsten Schritt zu wechseln',
		es: 'Verifica las condiciones para pasar al siguiente paso',
		tr: 'Sonraki adıma geçmek için koşulları kontrol eder',
		kk: 'Келесі қадамға өту үшін шарттарды тексереді',
		uz: 'Keyingi qadamga o‘tish uchun shartlarni tekshiradi',
		az: 'Növbəti addıma keçmək üçün şərtləri yoxlayır',
		ka: 'შემოწმებს პირობებს შემდეგ ეტაპზე გადასასვლელად',
		pt: 'Verifica as condições para passar para o próximo passo'
	},
	CHECK_PARAMS: {
		ru: 'Проверка',
		en: 'Check',
		de: 'Prüfung',
		es: 'Comprobar',
		tr: 'Kontrol',
		kk: 'Тексеру',
		uz: 'Tekshiruv',
		az: 'Yoxlama',
		ka: 'შემოწმება',
		pt: 'Verificação'
	},
	CHECK_PARAMS_DESCRIPTION: {
		ru: 'Проверяет, ввёл ли пользователь обязательные параметры (например, дату и время занятия)',
		en: 'Checks if the user has entered the required parameters (e.g., date and time of the session)',
		de: 'Überprüft, ob der Benutzer die erforderlichen Parameter eingegeben hat (z. B. Datum und Uhrzeit der Sitzung)',
		es: 'Verifica si el usuario ha ingresado los parámetros requeridos (por ejemplo, la fecha y hora de la sesión)',
		tr: 'Kullanıcının gerekli parametreleri girip girmediğini kontrol eder (örneğin, oturumun tarihi ve saati)',
		kk: 'Пайдаланушы міндетті параметрлерді (мысалы, сабақтың күні мен уақытын) енгізгенін тексереді',
		uz: 'Foydalanuvchi majburiy parametrlarni (masalan, seansning sanasi va vaqti) kiritganligini tekshiradi',
		az: 'İstifadəçinin tələb olunan parametrləri daxil edib-etmədiyini yoxlayır (məsələn, sessiyanın tarixi və vaxtı)',
		ka: 'შეგამოწმებს, შეიყვანა თუ არა მომხმარებელმა საჭირო პარამეტრები (მაგალითად, სესიის თარიღი და დრო)',
		pt: 'Verifica se o usuário inseriu os parâmetros obrigatórios (por exemplo, a data e hora da sessão)'
	},
	CHECK_PARAMS_2: {
		ru: 'Проверка параметров',
		en: 'Check parameters',
		de: 'Parameter prüfen',
		es: 'Comprobar parámetros',
		tr: 'Parametre kontrolü',
		kk: 'Параметрлерді тексеру',
		uz: 'Parametrlarni tekshirish',
		az: 'Parametrləri yoxlamaq',
		ka: 'პარამეტრების შემოწმება',
		pt: 'Verificar parâmetros'
	},
	CHECK_PARAMS_2_DESCRIPTION: {
		ru: 'Проверяет, ввёл ли пользователь обязательные параметры',
		en: 'Checks if the user has entered the required parameters',
		de: 'Prüft, ob der Benutzer die erforderlichen Parameter eingegeben hat',
		es: 'Verifica si el usuario ha ingresado los parámetros requeridos',
		tr: 'Kullanıcının gerekli parametreleri girip girmediğini kontrol eder',
		kk: 'Пайдаланушы міндетті параметрлерді енгізгенін тексереді',
		uz: 'Foydalanuvchi majburiy parametrlarni kiritganligini tekshiradi',
		az: 'İstifadəçinin tələb olunan parametrləri daxil edib-etmədiyini yoxlayır',
		ka: 'შეგამოწმებთ, შეიყვანა თუ არა მომხმარებელმა საჭირო პარამეტრები',
		pt: 'Verifica se o usuário inseriu os parâmetros obrigatórios'
	},
	CHOOSE_FILES: {
		ru: 'Выберите файлы',
		en: 'Choose files',
		de: 'Wählen Sie Dateien',
		es: 'Elegir archivos',
		tr: 'Dosyaları seçin',
		kk: 'Файлдарды таңдаңыз',
		uz: 'Fayllarni tanlang',
		az: 'Faylları seçin',
		ka: 'ფაილების არჩევა',
		pt: 'Escolher arquivos'
	},
	CHOOSE_WEBHOOK: {
		ru: 'Пожалуйста, выберите вебхук',
		en: 'Please choose a webhook',
		de: 'Bitte wählen Sie einen Webhook',
		es: 'Por favor, elija un webhook',
		tr: 'Lütfen bir webhook seçin',
		kk: 'Өтінемін, вебхукты таңдаңыз',
		uz: 'Iltimos, webhookni tanlang',
		az: 'Zəhmət olmasa, webhook seçin',
		ka: 'გთხოვთ, აირჩიოთ webhook',
		pt: 'Por favor, escolha um webhook'
	},
	CHOOSE_WEBHOOK_IN_ACTION: {
		ru: 'Пожалуйста, выберите вебхук в действии',
		en: 'Please select a webhook in the action',
		de: 'Bitte wählen Sie einen Webhook in der Aktion aus',
		es: 'Por favor, seleccione un webhook en la acción',
		tr: 'Lütfen aksiyonda bir webhook seçin',
		kk: 'Әрекетте вебхукті таңдаңыз',
		uz: 'Iltimos, harakatda webhookni tanlang',
		az: 'Zəhmət olmasa, hərəkətdə webhook seçin',
		ka: 'გთხოვთ, აირჩიოთ ვებჰუკი მოქმედებაში',
		pt: 'Por favor, selecione um webhook na ação'
	},
	CHOOSE_WEBHOOK_IN_CONDITION: {
		ru: 'Пожалуйста, выберите вебхук в условии',
		en: 'Please select a webhook in the condition',
		de: 'Bitte wählen Sie einen Webhook in der Bedingung aus',
		es: 'Por favor, seleccione un webhook en la condición',
		tr: 'Lütfen koşulda bir webhook seçin',
		kk: 'Шартта вебхукті таңдаңыз',
		uz: 'Iltimos, shartda webhookni tanlang',
		az: 'Zəhmət olmasa, şərtdə webhook seçin',
		ka: 'გთხოვთ, აირჩიოთ ვებჰუკი პირობაში',
		pt: 'Por favor, selecione um webhook na condição'
	},
	CODE: {
		ru: 'Код',
		'en': 'Code',
		'de': 'Code',
		'es': 'Código',
		'tr': 'Kod',
		'kk': 'Код',
		'uz': 'Kod',
		'az': 'Kod',
		'ka': 'კოდი',
		'pt': 'Código'
	},
	COMMANDS: {
		ru: 'Команды',
		en: 'Commands',
		de: 'Befehle',
		es: 'Comandos',
		tr: 'Komutlar',
		kk: 'Командалар',
		uz: 'Buyruqlar',
		az: 'Əmrlər',
		ka: 'კომანდები',
		pt: 'Comandos'
	},
	CONDITION: {
		ru: 'Условие',
		en: 'Condition',
		de: 'Bedingung',
		es: 'Condición',
		tr: 'Koşul',
		kk: 'Шарт',
		uz: 'Shart',
		az: 'Şərt',
		ka: 'პირობა',
		pt: 'Condição'
	},
	CONDITION_ADD: {
		ru: 'Добавление условия',
		en: 'Adding condition',
		de: 'Bedingung hinzufügen',
		es: 'Agregar condición',
		tr: 'Koşul ekleme',
		kk: 'Шарт қосу',
		uz: `Shart qo'shish`,
		az: 'Şərt əlavə etmək',
		ka: 'პარამეტრის დამატება',
		pt: 'Adicionar condição'
	},
	CONDITION_EDIT: {
		ru: 'Редактирование условия',
		en: 'Editing condition',
		de: 'Bedingung bearbeiten',
		es: 'Editar condición',
		tr: 'Koşulu düzenleme',
		kk: 'Шартты өзгерту',
		uz: 'Shartni tahrirlash',
		az: 'Şərti redaktə etmək',
		ka: 'პარამეტრის რედაქტირება',
		pt: 'Editar condição'
	},
	CONDITION_MANAGE: {
		ru: 'Управление условиями срабатывания вебхуков',
		en: 'Managing webhook trigger conditions',
		de: 'Verwalten von Webhook-Auslösebedingungen',
		es: 'Gestionar condiciones de activación de webhook',
		tr: 'Webhook tetikleyici koşullarını yönetme',
		kk: 'Webhook іске қосу шарттарын басқару',
		uz: 'Webhook ishga tushirish shartlarini boshqarish',
		az: 'Webhook tetikleyici şərtlərini idarə etmək',
		ka: 'Webhook-ის გამორთვის პირობების მართვა',
		pt: 'Gerenciar condições de acionamento de webhook'
	},
	CONDITION_TYPE: {
		ru: 'Тип условия',
		en: 'Condition type',
		de: 'Bedingungstyp',
		es: 'Tipo de condición',
		tr: 'Koşul türü',
		kk: 'Шарт түрі',
		uz: 'Shart turi',
		az: 'Şərt növü',
		ka: 'პარამეტრის ტიპი',
		pt: 'Tipo de condição'
	},
	CONDITIONS: {
		ru: 'Условия',
		en: 'Conditions',
		de: 'Bedingungen',
		es: 'Condiciones',
		tr: 'Koşullar',
		kk: 'Шарттар',
		uz: 'Shartlar',
		az: 'Şərtlər',
		ka: 'პარამეტრები',
		pt: 'Condições'
	},
	CONDITIONS_DESCRIPTION: {
		ru: 'Проверяет условия для перехода на следующий шаг. Например, если введены все параметры, продолжить процесс',
		en: 'Checks conditions to move to the next step. For example, if all parameters are entered, continue the process',
		de: 'Prüft die Bedingungen, um zum nächsten Schritt zu wechseln. Zum Beispiel, wenn alle Parameter eingegeben wurden, den Prozess fortsetzen',
		es: 'Verifica las condiciones para pasar al siguiente paso. Por ejemplo, si se ingresan todos los parámetros, continúa con el proceso',
		tr: 'Sonraki adıma geçmek için koşulları kontrol eder. Örneğin, tüm parametreler girildiyse, işlemi devam ettir',
		kk: 'Келесі қадамға өту үшін шарттарды тексереді. Мысалы, барлық параметрлер енгізілген болса, процесті жалғастырыңыз',
		uz: 'Keyingi qadamga o‘tish uchun shartlarni tekshiradi. Masalan, barcha parametrlar kiritilgan bo‘lsa, jarayonni davom ettirish',
		az: 'Növbəti addıma keçmək üçün şərtləri yoxlayır. Məsələn, bütün parametrlər daxil edildikdə, prosesi davam etdirin',
		ka: 'შემოწმებს პირობებს შემდეგ ეტაპზე გადასასვლელად. მაგალითად, თუ ყველა პარამეტრი შეყვანილია, გააგრძელეთ პროცესი',
		pt: 'Verifica as condições para passar para o próximo passo. Por exemplo, se todos os parâmetros forem inseridos, continue o processo'
	},
	CONTENT_TYPE: {
		ru: 'Тип контента',
		en: 'Content Type',
		de: 'Inhaltstyp',
		es: 'Tipo de contenido',
		tr: 'İçerik Türü',
		kk: 'Мазмұн түрі',
		uz: 'Mazmun turi',
		az: 'Məzmun növü',
		ka: 'კონტენტის ტიპი',
		pt: 'Tipo de Conteúdo'
	},
	COPY: {
		ru: 'Копировать',
		en: 'Copy',
		de: 'Kopieren',
		es: 'Copiar',
		tr: 'Kopyala',
		kk: 'Көшіру',
		uz: 'Nusxa olish',
		az: 'Kopyalamaq',
		ka: 'კოპირება',
		pt: 'Copiar'
	},
	COOKIES: {
		ru: 'Куки',
		en: 'Cookies',
		de: 'Cookies',
		es: 'Cookies',
		tr: 'Çerezler',
		kk: 'Куки',
		uz: 'Kuki',
		az: 'Çərəzlər',
		ka: 'კუკიები',
		pt: 'Cookies'
	},
	COPY_TOKEN: {
		ru: 'Скопировать токен сценария',
		en: 'Copy script token',
		de: 'Script-Token kopieren',
		es: 'Copiar token del guion',
		tr: `Senaryo token'ını kopyala`,
		kk: 'Сценарий токенін көшіру',
		uz: 'Ssenariy tokenini nusxalash',
		az: 'Ssenari tokenini kopyala',
		ka: 'სცენარის ტოკენის კოპირება',
		pt: 'Copiar token do script'
	},
	CREATE: {
		ru: 'Создать',
		en: 'Create',
		de: 'Erstellen',
		es: 'Crear',
		tr: 'Oluştur',
		kk: 'Жасау',
		uz: 'Yaratish',
		az: 'Yarat',
		ka: 'შექმნა',
		pt: 'Criar'
	},
	CREATE_SCENARIOS: {
		ru: 'Создавать сценарии',
		en: 'Create scenarios',
		de: 'Szenarien erstellen',
		es: 'Crear escenarios',
		tr: 'Senaryolar oluştur',
		kk: 'Сценарийлер жасау',
		uz: 'Senariyalar yaratish',
		az: 'Ssenarilər yaratmaq',
		ka: 'სცენარების შექმნა',
		pt: 'Criar cenários'
	},
	CREATE_UPDATE_USERS: {
		ru: 'Создавать / Обновлять пользователей',
		en: 'Create / Update users',
		de: 'Benutzer erstellen / aktualisieren',
		es: 'Crear / Actualizar usuarios',
		tr: 'Kullanıcıları oluştur / Güncelle',
		kk: 'Пайдаланушыларды жасау / Жаңарту',
		uz: 'Foydalanuvchilarni yaratish / Yangilash',
		az: 'İstifadəçiləri yaratmaq / Yeniləmək',
		ka: 'მომხმარებლების შექმნა / განახლება',
		pt: 'Criar / Atualizar usuários'
	},
	DATE: {
		ru: 'Дата',
		en: 'Date',
		de: 'Datum',
		es: 'Fecha',
		tr: 'Tarih',
		kk: 'Күні',
		uz: 'Sana',
		az: 'Tarix',
		ka: 'თარიღი',
		pt: 'Data'
	},
	DEFAULT: {
		ru: 'По умолчанию',
		en: 'Default',
		de: 'Standard',
		es: 'Predeterminado',
		tr: 'Varsayılan',
		kk: 'Әдепкі',
		uz: 'Standart',
		az: 'Əsas',
		ka: 'დეფოლტი',
		pt: 'Padrão'
	},
	DELAY: {
		ru: 'Задержка',
		en: 'Delay',
		de: 'Verzögerung',
    	es: 'Retraso',
    	tr: 'Gecikme',
    	kk: 'Кідіріс',
    	uz: 'Kechikish',
    	az: 'Gecikmə',
    	ka: 'დაყოვნება',
    	pt: 'Atraso'
	},
	DELAY_DESCRIPTION: {
		ru: 'Задерживает поток данных на определённое время',
		en: 'Delays the data flow for a certain time',
		de: 'Verzögert den Datenfluss für eine bestimmte Zeit',
		es: 'Retrasa el flujo de datos durante un cierto tiempo',
		tr: 'Veri akışını belirli bir süre geciktirir',
		kk: 'Деректер ағынын белгілі бір уақытқа кешіктіреді',
		uz: 'Maʼlumot oqimini maʼlum vaqtga kechiktiradi',
		az: 'Məlumat axınını müəyyən müddət gecikdirir',
		ka: 'მონაცემთა ნაკადის გარკვეული დროით დაყოვნება',
		pt: 'Atrasar o fluxo de dados por um certo tempo'
	},
	DELETE_SCENARIO: {
		ru: 'Удалить сценарий',
		en: 'Delete scenario',
		de: 'Szenario löschen',
		es: 'Eliminar escenario',
		tr: 'Senaryo sil',
		kk: 'Сценарийді жою',
		uz: 'Ssenariyni o‘chirish',
		az: 'Ssenarini sil',
		ka: 'სცენარის წაშლა',
		pt: 'Excluir cenário'
	},
	DELETE_SCENARIO_QUESTION: {
		ru: 'Вы действительно хотите удалить сценарий',
		en: 'Are you sure you want to delete the scenario',
		de: 'Möchten Sie das Szenario wirklich löschen',
		es: '¿Está seguro de que desea eliminar el escenario',
		tr: 'Senaryo silmek istediğinizden emin misiniz',
		kk: 'Сценарийді шын мәнінде жойғыңыз келе ме',
		uz: `Ssenariyni o'chirishni xohlaysizmi`,
		az: 'Ssenarini silmək istədiyinizdən əminsiniz',
		ka: 'ნამდვილად გსურთ სცენარის წაშლა',
		pt: 'Tem certeza de que deseja excluir o cenário'
	},
	DELETE_USER_QUESTION: {
		ru: 'Вы действительно хотите удалить пользователя',
		en: 'Do you really want to delete the user',
		de: 'Möchten Sie den Benutzer wirklich löschen',
		es: '¿Realmente deseas eliminar al usuario',
		tr: 'Kullanıcıyı gerçekten silmek istiyor musunuz',
		kk: 'Сіз пайдаланушыны шынымен жойғыңыз келе ме',
		uz: 'Foydalanuvchini haqiqatdan o‘chirib tashlamoqchimisiz',
		az: 'İstifadəçini həqiqətən silmək istəyirsiniz',
		ka: 'ნამდვილად გსურთ მომხმარებლის წაშლა',
		pt: 'Você realmente deseja excluir o usuário'
	},
	DESCRIPTION: {
		ru: 'Описание',
		en: 'Description',
		de: 'Beschreibung',
		es: 'Descripción',
		tr: 'Açıklama',
		kk: 'Сипаттама',
		uz: 'Tavsif',
		az: 'Təsvir',
		ka: 'აღწერა',
		pt: 'Descrição'
	},
	DESTINATION_ARRAY: {
		ru: 'Массив назначения',
		en: 'Destination array',
		de: 'Ziel-Array',
		es: 'Matriz de destino',
		tr: 'Hedef dizi',
		kk: 'Мақсатты массив',
		uz: 'Manzil massiv',
		az: 'Təyinat massiv',
		ka: 'წ Ziel массивი',
		pt: 'Matriz de destino'
	},
	ENTER_COMPONENT_DESCRIPTION: {
		ru: 'Введите описание компонента',
		en: 'Enter component description',
		de: 'Geben Sie die Komponentenbeschreibung ein',
		es: 'Ingrese la descripción del componente',
		tr: 'Bileşen açıklamasını girin',
		kk: 'Компоненттің сипаттамасын енгізіңіз',
		uz: 'Komponent tavsifini kiriting',
		az: 'Komponent təsvirini daxil edin',
		ka: 'შეიყვანეთ კომპონენტის აღწერა',
		pt: 'Insira a descrição do componente'
	},
	ENTER_NAME: {
		ru: 'Введите имя',
		en: 'Enter name',
		de: 'Geben Sie den Namen ein',
		es: 'Introduzca el nombre',
		tr: 'Adınızı girin',
		kk: 'Атыңызды енгізіңіз',
		uz: 'Ismingizni kiriting',
		az: 'Adınızı daxil edin',
		ka: 'შეიყვანეთ სახელი',
		pt: 'Digite o nome'
	},
	ENTER_PASSWORD: {
		ru: 'Введите пароль',
		en: 'Enter password',
		de: 'Geben Sie das Passwort ein',
		es: 'Introduzca la contraseña',
		tr: 'Şifreyi girin',
		kk: 'Құпиясөзді енгізіңіз',
		uz: 'Parolingizni kiriting',
		az: 'Şifrəni daxil edin',
		ka: 'შეიყვანეთ პაროლი',
		pt: 'Digite a senha'
	},
	ENTER_SCENARIO_DESCRIPTION: {
		ru: 'Введите описание сценария',
		en: 'Enter scenario description',
		de: 'Geben Sie eine Szenariobeschreibung ein',
		es: 'Introduce la descripción del escenario',
		tr: 'Senaryo açıklamasını girin',
		kk: 'Сценарий сипаттамасын енгізіңіз',
		uz: 'Ssenariy tavsifini kiriting',
		az: 'Ssenariy təsvirini daxil edin',
		ka: 'შეიტანეთ სცენარის აღწერა',
		pt: 'Digite a descrição do cenário'
	},
	ENTER_SCENARIO_NAME: {
		ru: 'Введите название сценария',
		en: 'Enter scenario name',
		de: 'Geben Sie den Szenarionamen ein',
		es: 'Ingrese el nombre del escenario',
		tr: 'Senaryo adı girin',
		kk: 'Сценарий атын енгізіңіз',
		uz: 'Ssenariy nomini kiriting',
		az: 'Ssenari adını daxil edin',
		ka: 'მოცემის სახელი შეიყვანეთ',
		pt: 'Digite o nome do cenário'
	},
	ENTER_SYSTEM: {
		ru: 'Войдите в систему, чтобы продолжить использование IntellectDialog.',
		en: 'Enter the system to continue using IntellectDialog.',
		de: 'Melden Sie sich an, um IntellectDialog weiter zu verwenden.',
		es: 'Inicie sesión para continuar utilizando IntellectDialog.',
		tr: `IntellectDialog'ı kullanmaya devam etmek için sisteme giriş yapın.`,
		kk: 'IntellectDialog қолдануды жалғастыру үшін жүйеге кіріңіз.',
		uz: `IntellectDialog'ni ishlatishni davom ettirish uchun tizimga kiring.`,
		az: 'IntellectDialog istifadə etməyə davam etmək üçün sisteme daxil olun.',
		ka: 'IntellectDialog-ის გამოყენება განაგრძეთ სისტემაში შესვლით.',
		pt: 'Entre no sistema para continuar usando o IntellectDialog.'
	},
	ENTER_USERNAME: {
		ru: 'Введите имя пользователя',
		en: 'Enter username',
		de: 'Geben Sie den Benutzernamen ein',
		es: 'Introduzca el nombre de usuario',
		tr: 'Kullanıcı adını girin',
		kk: 'Пайдаланушы атын енгізіңіз',
		uz: 'Foydalanuvchi nomini kiriting',
		az: 'İstifadəçi adını daxil edin',
		ka: 'შეიყვანეთ მომხმარებლის სახელი',
		pt: 'Digite o nome de usuário'
	},
	ENTER_VALUE_TO_SAVE: {
		ru: 'Пожалуйста введите значение для сохранения',
		en: 'Please enter a value to save',
		de: 'Bitte geben Sie einen Wert zum Speichern ein',
		es: 'Por favor, ingrese un valor para guardar',
		tr: 'Lütfen kaydetmek için bir değer girin',
		kk: 'Сақтау үшін мән енгізіңіз',
		uz: 'Saqlash uchun qiymat kiriting',
		az: 'Saxlamaq üçün dəyər daxil edin',
		ka: 'გთხოვთ, შეიყვანოთ მნიშვნელობა შესანახად',
		pt: 'Por favor, insira um valor para salvar'
	},
	ERROR: {
		ru: 'Ошибка',
		en: 'Error',
		de: 'Fehler',
		es: 'Error',
		tr: 'Hata',
		kk: 'Қате',
		uz: 'Xato',
		az: 'Səhv',
		ka: 'შეცდომა',
		pt: 'Erro'
	},
	EXAMPLE: {
		ru: 'Например',
		en: 'For example',
		de: 'Zum Beispiel',
		es: 'Por ejemplo',
		tr: 'Örneğin',
		kk: 'Мысалы',
		uz: 'Masalan',
		az: 'Məsələn',
		ka: 'მაგალითად',
		pt: 'Por exemplo'
	},
	EXPORT: {
		ru: 'Экспортировать',
		en: 'Export',
		de: 'Exportieren',
		es: 'Exportar',
		tr: 'Dışa Aktar',
		kk: 'Экспорттау',
		uz: 'Eksport qilish',
		az: 'İxrac etmək',
		ka: 'ექსპორტი',
		pt: 'Exportar'
	},
	INTERFACE_LANGUAGE: {
		ru: 'Язык интерфейса',
		en: 'Interface language',
		de: 'Sprache der Benutzeroberfläche',
		es: 'Idioma de la interfaz',
		tr: 'Arayüz dili',
		kk: 'Интерфейс тілі',
		uz: 'Interfeys tili',
		az: 'İnterfeys dili',
		ka: 'ინტერფეისის ენა',
		pt: 'Idioma da interface'
	},
	FILE_MANAGER: {
		ru: 'Файловый менеджер',
		en: 'File manager',
		de: 'Dateimanager',
		es: 'Administrador de archivos',
		tr: 'Dosya yöneticisi',
		kk: 'Файл менеджері',
		uz: 'Fayl menejeri',
		az: 'Fayl meneceri',
		ka: 'ფაილების მენეჯერი',
		pt: 'Gerenciador de arquivos'
	},
	FILES: {
		ru: 'Файлы',
		en: 'Files',
		de: 'Dateien',
		es: 'Archivos',
		tr: 'Dosyalar',
		kk: 'Файлдар',
		uz: 'Fayllar',
		az: 'Fayllar',
		ka: 'ფაილები',
		pt: 'Arquivos'
	},
	FILES_UPLOADED: {
		ru: 'Файлы успешно загружены.',
		en: 'Files uploaded successfully.',
		de: 'Dateien erfolgreich hochgeladen.',
		es: 'Archivos cargados exitosamente.',
		tr: 'Dosyalar başarıyla yüklendi.',
		kk: 'Файлдар сәтті жүктелді.',
		uz: 'Fayllar muvaffaqiyatli yuklandi.',
		az: 'Fayllar uğurla yükləndi.',
		ka: 'ფაილები წარმატებით ატვირთა.',
		pt: 'Arquivos carregados com sucesso.'
	},
	FILES_DELETED: {
		en: 'Files deleted successfully.',
		ru: 'Файлы успешно удалены.',
		de: 'Dateien erfolgreich gelöscht.',
		es: 'Archivos eliminados con éxito.',
		tr: 'Dosyalar başarıyla silindi.',
		kk: 'Файлдар сәтті жойылды.',
		uz: `Fayllar muvaffaqiyatli o'chirildi.`,
		az: 'Fayllar uğurla silindi.',
		ka: 'ფაილები წარმატებით წაიშალა.',
		pt: 'Arquivos excluídos com sucesso.'
	},
	FILTER: {
		ru: 'Фильтрация',
		en: 'Filter',
		de: 'Filter',
		es: 'Filtro',
		tr: 'Filtre',
		kk: 'Фильтрация',
		uz: 'Filtrlash',
		az: 'Filtr',
		ka: 'ფილტრი',
		pt: 'Filtro'
	},
	FILTER_CONDITION: {
		ru: 'Условие для фильтрации',
		en: 'Filter Condition',
		de: 'Filterbedingung',
		es: 'Condición de filtro',
		tr: 'Filtre Koşulu',
		kk: 'Фильтрация шарттары',
		uz: 'Filtrlash sharti',
		az: 'Filtrləmə Şərti',
		ka: 'ფილტრაციის პირობა',
		pt: 'Condição de Filtro'
	},
	FILTER_DESCRIPTION: {
		ru: 'Отбирает только те данные, которые соответствуют заданным условиям. Например, фильтрация только персональных занятий.',
		en: 'Filters only the data that matches the given conditions. For example, filtering only personal lessons.',
		de: 'Filtert nur die Daten, die den angegebenen Bedingungen entsprechen. Zum Beispiel: nur persönliche Lektionen filtern.',
		es: 'Filtra solo los datos que coinciden con las condiciones dadas. Por ejemplo, filtrar solo lecciones personales.',
		tr: 'Yalnızca verilen koşullara uyan verileri filtreler. Örneğin, yalnızca kişisel dersleri filtrelemek.',
		kk: 'Тек берілген шарттарға сәйкес келетін деректерді таңдайды. Мысалы, тек жеке сабақтарды фильтрациялау.',
		uz: `Faqat belgilangan shartlarga mos keladigan ma'lumotlarni ajratib oladi. Masalan, faqat shaxsiy darslarni filtrlash.`,
		az: 'Yalnızca verilən şərtlərə uyğun olan məlumatları seçir. Məsələn, yalnız şəxsi dərsləri filtrləmək.',
		ka: 'ფილტრავს მხოლოდ იმ მონაცემებს, რომლებიც შეესაბამება მოცემულ პირობებს. მაგალითად, მხოლოდ პირადი გაკვეთილების ფილტრაცია.',
		pt: 'Filtra apenas os dados que correspondem às condições fornecidas. Por exemplo, filtrar apenas as lições pessoais.'
	},
	FILTER_DESCRIPTION_2: {
		ru: 'Отбирает данные, которые соответствуют условиям',
		en: 'Filters the data that matches the conditions',
		de: 'Filtert die Daten, die den Bedingungen entsprechen',
		es: 'Filtra los datos que coinciden con las condiciones',
		tr: 'Koşullara uyan verileri filtreler',
		kk: 'Шарттарға сәйкес келетін деректерді фильтрациялайды',
		uz: `Shartlarga mos keladigan ma'lumotlarni filtrlash`,
		az: 'Şərtlərə uyğun olan məlumatları filtrləyir',
		ka: 'ფილტრავს მონაცემებს, რომლებიც შეესაბამება პირობებს',
		pt: 'Filtra os dados que correspondem às condições'
	},
	FINISH: {
		ru: 'Финиш',
		en: 'Finish',
		de: 'Fertig',
		es: 'Terminar',
		tr: 'Bitiş',
		kk: 'Аяқтау',
		uz: 'Tugash',
		az: 'Son',
		ka: 'დასრულება',
		pt: 'Fim'
	},
	FROM: {
		ru: 'С',
		en: 'From',
		de: 'Von',
		es: 'Desde',
		tr: 'Başlangıç',
		kk: 'Бастап',
		uz: 'Dan',
		az: 'Başlanğıc',
		ka: 'დან',
		pt: 'De'
	},
	FUNCTION_ADD: {
		ru: 'Добавление функции',
		en: 'Add function',
		de: 'Funktion hinzufügen',
		es: 'Agregar función',
		tr: 'Fonksiyon ekle',
		kk: 'Функцияны қосу',
		uz: `Funksiya qo'shish`,
		az: 'Funksiya əlavə et',
		ka: 'ფუნქციის დამატება',
		pt: 'Adicionar função'
	},
	FUNCTION_EDIT: {
		ru: 'Редактирование функции',
		en: 'Function edit',
		de: 'Funktion bearbeiten',
		es: 'Editar función',
		tr: 'Fonksiyon düzenle',
		kk: 'Функцияны өңдеу',
		uz: 'Funksiyani tahrirlash',
		az: 'Funksiyanı redaktə et',
		ka: 'ფუნქციის რედაქტირება',
		pt: 'Editar função'
	},
	FUNCTION_DESC_CANNOT_BE_EMPTY: {
		ru: 'Описание функции не может быть пустым',
		en: 'Function description cannot be empty',
		de: 'Funktionsbeschreibung darf nicht leer sein',
		es: 'La descripción de la función no puede estar vacía',
		tr: 'Fonksiyon açıklaması boş olamaz',
		kk: 'Функция сипаттамасы бос болмауы керек',
		uz: `Funksiya tavsifi bo'sh bo'lmasligi kerak`,
		az: 'Funksiya təsviri boş ola bilməz',
		ka: 'ფუნქციის აღწერა ცარიელი არ შეიძლება იყოს',
		pt: 'A descrição da função não pode estar vazia'
	},
	FUNCTION_NAME_CANNOT_BE_EMPTY: {
		ru: 'Название функции не может быть пустым',
		en: 'Function name cannot be empty',
		de: 'Funktionsname darf nicht leer sein',
		es: 'El nombre de la función no puede estar vacío',
		tr: 'Fonksiyon adı boş olamaz',
		kk: 'Функция атауы бос болмауы керек',
		uz: `Funksiya nomi bo'sh bo'lmasligi kerak`,
		az: 'Funksiya adı boş ola bilməz',
		ka: 'ფუნქციის სახელი ცარიელი არ შეიძლება იყოს',
		pt: 'O nome da função não pode estar vazio'
	},
	FUNCTIONS: {
		ru: 'Функции',
		en: 'Functions',
		de: 'Funktionen',
		es: 'Funciones',
		tr: 'Fonksiyonlar',
		kk: 'Функциялар',
		uz: 'Funktsiyalar',
		az: 'Funksiyalar',
		ka: 'ფუნქციები',
		pt: 'Funções'
	},
	GENERATE: {
		ru: 'Сгенерировать',
		en: 'Generate',
		de: 'Generieren',
		es: 'Generar',
		tr: 'Oluştur',
		kk: 'Генерациялау',
		uz: 'Yaratish',
		az: 'Yarat',
		ka: 'გენერირება',
		pt: 'Gerar'
	},
	APPLY_CODE: {
		ru: 'Применить код',
		en: 'Apply code',
		de: 'Code anwenden',
		es: 'Aplicar código',
		tr: 'Kodu Uygula',
		kk: 'Кодты қолдану',
		uz: 'Kodni qo‘llash',
		az: 'Kodu tətbiq et',
		ka: 'კოდის გამოყენება',
		pt: 'Aplicar código'
	},
	GENERATE_CODE: {
		ru: 'Сгенерировать код',
		en: 'Generate code',
		de: 'Code generieren',
		es: 'Generar código',
		tr: 'Kod oluştur',
		kk: 'Кодты жасау',
		uz: 'Kod yaratish',
		az: 'Kod yarat',
		ka: 'კოდის გენერირება',
		pt: 'Gerar código'
	},
	GENERATED_CODE: {
		ru: 'Сгенерированный код',
		en: 'Generated code',
		de: 'Generierter Code',
		es: 'Código generado',
		tr: 'Oluşturulan kod',
		kk: 'Генерацияланған код',
		uz: 'Yaratilgan kod',
		az: 'Yaradılmış kod',
		ka: 'გენერირებული კოდი',
		pt: 'Código gerado'
	},
	GLOBAL_SAVE: {
		ru: 'Глоб. сейв',
		en: 'Global Save',
		de: 'Globaler Speicher',
		es: 'Guardar Global',
		tr: 'Küresel Kaydet',
		kk: 'Глобал сақта',
		uz: 'Global Saqlash',
		az: 'Qlobal Saxla',
		ka: 'გლობალური შენახვა',
		pt: 'Salvar Global'
	},
	GO_TO_SCENARIO: {
		ru: 'Перейти на страницу конструктора',
		en: 'Go to the builder page',
		de: 'Gehe zur Builder-Seite',
		es: 'Ir a la página del constructor',
		tr: 'Yapı sayfasına git',
		kk: 'Құрылысшы бетіне өту',
		uz: `Quruvchi sahifasiga o'tish`,
		az: 'Konstruktora səhifəsinə getmək',
		ka: 'გადადით კონსტრუქტორის გვერდზე',
		pt: 'Ir para a página do construtor'
	},
	HEADER: {
		ru: 'Заголовок',
		en: 'Header',
		de: 'Überschrift',
		es: 'Encabezado',
		tr: 'Başlık',
		kk: 'Тақырып',
		uz: 'Sarlavha',
		az: 'Başlıq',
		ka: 'სათაური',
		pt: 'Cabeçalho'
	},
	HEADERS: {
		ru: 'Заголовки',
		en: 'Headers',
		de: 'Kopfzeilen',
		es: 'Encabezados',
		tr: 'Başlıklar',
		kk: 'Бас тақырыптар',
		uz: 'Bosh sahifa',
		az: 'Başlıqlar',
		ka: 'სათაურები',
		pt: 'Cabeçalhos'
	},
	IMPORT: {
		ru: 'Импортировать',
		en: 'Import',
		de: 'Importieren',
		es: 'Importar',
		tr: 'İçe Aktar',
		kk: 'Импорттау',
		uz: 'Import qilish',
		az: 'İdxal etmək',
		ka: 'იმპორტი',
		pt: 'Importar'
	},
	INSTRUCTION: {
		ru: 'Инструкция',
		en: 'Instruction',
		de: 'Anleitung',
		es: 'Instrucción',
		tr: 'Talimat',
		kk: 'Нұсқаулық',
		uz: `Ko'rsatma`,
		az: 'Təlimat',
		ka: 'ინსტრუქცია',
		pt: 'Instrução'
	},
	INSTRUCTION_HELP: {
		ru: 'Текстовая инструкция для генерирования кода',
		en: 'Text instruction for generating code',
		de: 'Textanleitung zur Codegenerierung',
		es: 'Instrucción de texto para generar código',
		tr: 'Kod oluşturma için metin talimatı',
		kk: 'Кодты генерациялау үшін мәтіндік нұсқаулық',
		uz: `Kod yaratish uchun matnli ko'rsatma`,
		az: 'Kod yaratmaq üçün mətn təlimatı',
		ka: 'ტექსტური ინსტრუქცია კოდის გენერაციისთვის',
		pt: 'Instrução de texto para gerar código'
	},
	INSTRUCTION_PLACEHOLDER: {
		ru: 'Мне нужно написать код для генерирования текстовой строки. В строке нужно прописать все модели машин вместе с ценой, цветом и годом выпуска.',
		en: 'I need to write code for generating a text string. The string should list all car models along with their price, color, and year of manufacture.',
		de: 'Ich muss Code schreiben, um eine Textzeile zu generieren. Die Zeile sollte alle Automodelle mit Preis, Farbe und Baujahr auflisten.',
		es: 'Necesito escribir un código para generar una cadena de texto. La cadena debe enumerar todos los modelos de automóviles junto con su precio, color y año de fabricación.',
		tr: 'Metin dizisi oluşturmak için bir kod yazmam gerekiyor. Dizide tüm araba modelleri fiyatları, renkleri ve üretim yıllarıyla birlikte listelenmelidir.',
		kk: 'Мәтіндік жолды генерациялау үшін код жазуым керек. Жолда барлық көлік модельдері, олардың бағасы, түсі және шыққан жылы көрсетілуі тиіс.',
		uz: `Matn satrini yaratish uchun kod yozishim kerak. Satrda barcha avtomobil modellari, ularning narxi, rangi va chiqarilgan yili ko'rsatilgan bo'lishi kerak.`,
		az: 'Mətn sətirini yaratmaq üçün kod yazmalıyam. Sətirdə bütün avtomobil modelləri qiyməti, rəngi və buraxılış ili ilə göstərilməlidir.',
		ka: 'ტექსტური ხაზის გენერაციისთვის კოდის დაწერა მჭირდება. ხაზში უნდა იყოს ყველა ავტომობილის მოდელი ფასით, ფერით და გამოშვების წელთან ერთად.',
		pt: 'Preciso escrever um código para gerar uma string de texto. A string deve listar todos os modelos de carros com seu preço, cor e ano de fabricação.'
	},
	KNOWLEDGE_BASES: {
		ru: 'Базы знаний',
		en: 'Knowledge bases',
		de: 'Wissensdatenbanken',
		es: 'Bases de conocimiento',
		tr: 'Bilgi tabanları',
		kk: 'Білім базалары',
		uz: 'Bilim bazalari',
		az: 'Bilik bazaları',
		ka: 'ცოდნის ბაზები',
		pt: 'Bases de conhecimento'
	},
	LANGUAGE: {
		ru: 'Язык',
		en: 'Language',
		de: 'Sprache',
		es: 'Idioma',
		tr: 'Dil',
		kk: 'Тіл',
		uz: 'Til',
		az: 'Dil',
		ka: 'ენა',
		pt: 'Idioma'
	},
	LOG_CONDITION_SELECTED: {
		ru: 'Выбрано условие: %1.',
		en: 'Condition selected: %1.',
		de: 'Bedingung ausgewählt: %1.',
		es: 'Condición seleccionada: %1.',
		tr: 'Şart seçildi: %1.',
		kk: 'Шарт таңдалды: %1.',
		uz: 'Shart tanlandi: %1.',
		az: 'Şərt seçildi: %1.',
		ka: 'პირობა არჩეულია: %1.',
		pt: 'Condição selecionada: %1.'
	},
	LOG_DEFAULT_CONDITION_SELECTED: {
		ru: 'Выбрано условие: По умолчанию.',
		en: 'Condition selected: Default.',
		de: 'Bedingung ausgewählt: Standard.',
		es: 'Condición seleccionada: Predeterminada.',
		tr: 'Şart seçildi: Varsayılan.',
		kk: 'Шарт таңдалды: Әдепкі.',
		uz: 'Shart tanlandi: Standart.',
		az: 'Şərt seçildi: Varsayılan.',
		ka: 'პირობა არჩეულია: ნაგულისხმევი.',
		pt: 'Condição selecionada: Padrão.'
	},
	LOG_ERROR_OCCURRED: {
		ru: 'Произошла ошибка: %1',
		en: 'An error occurred: %1',
		de: 'Ein Fehler ist aufgetreten: %1',
		es: 'Ocurrió un error: %1',
		tr: 'Bir hata oluştu: %1',
		kk: 'Қате пайда болды: %1',
		uz: 'Xato yuz berdi: %1',
		az: 'Səhv baş verdi: %1',
		ka: 'შეცდომა მოხდა: %1',
		pt: 'Ocorreu um erro: %1'
	},
	LOG_JOB_STATUS: {
		ru: 'JobId: %1. Статус: %2',
		en: 'JobId: %1. Status: %2',
		de: 'JobId: %1. Status: %2',
		es: 'JobId: %1. Estado: %2',
		tr: 'JobId: %1. Durum: %2',
		kk: 'JobId: %1. Мәртебесі: %2',
		uz: 'JobId: %1. Holati: %2',
		az: 'JobId: %1. Statusu: %2',
		ka: 'JobId: %1. სტატუსი: %2',
		pt: 'JobId: %1. Status: %2'
	},
	LOG_LOGS_FOUND: {
		ru: 'Найдены логи: %1. JobId: %2.',
		en: 'Logs found: %1. JobId: %2.',
		de: 'Logs gefunden: %1. JobId: %2.',
		es: 'Registros encontrados: %1. JobId: %2.',
		tr: 'Loglar bulundu: %1. JobId: %2.',
		kk: 'Журналдар табылды: %1. JobId: %2.',
		uz: 'Loglar topildi: %1. JobId: %2.',
		az: 'Loglar tapıldı: %1. JobId: %2.',
		ka: 'ლოგები ნაპოვნია: %1. JobId: %2.',
		pt: 'Registros encontrados: %1. JobId: %2.'
	},
	LOG_MODEL_ANSWER: {
		ru: 'Ответ модели %1: Вызвать функцию %2.\nИспользуемые параметры: %3',
		en: 'Model response %1: Call function %2.\nUsed parameters: %3',
		de: 'Modellantwort %1: Funktion %2 aufrufen.\nVerwendete Parameter: %3',
		es: 'Respuesta del modelo %1: Llamar a la función %2.\nParámetros utilizados: %3',
		tr: 'Model yanıtı %1: %2 fonksiyonunu çağır.\nKullanılan parametreler: %3',
		kk: 'Үлгі жауабы %1: %2 функциясын шақыру.\nҚолданылған параметрлер: %3',
		uz: 'Model javobi %1: %2 funksiyasini chaqiring.\nFoydalanilgan parametrlar: %3',
		az: 'Model cavabı %1: %2 funksiyasını çağırın.\nİstifadə olunan parametrlər: %3',
		ka: 'მოდელის პასუხი %1: ფუნქცია %2 გამოიძახე.\nგამოყენებული პარამეტრები: %3',
		pt: 'Resposta do modelo %1: Chamar a função %2.\nParâmetros utilizados: %3'
	},
	LOG_NO_FUNCTION_SELECTED: {
		ru: 'Ни одна функция не выбрана. Модель %1 ответила текстом.',
		en: 'No function selected. Model %1 responded with text.',
		de: 'Keine Funktion ausgewählt. Modell %1 hat mit Text geantwortet.',
		es: 'No se seleccionó ninguna función. El modelo %1 respondió con texto.',
		tr: 'Hiçbir fonksiyon seçilmedi. Model %1 metinle yanıt verdi.',
		kk: 'Ешқандай функция таңдалған жоқ. Үлгі %1 мәтінмен жауап берді.',
		uz: 'Hech qanday funksiya tanlanmadi. Model %1 matn bilan javob berdi.',
		az: 'Heç bir funksiya seçilmədi. Model %1 mətnlə cavab verdi.',
		ka: 'არცერთი ფუნქცია არჩეული არ არის. მოდელმა %1 ტექსტით უპასუხა.',
		pt: 'Nenhuma função selecionada. O modelo %1 respondeu com texto.'
	},
	LOG_NOTHING_FOR_STEP: {
		ru: 'Ничего не найдено по данному этапу.',
		en: 'Nothing found for this step.',
		de: 'Nichts für diesen Schritt gefunden.',
		es: 'Nada encontrado para este paso.',
		tr: 'Bu adım için bir şey bulunamadı.',
		kk: 'Бұл кезең үшін ештеңе табылмады.',
		uz: 'Ushbu bosqich uchun hech narsa topilmadi.',
		az: 'Bu mərhələ üçün heç nə tapılmadı.',
		ka: 'ამ ეტაპისთვის ვერაფერი მოიძებნა.',
		pt: 'Nada encontrado para esta etapa.'
	},
	LOG_PARAMETERS: {
		ru: 'Переменные: %1.',
		en: 'Parameters: %1.',
		de: 'Parameter: %1.',
		es: 'Parámetros: %1.',
		tr: 'Parametreler: %1.',
		kk: 'Параметрлер: %1.',
		uz: 'Parametrlar: %1.',
		az: 'Parametrlər: %1.',
		ka: 'პარამეტრები: %1.',
		pt: 'Parâmetros: %1.'
	},
	LOG_SCENARIO_ACTIONS: {
		ru: 'Действия по сценарию: %1',
		en: 'Scenario actions: %1',
		de: 'Szenario-Aktionen: %1',
		es: 'Acciones del escenario: %1',
		tr: 'Senaryo eylemleri: %1',
		kk: 'Сценарий бойынша әрекеттер: %1',
		uz: 'Ssenariy harakatlari: %1',
		az: 'Ssenari üzrə tədbirlər: %1',
		ka: 'სცენარის მოქმედებები: %1',
		pt: 'Ações do cenário: %1'
	},
	LOG_SCENARIO_NOT_FOUND: {
		ru: 'Сценарий %1 не найден.',
		en: 'Scenario %1 not found.',
		de: 'Szenario %1 nicht gefunden.',
		es: 'Escenario %1 no encontrado.',
		tr: 'Senaryo %1 bulunamadı.',
		kk: 'Сценарий %1 табылмады.',
		uz: 'Ssenariy %1 topilmadi.',
		az: 'Ssenari %1 tapılmadı.',
		ka: 'სცენარი %1 ვერ მოიძებნა.',
		pt: 'Cenário %1 não encontrado.'
	},
	LOG_SCENARIO_STARTED: {
		ru: 'Начался сценарий: %1. Параметры: %2',
		en: 'Scenario started: %1. Parameters: %2',
		de: 'Szenario gestartet: %1. Parameter: %2',
		es: 'Escenario iniciado: %1. Parámetros: %2',
		tr: 'Senaryo başladı: %1. Parametreler: %2',
		kk: 'Сценарий басталды: %1. Параметрлер: %2',
		uz: 'Ssenariy boshlandi: %1. Parametrlar: %2',
		az: 'Ssenari başladı: %1. Parametrlər: %2',
		ka: 'სცენარი დაიწყო: %1. პარამეტრები: %2',
		pt: 'Cenário iniciado: %1. Parâmetros: %2'
	},
	LOG_SCENARIO_STEP_STARTED: {
		ru: 'Начался этап сценария: %1.',
		en: 'Scenario step started: %1.',
		de: 'Szenario-Schritt gestartet: %1.',
		es: 'Paso del escenario iniciado: %1.',
		tr: 'Senaryo adımı başladı: %1.',
		kk: 'Сценарийдің кезеңі басталды: %1.',
		uz: 'Ssenariy bosqichi boshlandi: %1.',
		az: 'Ssenarinin mərhələsi başladı: %1.',
		ka: 'სცენარის ეტაპი დაიწყო: %1.',
		pt: 'Etapa do cenário iniciada: %1.'
	},
	LOG_SCENARIO_WITH_FUNCTIONS_NOT_FOUND: {
		ru: 'Сценарий со списком функций не найден',
		en: 'Scenario with a list of functions not found',
		de: 'Szenario mit Funktionsliste nicht gefunden',
		es: 'Escenario con una lista de funciones no encontrado',
		tr: 'Fonksiyon listesi içeren senaryo bulunamadı',
		kk: 'Функциялар тізімі бар сценарий табылмады',
		uz: `Funksiyalar ro'yxati bilan ssenariy topilmadi`,
		az: 'Funksiya siyahısı ilə ssenari tapılmadı',
		ka: 'ფუნქციების ჩამონათვალის მქონე სცენარი ვერ მოიძებნა',
		pt: 'Cenário com uma lista de funções não encontrado'
	},
	LOG_START_COMPONENT_STARTED: {
		ru: 'Начался стартовый сценарий со списком функций',
		en: 'The initial scenario with a list of functions has started',
		de: 'Das anfängliche Szenario mit Funktionsliste hat begonnen',
		es: 'Comenzó el escenario inicial con una lista de funciones',
		tr: 'Fonksiyon listesi içeren başlangıç senaryosu başladı',
		kk: 'Функциялар тізімі бар бастапқы сценарий басталды',
		uz: `Funksiyalar ro'yxati bilan boshlang'ich ssenariy boshlandi`,
		az: 'Funksiya siyahısı ilə başlanğıc ssenari başladı',
		ka: 'ფუნქციების ჩამონათვალის მქონე საწყისი სცენარი დაიწყო',
		pt: 'O cenário inicial com uma lista de funções começou'
	},
	LOGIN: {
		ru: 'Войти',
		en: 'Login',
		de: 'Anmelden',
		es: 'Iniciar sesión',
		tr: 'Giriş',
		kk: 'Кіру',
		uz: 'Kirish',
		az: 'Daxil ol',
		ka: 'შესვლა',
		pt: 'Entrar'
	},
	LOGOUT: {
		ru: 'Выйти',
		en: 'Log out',
		de: 'Abmelden',
		es: 'Cerrar sesión',
		tr: 'Çıkış Yap',
		kk: 'Шығу',
		uz: 'Chiqish',
		az: 'Çıxış',
		ka: 'გამოსვლა',
		pt: 'Sair'
	},
	MAPPER: {
		ru: 'Mapper',
		en: 'Mapper',
		de: 'Mapper',
		es: 'Mapeador',
		tr: 'Harita oluşturucu',
		kk: 'Маппер',
		uz: 'Mapper',
		az: 'Mapper',
		ka: 'მაპერი',
		pt: 'Mapeador'
	},
	MAPPER_DESCRIPTION: {
		ru: 'Преобразует или форматирует данные (массив), чтобы их можно было использовать на следующем шаге',
		en: 'Converts or formats the data (array) to be used in the next step',
		de: 'Konvertiert oder formatiert die Daten (Array), um sie im nächsten Schritt zu verwenden',
		es: 'Convierte o formatea los datos (arreglo) para su uso en el siguiente paso',
		tr: 'Verileri (diziyi) bir sonraki adımda kullanılmak üzere dönüştürür veya formatlar',
		kk: 'Деректерді (массивті) келесі қадамда қолдану үшін түрлендіреді немесе пішімдейді',
		uz: `Ma'lumotlarni (massivni) keyingi qadamda ishlatish uchun o'zgartiradi yoki formatlaydi`,
		az: 'Məlumatları (massivi) növbəti addımda istifadə etmək üçün çevririr və ya formatlayır',
		ka: 'გადაყენებს ან ფორმატავს მონაცემებს (მასივს), რათა გამოიყენოს შემდეგ ეტაპზე',
		pt: 'Converte ou formata os dados (array) para serem usados no próximo passo'
	},
	MAPPER_DESCRIPTION_2: {
		ru: 'Преобразует или форматирует массив, чтобы использовать в будущем',
		en: 'Converts or formats the array to be used in the future',
		de: 'Konvertiert oder formatiert das Array, um es in der Zukunft zu verwenden',
		es: 'Convierte o formatea el arreglo para su uso futuro',
		tr: 'Diziyi gelecekte kullanmak üzere dönüştürür veya formatlar',
		kk: 'Массивті болашақта қолдану үшін түрлендіреді немесе пішімдейді',
		uz: `Massivni kelajakda ishlatish uchun o'zgartiradi yoki formatlaydi`,
		az: 'Massivi gələcəkdə istifadə etmək üçün çevririr və ya formatlayır',
		ka: 'გადაყენებს ან ფორმატავს მასივს, რომ გამოიყენოს მომავალში',
		pt: 'Converte ou formata o array para ser usado no futuro'
	},
	MESSAGE_TO_BOT: {
		ru: 'Сообщение боту ...',
		en: 'Message to the bot...',
		de: 'Nachricht an den Bot...',
		es: 'Mensaje al bot...',
		tr: 'Bota mesaj...',
		kk: 'Ботқа хабарлама...',
		uz: 'Botga xabar...',
		az: 'Bota mesaj...',
		ka: 'შეტყობინება ბოტს...',
		pt: 'Mensagem para o bot...'
	},
	NAME: {
		ru: 'Имя',
		en: 'Name',
		de: 'Name',
		es: 'Nombre',
		tr: 'Ad',
		kk: 'Аты',
		uz: 'Ism',
		az: 'Ad',
		ka: 'სახელი',
		pt: 'Nome'
	},
	NAME_2: {
		ru: 'Название',
		en: 'Name',
		de: 'Name',
		es: 'Nombre',
		tr: 'Ad',
		kk: 'Атауы',
		uz: 'Nom',
		az: 'Ad',
		ka: 'სახელი',
		pt: 'Nome'
	},
	NO_FORMAT: {
		ru: 'Без формата',
		en: 'No format',
		de: 'Kein Format',
		es: 'Sin formato',
		tr: 'Format yok',
		kk: 'Форматсыз',
		uz: `Format yo'q`,
		az: 'Format yoxdur',
		ka: 'ფორმატის გარეშე',
		pt: 'Sem formato'
	},
	NOTHING_HERE: {
		ru: 'Тут пока ничего нет',
		en: `There's nothing here yet`,
		de: 'Hier ist noch nichts',
		es: 'Aquí no hay nada todavía',
		tr: 'Burada henüz bir şey yok',
		kk: 'Мұнда әлі ештеңе жоқ',
		uz: `Bu yerda hali hech narsa yo'q`,
		az: 'Burada hələ heç nə yoxdur',
		ka: 'აქ ჯერ არაფერია',
		pt: 'Ainda não há nada aqui'
	},
	NULLABLE: {
		ru: 'Нулевое',
		en: 'Nullable',
		de: 'Nullwert',
		es: 'Nulo',
		tr: 'Boş',
		kk: 'Бос',
		uz: 'Boʻsh',
		az: 'Boş',
		ka: 'ნულის',
		pt: 'Nulo'
	},
	ONLY_ONE_EDGE_ERROR_1: {
		ru: 'Компоненты можно соединить лишь одной связью',
		en: 'Components can only be connected by a single link',
		de: 'Komponenten können nur mit einer einzigen Verbindung verbunden werden',
		es: 'Los componentes solo se pueden conectar con un solo enlace',
		tr: 'Bileşenler yalnızca tek bir bağlantı ile bağlanabilir',
		kk: 'Компоненттерді тек бір ғана байланыспен қосуға болады',
		uz: 'Komponentlarni faqat bitta aloqa bilan ulash mumkin',
		az: 'Komponentləri yalnız bir bağlantı ilə birləşdirmək olar',
		ka: 'კომპონენტები მხოლოდ ერთი კავშირით შეიძლება იყოს დაკავშირებული',
		pt: 'Os componentes só podem ser conectados por um único link'
	},
	ONLY_ONE_EDGE_ERROR_2: {
		ru: 'Из одной точки может выходить только одна связь',
		en: 'Only one connection can originate from a single point',
		de: 'Von einem einzigen Punkt kann nur eine Verbindung ausgehen',
		es: 'Solo puede salir una conexión de un solo punto',
		tr: 'Tek bir noktadan yalnızca bir bağlantı çıkabilir',
		kk: 'Бір нүктеден тек бір ғана байланыс шыға алады',
		uz: 'Bitta nuqtadan faqat bitta aloqa chiqishi mumkin',
		az: 'Bir nöqtədən yalnız bir əlaqə çıxa bilər',
		ka: 'ერთი წერტილიდან შეიძლება მხოლოდ ერთი კავშირი გამოვიდეს',
		pt: 'Apenas uma conexão pode se originar de um único ponto'
	},
	ONLY_ONE_EDGE_ERROR_3: {
		ru: 'Из одного компонента может выходить только одна связь',
		en: 'Only one connection can originate from a single component',
		de: 'Von einer einzigen Komponente kann nur eine Verbindung ausgehen',
		es: 'Solo puede salir una conexión de un solo componente',
		tr: 'Tek bir bileşenden yalnızca bir bağlantı çıkabilir',
		kk: 'Бір компоненттен тек бір ғана байланыс шыға алады',
		uz: 'Bitta komponentdan faqat bitta aloqa chiqishi mumkin',
		az: 'Bir komponentdən yalnız bir əlaqə çıxa bilər',
		ka: 'ერთი კომპონენტიდან შეიძლება მხოლოდ ერთი კავშირი გამოვიდეს',
		pt: 'Apenas uma conexão pode se originar de um único componente'
	},
	OPTIMIZED_CODE: {
		ru: 'Оптимизированный код',
		'en': 'Optimized code',
		'de': 'Optimierter code',
		'es': 'Código optimizado',
		'tr': 'Optimize edilmiş kod',
		'kk': 'Оптимизацияланған код',
		'uz': 'Optimallashtirilgan kod',
		'az': 'Optimizə edilmiş kod',
		'ka': 'ოპტიმიზირებული კოდი',
		'pt': 'Código otimizado'
	},
	PARAMETER: {
		ru: 'Переменная',
		en: 'Variable',
		de: 'Variable',
		es: 'Variable',
		tr: 'Değişken',
		kk: 'Айнымалы',
		uz: `O'zgaruvchi`,
		az: 'Dəyişən',
		ka: 'ცვლადი',
		pt: 'Variável'
	},
	PARAMETER_2: {
		ru: 'Параметр',
		en: 'Parameter',
		de: 'Parameter',
		es: 'Parámetro',
		tr: 'Parametre',
		kk: 'Параметр',
		uz: 'Parametr',
		az: 'Parametr',
		ka: 'პარამეტრი',
		pt: 'Parâmetro'
	},
	PARAMETER_QUESTION: {
		ru: 'Вопрос для переменной (Как вас зовут?)',
		en: 'Question for the variable (What is your name?)',
		de: 'Frage für die Variable (Wie heißen Sie?)',
		es: 'Pregunta para la variable (¿Cómo te llamas?)',
		tr: 'Değişken için soru (Adınız nedir?)',
		kk: 'Айнымалы үшін сұрақ (Сіздің атыңыз кім?)',
		uz: `O'zgaruvchi uchun savol (Ismingiz nima?)`,
		az: 'Dəyişən üçün sual (Adınız nədir?)',
		ka: 'ცვლადისთვის კითხვა (თქვენი სახელი რა არის?)',
		pt: 'Pergunta para a variável (Qual é o seu nome?)'
	},
	PARAMETERS: {
		ru: 'Параметры',
		en: 'Parameters',
		de: 'Parameter',
		es: 'Parámetros',
		tr: 'Parametreler',
		kk: 'Параметрлер',
		uz: 'Parametrlar',
		az: 'Parametrlər',
		ka: 'პარამეტრები',
		pt: 'Parâmetros'
	},
	PARAMETERS_2: {
		ru: 'Переменные',
		en: 'Variables',
		de: 'Variablen',
		es: 'Variables',
		tr: 'Değişkenler',
		kk: 'Айнымалылар',
		uz: `O'zgaruvchilar`,
		az: 'Dəyişənlər',
		ka: 'მიმხრებები',
		pt: 'Variáveis'
	},
	PASSWORD: {
		ru: 'Пароль',
		en: 'Password',
		de: 'Passwort',
		es: 'Contraseña',
		tr: 'Parola',
		kk: 'Құпиясөз',
		uz: 'Parol',
		az: 'Şifrə',
		ka: 'პაროლი',
		pt: 'Senha'
	},
	PASTE: {
		ru: 'Вставить',
		en: 'Paste',
		de: 'Einfügen',
		es: 'Pegar',
		tr: 'Yapıştır',
		kk: 'Қою',
		uz: 'Qo‘yish',
		az: 'Yapışdırmaq',
		ka: 'ჩასმა',
		pt: 'Colar'
	},
	PERSONAL_INFO: {
		ru: 'Персональные данные',
		en: 'Personal information',
		de: 'Persönliche Informationen',
		es: 'Información personal',
		tr: 'Kişisel bilgiler',
		kk: 'Жеке деректер',
		uz: `Shaxsiy ma'lumotlar`,
		az: 'Şəxsi məlumatlar',
		ka: 'პერსონალური ინფორმაცია',
		pt: 'Informações pessoais'
	},
	PLEASE_ENTER_CODE: {
		ru: 'Пожалуйста, введите код.',
		'en': 'Please enter the code.',
		'de': 'Bitte geben Sie den Code ein.',
		'es': 'Por favor ingrese el código.',
		'tr': 'Lütfen kodu girin.',
		'kk': 'Кодты енгізіңіз.',
		'uz': 'Iltimos, kodni kiriting.',
		'az': 'Zəhmət olmasa, kodu daxil edin.',
		'ka': 'გთხოვთ შეიყვანოთ კოდი.',
		'pt': 'Por favor, insira o código.'
	},
	PLEASE_ENTER_CONDITION: {
		ru: 'Пожалуйста, введите условие',
		en: 'Please enter condition',
		de: 'Bitte geben Sie die Bedingung ein',
		es: 'Por favor, ingrese la condición',
		tr: 'Lütfen durumu girin',
		kk: 'Шартты енгізіңіз',
		uz: 'Iltimos, shartni kiriting',
		az: 'Zəhmət olmasa, şərti daxil edin',
		ka: 'გთხოვთ, შეიყვანოთ პირობები',
		pt: 'Por favor, insira a condição'
	},
	PLEASE_ENTER_CONDITION_TEXT: {
		ru: 'Пожалуйста, введите текст для условия',
		en: 'Please enter text for the condition',
		de: 'Bitte geben Sie den Text für die Bedingung ein',
		es: 'Por favor, ingrese el texto para la condición',
		tr: 'Lütfen şart için metin girin',
		kk: 'Шарт үшін мәтінді енгізіңіз',
		uz: 'Iltimos, shart uchun matn kiriting',
		az: 'Zəhmət olmasa, şərt üçün mətni daxil edin',
		ka: 'გთხოვთ, შეიყვანოთ ტექსტი პირობებისათვის',
		pt: 'Por favor, insira o texto para a condição'
	},
	PLEASE_ENTER_DESTINATION_ARRAY: {
		ru: 'Пожалуйста, введите массив назначения.',
		en: 'Please enter the destination array.',
		de: 'Bitte geben Sie das Ziel-Array ein.',
		es: 'Por favor, ingrese el array de destino.',
		tr: 'Lütfen hedef diziyi girin.',
		kk: 'Мақсатты массивті енгізіңіз.',
		uz: 'Iltimos, manzil massivini kiriting.',
		az: 'Zəhmət olmasa, təyinat massivini daxil edin.',
		ka: 'გთხოვთ, შეიტანოთ დანიშნულების მასივი.',
		pt: 'Por favor, insira o array de destino.'
	},
	PLEASE_ENTER_DESTINATION_PATH: {
		ru: 'Пожалуйста, введите путь назначения.',
		en: 'Please enter the destination path.',
		de: 'Bitte geben Sie den Zielpfad ein.',
		es: 'Por favor, ingrese la ruta de destino.',
		tr: 'Lütfen hedef yolu girin.',
		kk: 'Мақсатты жолды енгізіңіз.',
		uz: `Iltimos, manzil yo'lini kiriting.`,
		az: 'Zəhmət olmasa, təyinat yolunu daxil edin.',
		ka: 'გთხოვთ, შეიტანოთ დანიშნულების გზა.',
		pt: 'Por favor, insira o caminho de destino.'
	},
	PLEASE_ENTER_DESTINATION_VALUE: {
		ru: 'Пожалуйста, введите значение назначения',
		en: 'Please enter destination value',
		de: 'Bitte geben Sie den Zielwert ein',
		es: 'Por favor, ingrese el valor de destino',
		tr: 'Lütfen varış değeri girin',
		kk: 'Мақсат мәнін енгізіңіз',
		uz: 'Iltimos, manzil qiymatini kiriting',
		az: 'Zəhmət olmasa təyinat dəyərini daxil edin',
		ka: 'გთხოვთ შეიყვანოთ დანიშნულების მნიშვნელობა',
		pt: 'Por favor, insira o valor de destino'
	},
	PLEASE_ENTER_FILTER_CONDITION: {
		ru: 'Пожалуйста, введите условие для фильтрации.',
		en: 'Please enter filter condition.',
		de: 'Bitte geben Sie eine Filterbedingung ein.',
		es: 'Por favor, ingrese una condición de filtro.',
		tr: 'Lütfen bir filtre koşulu girin.',
		kk: 'Фильтр шартын енгізіңіз.',
		uz: 'Filtr shartini kiriting.',
		az: 'Xahiş edirəm, filter şərtini daxil edin.',
		ka: 'გთხოვთ, შეიყვანეთ ფილტრის პირობები.',
		pt: 'Por favor, insira a condição do filtro.'
	},
	PLEASE_ENTER_INSTRUCTION: {
		ru: 'Пожалуйста, введите инструкцию.',
		en: 'Please enter the instruction.',
		de: 'Bitte geben Sie die Anleitung ein.',
		es: 'Por favor, introduzca la instrucción.',
		tr: 'Lütfen talimatı girin.',
		kk: 'Нұсқаулықты енгізіңіз.',
		uz: `Iltimos, ko'rsatmani kiriting.`,
		az: 'Zəhmət olmasa, təlimatı daxil edin.',
		ka: 'გთხოვთ, შეიყვანეთ ინსტრუქცია.',
		pt: 'Por favor, insira a instrução.'
	},
	PLEASE_ENTER_NAME: {
		ru: 'Пожалуйста, введите допустимое имя. Минимум 3 символа.',
		en: 'Please enter a valid name. Minimum 3 characters.',
		de: 'Bitte geben Sie einen gültigen Namen ein. Mindestens 3 Zeichen.',
		es: 'Por favor, ingrese un nombre válido. Mínimo 3 caracteres.',
		tr: 'Lütfen geçerli bir isim girin. En az 3 karakter.',
		kk: 'Құ Valid есім енгізіңіз. Кем дегенде 3 таңба.',
		uz: `Iltimos, to'g'ri ism kiriting. Kamida 3 ta belgidan.`,
		az: 'Zəhmət olmasa düzgün bir ad daxil edin. Minimum 3 simvol.',
		ka: 'გთხოვთ, შეიყვანოთ ვალიდური სახელი. მინიმუმ 3 სიმბოლო.',
		pt: 'Por favor, insira um nome válido. Mínimo de 3 caracteres.'
	},
	PLEASE_ENTER_NAME_2: {
		ru: 'Пожалуйста, введите допустимое название. Минимум 3 символа.',
		en: 'Please enter a valid name. Minimum 3 characters.',
		de: 'Bitte geben Sie einen gültigen Namen ein. Mindestens 3 Zeichen.',
		es: 'Por favor, ingrese un nombre válido. Mínimo 3 caracteres.',
		tr: 'Lütfen geçerli bir isim girin. En az 3 karakter.',
		kk: 'Өтінемін, жарамды ат енгізіңіз. Ең кемінде 3 таңба.',
		uz: 'Iltimos, haqiqiy ism kiriting. Kamida 3 ta belgi.',
		az: 'Zəhmət olmasa, etibarlı bir ad daxil edin. Ən azı 3 simvol.',
		ka: 'გთხოვთ, შეიტანოთ ვალიდური სახელი. მინიმუმ 3 სიმბოლო.',
		pt: 'Por favor, insira um nome válido. Mínimo de 3 caracteres.'
	},
	PLEASE_ENTER_NAME_3: {
		ru: 'Пожалуйста, введите название',
		en: 'Please enter the name',
		de: 'Bitte geben Sie den Namen ein',
		es: 'Por favor, introduzca el nombre',
		tr: 'Lütfen adı girin',
		kk: 'Атын енгізіңіз',
		uz: 'Iltimos, nomini kiriting',
		az: 'Zəhmət olmasa, adı daxil edin',
		ka: 'გთხოვთ შეიყვანოთ სახელი',
		pt: 'Por favor, insira o nome'
	},
	PLEASE_ENTER_PARAMETER: {
		ru: 'Пожалуйста выберите переменную',
		en: 'Please select a variable',
		de: 'Bitte wählen Sie eine Variable',
		es: 'Por favor, seleccione un parámetro',
		tr: 'Lütfen bir değişken seçin',
		kk: 'Параметрді таңдаңыз',
		uz: 'Iltimos, parametrni tanlang',
		az: 'Zəhmət olmasa, parametr seçin',
		ka: 'გთხოვთ, აირჩიოთ პარამეტრი',
		pt: 'Por favor, selecione um parâmetro'
	},
	PLEASE_ENTER_PARAMETER_TEXT: {
		ru: 'Пожалуйста введите текст для переменной',
		en: 'Please enter text for the variable',
		de: 'Bitte geben Sie den Text für die Variable ein',
		es: 'Por favor, ingrese el texto para el parámetro',
		tr: 'Lütfen değişken için metin girin',
		kk: 'Параметр үшін мәтінді енгізіңіз',
		uz: 'Iltimos, parametr uchun matn kiriting',
		az: 'Zəhmət olmasa, parametr üçün mətn daxil edin',
		ka: 'გთხოვთ, შეიტანოთ ტექსტი პარამეტრისთვის',
		pt: 'Por favor, insira o texto para o parâmetro'
	},
	PLEASE_ENTER_PASSWORD: {
		ru: 'Пожалуйста введите пароль.',
		en: 'Please enter your password.',
		de: 'Bitte geben Sie Ihr Passwort ein.',
		es: 'Por favor ingrese su contraseña.',
		tr: 'Lütfen şifrenizi girin.',
		kk: 'Парольді енгізіңіз.',
		uz: 'Iltimos, parolingizni kiriting.',
		az: 'Zəhmət olmasa şifrənizi daxil edin.',
		ka: 'გთხოვთ, შეიყვანოთ პაროლი.',
		pt: 'Por favor, insira sua senha.'
	},
	PLEASE_ENTER_RESULT_TEXT: {
		ru: 'Пожалуйста, введите текст для ответа',
		en: 'Please enter the text for the response',
		de: 'Bitte geben Sie den Text für die Antwort ein',
		es: 'Por favor, ingrese el texto para la respuesta',
		tr: 'Lütfen yanıt metnini girin',
		kk: 'Жауап мәтінін енгізіңіз',
		uz: 'Iltimos, javob matnini kiriting',
		az: 'Zəhmət olmasa, cavab mətnini daxil edin',
		ka: 'გთხოვთ, შეიტანოთ პასუხის ტექსტი',
		pt: 'Por favor, insira o texto para a resposta'
	},
	PLEASE_ENTER_RETURN_EXAMPLE: {
		ru: 'Пожалуйста, введите пример ответа.',
		en: 'Please enter the return example.',
		de: 'Bitte geben Sie das Rückgabe-Beispiel ein.',
		es: 'Por favor, introduzca el ejemplo de retorno.',
		tr: 'Lütfen dönüş örneğini girin.',
		kk: 'Қайтару үлгісін енгізіңіз.',
		uz: 'Iltimos, qaytish misolini kiriting.',
		az: 'Zəhmət olmasa, qaytarma nümunəsini daxil edin.',
		ka: 'გთხოვთ, შეიყვანეთ დაბრუნების მაგალითი.',
		pt: 'Por favor, insira o exemplo de retorno.'
	},
	PLEASE_ENTER_SOURCE_ARRAY: {
		ru: 'Пожалуйста, введите название исходного массива.',
		en: 'Please enter the source array name.',
		de: 'Bitte geben Sie den Namen des Quellarrays ein.',
		es: 'Por favor, ingrese el nombre del array de origen.',
		tr: 'Lütfen kaynak dizi adını girin.',
		kk: 'Қатысушы массивтің атын енгізіңіз.',
		uz: 'Iltimos, manba massiv nomini kiriting.',
		az: 'Zəhmət olmasa, mənbə massivi adını daxil edin.',
		ka: 'გთხოვთ, შეიტანოთ წყაროს მასივის სახელი.',
		pt: 'Por favor, insira o nome do array de origem.'
	},
	PLEASE_ENTER_SOURCE_ELEMENT: {
		ru: 'Пожалуйста, введите название исходного элемента.',
		en: 'Please enter the source element name.',
		de: 'Bitte geben Sie den Namen des Quell-Elements ein.',
		es: 'Por favor, ingrese el nombre del elemento de origen.',
		tr: 'Lütfen kaynak öğe adını girin.',
		kk: 'Қатысушы элементтің атын енгізіңіз.',
		uz: 'Iltimos, manba element nomini kiriting.',
		az: 'Zəhmət olmasa, mənbə elementinin adını daxil edin.',
		ka: 'გთხოვთ, შეიტანოთ წყაროს ელემენტის სახელი.',
		pt: 'Por favor, insira o nome do elemento de origem.'
	},
	PLEASE_ENTER_SOURCE_VALUE: {
		ru: 'Пожалуйста, введите исходное значение',
		en: 'Please enter source value',
		de: 'Bitte geben Sie den Quellwert ein',
		es: 'Por favor, ingrese el valor fuente',
		tr: 'Lütfen kaynak değeri girin',
		kk: 'Дереккөз мәнін енгізіңіз',
		uz: 'Iltimos, manba qiymatini kiriting',
		az: 'Zəhmət olmasa mənbə dəyərini daxil edin',
		ka: 'გთხოვთ შეიყვანოთ წყაროს მნიშვნელობა',
		pt: 'Por favor, insira o valor de origem'
	},
	PLEASE_ENTER_TEXT: {
		ru: 'Пожалуйста, введите текст.',
		en: 'Please enter text.',
		de: 'Bitte geben Sie den Text ein.',
		es: 'Por favor, introduzca el texto.',
		tr: 'Lütfen metni girin.',
		kk: 'Мәтінді енгізіңіз.',
		uz: 'Iltimos, matnni kiriting.',
		az: 'Zəhmət olmasa, mətn daxil edin.',
		ka: 'გთხოვთ, შეიყვანოთ ტექსტი.',
		pt: 'Por favor, insira o texto.'
	},
	PLEASE_ENTER_URL: {
		ru: 'Пожалуйста, введите URL',
		en: 'Please enter URL',
		de: 'Bitte URL eingeben',
		es: 'Por favor, introduzca la URL',
		tr: 'Lütfen URL girin',
		kk: 'URL енгізіңіз',
		uz: 'Iltimos, URL kiriting',
		az: 'Zəhmət olmasa, URL daxil edin',
		ka: 'გთხოვთ შეიყვანოთ URL',
		pt: 'Por favor, insira o URL'
	},
	PLEASE_ENTER_USERNAME: {
		ru: 'Пожалуйста, введите допустимое имя пользователя. Минимум 3 символа.',
		en: 'Please enter a valid username. Minimum 3 characters.',
		de: 'Bitte geben Sie einen gültigen Benutzernamen ein. Mindestens 3 Zeichen.',
		es: 'Por favor, ingrese un nombre de usuario válido. Mínimo 3 caracteres.',
		tr: 'Lütfen geçerli bir kullanıcı adı girin. En az 3 karakter.',
		kk: 'Құ Valid есім енгізіңіз. Кем дегенде 3 таңба.',
		uz: `Iltimos, to'g'ri foydalanuvchi nomini kiriting. Kamida 3 ta belgi.`,
		az: 'Zəhmət olmasa düzgün bir istifadəçi adı daxil edin. Minimum 3 simvol.',
		ka: 'გთხოვთ, შეიყვანოთ ვალიდური მომხმარებლის სახელი. მინიმუმ 3 სიმბოლო.',
		pt: 'Por favor, insira um nome de usuário válido. Mínimo de 3 caracteres.'
	},
	PLEASE_ENTER_USERNAME_2: {
		ru: 'Пожалуйста введите имя пользователя.',
		en: 'Please enter your username.',
		de: 'Bitte geben Sie Ihren Benutzernamen ein.',
		es: 'Por favor ingrese su nombre de usuario.',
		tr: 'Lütfen kullanıcı adınızı girin.',
		kk: 'Пайдаланушы атын енгізіңіз.',
		uz: 'Iltimos, foydalanuvchi nomini kiriting.',
		az: 'Zəhmət olmasa istifadəçi adınızı daxil edin.',
		ka: 'გთხოვთ, შეიყვანოთ მომხმარებლის სახელი.',
		pt: 'Por favor, insira seu nome de usuário.'
	},
	PLEASE_SELECT_API: {
		ru: 'Пожалуйста, выберите API запрос из списка',
		en: 'Please select an API request from the list',
		de: 'Bitte wählen Sie eine API-Anfrage aus der Liste',
		es: 'Por favor, seleccione una solicitud de API de la lista',
		tr: 'Lütfen listeden bir API isteği seçin',
		kk: 'Тізімнен API сұрауын таңдаңыз',
		uz: `Iltimos, ro'yxatdan API so'rovini tanlang`,
		az: 'Zəhmət olmasa, siyahıdan bir API sorğusu seçin',
		ka: 'გთხოვთ, აირჩიოთ API მოთხოვნა სიიდან',
		pt: 'Por favor, selecione uma solicitação de API da lista'
	},
	PRIVILEGES: {
		ru: 'Привилегии',
		en: 'Privileges',
		de: 'Privilegien',
		es: 'Privilegios',
		tr: 'Ayrıcalıklar',
		kk: 'Артықшылықтар',
		uz: 'Privilegiya',
		az: 'Privilegiyalar',
		ka: 'პრივილეგიები',
		pt: 'Privilégios'
	},
	PROFILE: {
		ru: 'Профиль',
		en: 'Profile',
		de: 'Profil',
		es: 'Perfil',
		tr: 'Profil',
		kk: 'Профиль',
		uz: 'Profil',
		az: 'Profil',
		ka: 'პროფილი',
		pt: 'Perfil'
	},
	PROMPT: {
		ru: 'Промт',
		en: 'Prompt',
		de: 'Aufforderung',
		es: 'Aviso',
		tr: 'İpucu',
		kk: 'Нұсқау',
		uz: `Ko'rsatma`,
		az: 'Təlimat',
		ka: 'პრომპტი',
		pt: 'Prompt'
	},
	PROMPT_ASSISTANT: {
		ru: 'Ответ ассистента',
		en: `Assistant's response`,
		de: 'Antwort des Assistenten',
		es: 'Respuesta del asistente',
		tr: 'Asistanın yanıtı',
		kk: 'Көмекшінің жауабы',
		uz: 'Assistent javobi',
		az: 'Asistentin cavabı',
		ka: 'ასისტენტის პასუხი',
		pt: 'Resposta do assistente'
	},
	PROMPT_CANNOT_BE_EMPTY: {
		ru: 'Промпт не может быть пустым',
		en: 'Prompt cannot be empty',
		de: 'Die Eingabe darf nicht leer sein',
		es: 'El mensaje no puede estar vacío',
		tr: 'Prompt boş olamaz',
		kk: 'Промпт бос болмауы тиіс',
		uz: `Prompt bo'sh bo'lishi mumkin emas`,
		az: 'Prompt boş ola bilməz',
		ka: 'პრომპტი არ შეიძლება იყოს ცარიელი',
		pt: 'O prompt não pode estar vazio'
	},
	PROMPT_SYSTEM: {
		ru: 'Системный промпт',
		en: 'System prompt',
		de: 'Systemaufforderung',
		es: 'Indicador del sistema',
		tr: 'Sistem istemi',
		kk: 'Жүйелік промпт',
		uz: `Tizim so'rovi`,
		az: 'Sistem təklifi',
		ka: 'სისტემის პრომპტი',
		pt: 'Prompt do sistema'
	},
	PROMPT_USER: {
		ru: 'Промпт пользователя',
		en: 'User prompt',
		de: 'Benutzereingabe',
		es: 'Indicador del usuario',
		tr: 'Kullanıcı istemi',
		kk: 'Пайдаланушы промпті',
		uz: `Foydalanuvchi so'rovi`,
		az: 'İstifadəçi təklifi',
		ka: 'მომხმარებლის პრომპტი',
		pt: 'Prompt do usuário'
	},
	RESET_DIALOG: {
		ru: 'Обнулить диалог',
		en: 'Reset dialog',
		de: 'Dialog zurücksetzen',
		es: 'Restablecer diálogo',
		tr: 'Diyaloğu sıfırla',
		kk: 'Диалогты қайта орнату',
		uz: 'Dialogni qayta tiklash',
		az: 'Dialoqu sıfırla',
		ka: 'დიალოგის განულება',
		pt: 'Reiniciar diálogo'
	},
	PROMPTS: {
		ru: 'Промты',
		en: 'Prompts',
		de: 'Aufforderungen',
		es: 'Indicaciones',
		tr: 'İpuçları',
		kk: 'Нұсқамалар',
		uz: `Ko'rsatmalar`,
		az: 'Təlimatlar',
		ka: 'ინსტრუქციები',
		pt: 'Prompts'
	},
	RECORD: {
		ru: 'Запись',
		en: 'Record',
		de: 'Aufzeichnung',
		es: 'Registro',
		tr: 'Kayıt',
		kk: 'Жазба',
		uz: 'Yozuv',
		az: 'Qeyd',
		ka: 'ჩანაწერი',
		pt: 'Registro'
	},
	RECORDS: {
		ru: 'Записи',
		en: 'Records',
		de: 'Aufzeichnungen',
		es: 'Registros',
		tr: 'Kayıtlar',
		kk: 'Жазбалар',
		uz: 'Yozuvlar',
		az: 'Qeydlər',
		ka: 'ჩანაწერები',
		pt: 'Registros'
	},
	REQUEST: {
		ru: 'Запрос',
		en: 'Request',
		de: 'Anfrage',
		es: 'Solicitud',
		tr: 'İstek',
		kk: 'Сұрау',
		uz: 'So‘rov',
		az: 'Sorğu',
		ka: 'მოთხოვნა',
		pt: 'Requisição'
	},
	REQUIRED_FIELD: {
		ru: 'Обязательное поле',
		en: 'Required field',
		de: 'Pflichtfeld',
		es: 'Campo obligatorio',
		tr: 'Zorunlu alan',
		kk: 'Міндетті өріс',
		uz: 'Majburiy maydon',
		az: 'Mütləq sahə',
		ka: 'სავალდებულო ველი',
		pt: 'Campo obrigatório'
	},
	RESULT: {
		ru: 'Результат',
		en: 'Result',
		de: 'Ergebnis',
		es: 'Resultado',
		tr: 'Sonuç',
		kk: 'Нәтиже',
		uz: 'Natija',
		az: 'Nəticə',
		ka: 'შედეგი',
		pt: 'Resultado'
	},
	RESULT_DESCRIPTION: {
		ru: 'Отправляет пользователю текстовый ответ',
		en: 'Sends a text response to the user',
		de: 'Sendet eine Textantwort an den Benutzer',
		es: 'Envía una respuesta de texto al usuario',
		tr: 'Kullanıcıya metin yanıtı gönderir',
		kk: 'Пайдаланушыға мәтіндік жауап жібереді',
		uz: 'Foydalanuvchiga matnli javob yuboradi',
		az: 'İstifadəçiyə mətn cavabı göndərir',
		ka: 'გზავნის ტექსტურ პასუხს მომხმარებელს',
		pt: 'Envia uma resposta em texto para o usuário'
	},
	RESULT_DESCRIPTION_2: {
		ru: 'Предназначен для подготовки текстового ответа пользователю',
		en: 'Designed to prepare a text response for the user',
		de: 'Dient zur Vorbereitung einer Textantwort für den Benutzer',
		es: 'Diseñado para preparar una respuesta de texto para el usuario',
		tr: 'Kullanıcı için metin yanıtı hazırlamak üzere tasarlanmıştır',
		kk: 'Пайдаланушы үшін мәтіндік жауап дайындау үшін жасалған',
		uz: `Foydalanuvchi uchun matnli javob tayyorlash uchun mo'ljallangan`,
		az: 'İstifadəçi üçün mətn cavabı hazırlamaq üçün nəzərdə tutulub',
		ka: 'მიზანშეწონილია მომხმარებლისთვის ტექსტური პასუხის მომზადება',
		pt: 'Destinado a preparar uma resposta de texto para o usuário'
	},
	RESULT_TEXT: {
		ru: 'Текст для ответа',
		en: 'Text for response',
		de: 'Text für die Antwort',
		es: 'Texto para respuesta',
		tr: 'Yanıt için metin',
		kk: 'Жауап үшін мәтін',
		uz: 'Javob uchun matn',
		az: 'Cavab üçün mətn',
		ka: 'პასუხის ტექსტი',
		pt: 'Texto para resposta'
	},
	RETURN_EXAMPLE: {
		ru: 'Пример ответа',
		en: 'Return example',
		de: 'Rückgabe-Beispiel',
		es: 'Ejemplo de retorno',
		tr: 'Dönüş örneği',
		kk: 'Қайтару үлгісі',
		uz: 'Qaytish misoli',
		az: 'Qaytarma nümunəsi',
		ka: 'დაბრუნების მაგალითი',
		pt: 'Exemplo de retorno'
	},
	RETURN_EXAMPLE_HELP: {
		ru: 'Напишите пример ответа, который должен сформироваться после запуска кода',
		en: 'Write an example of the response that should be generated after running the code',
		de: 'Schreiben Sie ein Beispiel für die Antwort, die nach dem Ausführen des Codes generiert werden soll',
		es: 'Escriba un ejemplo de la respuesta que debería generarse después de ejecutar el código',
		tr: 'Kod çalıştırıldıktan sonra oluşturulması gereken yanıtın bir örneğini yazın',
		kk: 'Кодты іске қосқаннан кейін құрылуы керек жауаптың үлгісін жазыңыз',
		uz: `Kod ishga tushgandan keyin hosil bo'lishi kerak bo'lgan javobning namunasi yozing`,
		az: 'Kod işə salındıqdan sonra yaradılmalı olan cavab nümunəsini yazın',
		ka: 'დაწერეთ კოდის გაშვების შემდეგ შექმნილი პასუხის მაგალითი',
		pt: 'Escreva um exemplo da resposta que deve ser gerada após a execução do código'
	},
	RETURN_EXAMPLE_PLACEHOLDER: {
		ru: 'По вашему запросу у нас есть такие модели:\n1. Модель: ЛАДА Веста. Цена: 1000000 рублей. Цвет: белый. Год выпуска: 2020.\n2. Модель: ЛАДА Калина. Цена: 1250000 рублей. Цвет: чёрный. Год выпуска: 2023.\n3. Модель: ЛАДА Ларгус. Цена: 2000000 рублей. Цвет: серебристый. Год выпуска: 2024.',
		en: 'For your request, we have the following models:\n1. Model: LADA Vesta. Price: 1,000,000 rubles. Color: white. Year: 2020.\n2. Model: LADA Kalina. Price: 1,250,000 rubles. Color: black. Year: 2023.\n3. Model: LADA Largus. Price: 2,000,000 rubles. Color: silver. Year: 2024.',
		de: 'Für Ihre Anfrage haben wir folgende Modelle:\n1. Modell: LADA Vesta. Preis: 1.000.000 Rubel. Farbe: Weiß. Baujahr: 2020.\n2. Modell: LADA Kalina. Preis: 1.250.000 Rubel. Farbe: Schwarz. Baujahr: 2023.\n3. Modell: LADA Largus. Preis: 2.000.000 Rubel. Farbe: Silber. Baujahr: 2024.',
		es: 'Para su solicitud, tenemos los siguientes modelos:\n1. Modelo: LADA Vesta. Precio: 1.000.000 rublos. Color: blanco. Año: 2020.\n2. Modelo: LADA Kalina. Precio: 1.250.000 rublos. Color: negro. Año: 2023.\n3. Modelo: LADA Largus. Precio: 2.000.000 rublos. Color: plateado. Año: 2024.',
		tr: 'Talebinize göre şu modelleri sunuyoruz:\n1. Model: LADA Vesta. Fiyat: 1.000.000 ruble. Renk: beyaz. Yıl: 2020.\n2. Model: LADA Kalina. Fiyat: 1.250.000 ruble. Renk: siyah. Yıl: 2023.\n3. Model: LADA Largus. Fiyat: 2.000.000 ruble. Renk: gümüş. Yıl: 2024.',
		kk: 'Сіздің сұранысыңыз бойынша бізде келесі модельдер бар:\n1. Модель: ЛАДА Веста. Бағасы: 1 000 000 рубль. Түсі: ақ. Шыққан жылы: 2020.\n2. Модель: ЛАДА Калина. Бағасы: 1 250 000 рубль. Түсі: қара. Шыққан жылы: 2023.\n3. Модель: ЛАДА Ларгус. Бағасы: 2 000 000 рубль. Түсі: күміс. Шыққан жылы: 2024.',
		uz: 'Sizning so‘rovingizga ko‘ra bizda quyidagi modellari mavjud:\n1. Model: LADA Vesta. Narxi: 1 000 000 rubl. Rangi: oq. Yili: 2020.\n2. Model: LADA Kalina. Narxi: 1 250 000 rubl. Rangi: qora. Yili: 2023.\n3. Model: LADA Largus. Narxi: 2 000 000 rubl. Rangi: kumush. Yili: 2024.',
		az: 'Sorğunuz üçün bizdə aşağıdakı modellər var:\n1. Model: LADA Vesta. Qiymət: 1.000.000 rubl. Rəng: ağ. İl: 2020.\n2. Model: LADA Kalina. Qiymət: 1.250.000 rubl. Rəng: qara. İl: 2023.\n3. Model: LADA Largus. Qiymət: 2.000.000 rubl. Rəng: gümüş. İl: 2024.',
		ka: 'თქვენი მოთხოვნისთვის გვაქვს შემდეგი მოდელები:\n1. მოდელი: LADA Vesta. ფასი: 1,000,000 რუბლი. ფერი: თეთრი. წელი: 2020.\n2. მოდელი: LADA Kalina. ფასი: 1,250,000 რუბლი. ფერი: შავი. წელი: 2023.\n3. მოდელი: LADA Largus. ფასი: 2,000,000 რუბლი. ფერი: ვერცხლისფერი. წელი: 2024.',
		pt: 'Para o seu pedido, temos os seguintes modelos:\n1. Modelo: LADA Vesta. Preço: 1.000.000 rublos. Cor: branca. Ano: 2020.\n2. Modelo: LADA Kalina. Preço: 1.250.000 rublos. Cor: preta. Ano: 2023.\n3. Modelo: LADA Largus. Preço: 2.000.000 rublos. Cor: prata. Ano: 2024.'
	},
	ROLE: {
		ru: 'Роль',
		en: 'Role',
		de: 'Rolle',
		es: 'Rol',
		tr: 'Rol',
		kk: 'Роль',
		uz: 'Rol',
		az: 'Rol',
		ka: 'როლი',
		pt: 'Função'
	},
	RULE: {
		ru: 'Правило',
		en: 'Rule',
		de: 'Regel',
		es: 'Regla',
		tr: 'Kural',
		kk: 'Ереже',
		uz: 'Qoidalar',
		az: 'Qayda',
		ka: 'წესი',
		pt: 'Regra'
	},
	SAVE: {
		ru: 'Сохранить',
		en: 'Save',
		de: 'Speichern',
		es: 'Guardar',
		tr: 'Kaydet',
		kk: 'Сақтау',
		uz: 'Saqlash',
		az: 'Saxlamaq',
		ka: 'შენახვა',
		pt: 'Salvar'
	},
	SAVE_VARIABLES: {
		ru: 'Сохранение',
		en: 'Saving',
		de: 'Speichern',
		es: 'Guardado',
		tr: 'Kaydetme',
		kk: 'Сақтау',
		uz: 'Saqlash',
		az: 'Saxlama',
		ka: 'შენახვა',
		pt: 'Salvando'
	},
	SAVE_VARIABLES_DESCRIPTION: {
		ru: 'Сохраняет данные, полученные на предыдущих шагах, для дальнейшего использования',
		en: 'Saves data obtained from previous steps for future use',
		de: 'Speichert Daten, die aus den vorherigen Schritten erhalten wurden, zur späteren Verwendung',
		es: 'Guarda los datos obtenidos de los pasos anteriores para su uso futuro',
		tr: 'Önceki adımlardan elde edilen verileri gelecekte kullanmak için kaydeder',
		kk: 'Алдыңғы қадамдардан алынған мәліметтерді болашақта пайдалану үшін сақтайды',
		uz: `Oldingi bosqichlarda olingan ma'lumotlarni kelajakda ishlatish uchun saqlaydi`,
		az: 'Əvvəlki addımlardan alınan məlumatları gələcəkdə istifadə etmək üçün saxlayır',
		ka: 'შენახავს მონაცემებს, რომლებიც მიღებულია წინა ნაბიჯებიდან, მომავალი გამოყენებისთვის',
		pt: 'Salva os dados obtidos nas etapas anteriores para uso futuro'
	},
	SAVE_VARIABLES_DESCRIPTION_2: {
		ru: 'Сохраняет данные для дальнейшего использования',
		en: 'Saves data for future use',
		de: 'Speichert Daten zur späteren Verwendung',
		es: 'Guarda los datos para su uso futuro',
		tr: 'Verileri gelecekte kullanmak için kaydeder',
		kk: 'Деректерді болашақта пайдалану үшін сақтайды',
		uz: `Ma'lumotlarni kelajakda ishlatish uchun saqlaydi`,
		az: 'Məlumatları gələcəkdə istifadə etmək üçün saxlayır',
		ka: 'შენახავს მონაცემებს, რომლებიც გამოყენებულია მომავალი გამოყენებისთვის',
		pt: 'Salva os dados para uso futuro'
	},
	SAVING: {
		ru: 'Сохраняется ...',
		en: 'Saving ...',
		de: 'Speichern ...',
		es: 'Guardando ...',
		tr: 'Kaydediliyor ...',
		kk: 'Сақталуда ...',
		uz: 'Saqlanmoqda ...',
		az: 'Saxlanılır ...',
		ka: 'მორგება ...',
		pt: 'Salvando ...'
	},
	SCENARIO_CREATED: {
		ru: 'Сценарий успешно создан.',
		en: 'Scenario successfully created.',
		de: 'Szenario erfolgreich erstellt.',
		es: 'Escenario creado con éxito.',
		tr: 'Senaryo başarıyla oluşturuldu.',
		kk: 'Сценарий сәтті құрылды.',
		uz: 'Ssenariy muvaffaqiyatli yaratildi.',
		az: 'Ssenari uğurla yaradıldı.',
		ka: 'სცენარი წარმატებით შეიქმნა.',
		pt: 'Cenário criado com sucesso.'
	},
	SCENARIO_DELETED: {
		ru: 'Сценарий успешно удалён.',
		en: 'Scenario successfully deleted.',
		de: 'Szenario erfolgreich gelöscht.',
		es: 'Escenario eliminado con éxito.',
		tr: 'Senaryo başarıyla silindi.',
		kk: 'Сценарий сәтті жойылды.',
		uz: 'Ssenariy muvaffaqiyatli o‘chirildi.',
		az: 'Ssenari uğurla silindi.',
		ka: 'სცენარი წარმატებით წაიშალა.',
		pt: 'Cenário excluído com sucesso.'
	},
	SCENARIO_DELETION: {
		ru: 'Удаление сценария',
		en: 'Scenario deletion',
		de: 'Szenario löschen',
		es: 'Eliminación de escenario',
		tr: 'Senaryo Silme',
		kk: 'Сценарийді жою',
		uz: `Ssenariyni o'chirish`,
		az: 'Ssenariyanı silmək',
		ka: 'სცენარის წაშლა',
		pt: 'Exclusão de cenário'
	},
	SCENARIO_DESCRIPTION: {
		ru: 'Описание сценария',
		en: 'Scenario description',
		de: 'Szenariobeschreibung',
		es: 'Descripción del escenario',
		tr: 'Senaryo açıklaması',
		kk: 'Сценарий сипаттамасы',
		uz: 'Ssenariy tavsifi',
		az: 'Ssenariy təsviri',
		ka: 'სცენარის აღწერა',
		pt: 'Descrição do cenário'
	},
	SCENARIO_NAME: {
		ru: 'Название сценария',
		en: 'Scenario name',
		de: 'Szenarienname',
		es: 'Nombre del escenario',
		tr: 'Senaryo Adı',
		kk: 'Сценарий атауы',
		uz: 'Ssenariy nomi',
		az: 'Ssenari adı',
		ka: 'სცენარის სახელი',
		pt: 'Nome do cenário'
	},
	SCENARIO_SELECTION: {
		ru: 'Выбор сценария при обработке сообщений от пользователя',
		en: 'Scenario selection when processing messages from the user',
		de: 'Auswahl des Szenarios bei der Verarbeitung von Nachrichten vom Benutzer',
		es: 'Selección de escenario al procesar mensajes del usuario',
		tr: 'Kullanıcıdan gelen mesajları işlerken senaryo seçimi',
		kk: 'Пайдаланушыдан келген хабарларды өңдеу кезінде сценарийді таңдау',
		uz: 'Foydalanuvchidan xabarlarni qayta ishlashda stsenariy tanlash',
		az: 'İstifadəçidən mesajları işləyərkən ssenari seçimi',
		ka: 'მომხმარებლის შეტყობინებების დამუშავებისას სცენარის არჩევა',
		pt: 'Seleção de cenário ao processar mensagens do usuário'
	},
	SCENARIO_UPDATED: {
		ru: 'Сценарий успешно обновлён.',
		en: 'Scenario successfully updated.',
		de: 'Szenario erfolgreich aktualisiert.',
		es: 'Escenario actualizado con éxito.',
		tr: 'Senaryo başarıyla güncellendi.',
		kk: 'Сценарий сәтті жаңартылды.',
		uz: 'Ssenariy muvaffaqiyatli yangilandi.',
		az: 'Ssenari uğurla yeniləndi.',
		ka: 'სცენარი წარმატებით განახლდა.',
		pt: 'Cenário atualizado com sucesso.'
	},
	SCENARIO_VERSION_CREATED: {
		ru: 'Версия сценария успешно создана.',
		en: 'Scenario version successfully created.',
		de: 'Szenarioversion erfolgreich erstellt.',
		es: 'Versión del escenario creada con éxito.',
		tr: 'Senaryo sürümü başarıyla oluşturuldu.',
		kk: 'Сценарий нұсқасы сәтті құрылды.',
		uz: 'Ssenariy versiyasi muvaffaqiyatli yaratildi.',
		az: 'Ssenari versiyası uğurla yaradıldı.',
		ka: 'სცენარის ვერსია წარმატებით შეიქმნა.',
		pt: 'Versão do cenário criada com sucesso.'
	},
	SCENARIOS: {
		ru: 'Сценарии',
		en: 'Scenarios',
		de: 'Szenarien',
		es: 'Escenarios',
		tr: 'Senaryolar',
		kk: 'Сценарийлер',
		uz: 'Ssenariylar',
		az: 'Ssenarilər',
		ka: 'სცენარები',
		pt: 'Cenários'
	},
	SCENARIOS_NOT_FOUND: {
		ru: 'Не найдено ни одного сценария.',
		en: 'No scenarios found.',
		de: 'Keine Szenarien gefunden.',
		es: 'No se encontraron escenarios.',
		tr: 'Hiç senaryo bulunamadı.',
		kk: 'Ешқандай сценарий табылмады.',
		uz: 'Hech qanday senariy topilmadi.',
		az: 'Heç bir ssenari tapılmadı.',
		ka: 'არანაირი სცენარი ვერ მოიძებნა.',
		pt: 'Nenhum cenário encontrado.'
	},
	SCHEMA: {
		ru: 'Схема ответа',
		en: 'Response Schema',
		de: 'Antwortschema',
		es: 'Esquema de respuesta',
		tr: 'Yanıt Şeması',
		kk: 'Жауап схемасы',
		uz: 'Javob sxemasi',
		az: 'Cavab sxemi',
		ka: 'პასუხის სქემა',
		pt: 'Esquema de Resposta'
	},
	SCHEMA_ERROR: {
		ru: 'Схема ответа: Объект JSON недействителен',
		en: 'Response Schema: JSON object is invalid',
		de: 'Antwortschema: JSON-Objekt ist ungültig',
		es: 'Esquema de respuesta: El objeto JSON no es válido',
		tr: 'Yanıt Şeması: JSON nesnesi geçersiz',
		kk: 'Жауап схемасы: JSON объекті жарамсыз',
		uz: 'Javob sxemasi: JSON obyekti noto‘g‘ri',
		az: 'Cavab sxemi: JSON obyekti yanlışdır',
		ka: 'პასუხის სქემა: JSON ობიექტი არასწორია',
		pt: 'Esquema de Resposta: Objeto JSON inválido'
	},
	SEARCH: {
		ru: 'Поиск ...',
		en: 'Search ...',
		de: 'Suche ...',
		es: 'Buscar ...',
		tr: 'Arama ...',
		kk: 'Іздеу ...',
		uz: 'Qidirish ...',
		az: 'Axtarış ...',
		ka: 'ძიება ...',
		pt: 'Pesquisar ...'
	},
	SOMETHING_WENT_WRONG: {
		ru: 'Что-то пошло не так.',
		en: 'Something went wrong.',
		de: 'Etwas ist schief gelaufen.',
		es: 'Algo salió mal.',
		tr: 'Bir şeyler yanlış gitti.',
		kk: 'Бір нәрсе дұрыс болмады.',
		uz: 'Nimadir xato ketdi.',
		az: 'Bir şey səhv oldu.',
		ka: 'გაფუჭდა რაღაც.',
		pt: 'Algo deu errado.'
	},
	SOURCE_ARRAY: {
		ru: 'Исходный массив',
		en: 'Source array',
		de: 'Quell-Array',
		es: 'Matriz de origen',
		tr: 'Kaynak dizi',
		kk: 'Бастапқы массив',
		uz: 'Manba massiv',
		az: 'Mənbə massiv',
		ka: 'წყარო მასივი',
		pt: 'Matriz de origem'
	},
	SOURCE_VALUE: {
		ru: 'Исходное значение',
		en: 'Source value',
		de: 'Quellwert',
		es: 'Valor fuente',
		tr: 'Kaynak değeri',
		kk: 'Бастапқы мән',
		uz: 'Asosiy qiymat',
		az: 'Mənbə dəyəri',
		ka: 'წყაროს მნიშვნელობა',
		pt: 'Valor de origem'
	},
	START: {
		ru: 'Старт',
		en: 'Start',
		de: 'Start',
		es: 'Inicio',
		tr: 'Başlangıç',
		kk: 'Бастау',
		uz: 'Boshlash',
		az: 'Başla',
		ka: 'დაწყება',
		pt: 'Iniciar'
	},
	START_DESCRIPTION: {
		ru: 'Начальный шаг, который активирует процесс обработки данных. Здесь пользователь выбирает, какую операцию выполнить.',
		en: 'The initial step that activates the data processing. Here the user chooses which operation to perform.',
		de: 'Der erste Schritt, der den Datenverarbeitungsprozess aktiviert. Hier wählt der Benutzer aus, welche Operation ausgeführt werden soll.',
		es: 'El paso inicial que activa el procesamiento de datos. Aquí el usuario elige qué operación realizar.',
		tr: 'Veri işleme sürecini etkinleştiren ilk adım. Burada kullanıcı hangi işlemi yapacağını seçer.',
		kk: 'Деректер өңдеу процесін іске қосатын бастапқы қадам. Мұнда пайдаланушы қандай операцияны орындау керектігін таңдайды.',
		uz: `Ma'lumotlarni qayta ishlash jarayonini faollashtiradigan dastlabki qadam. Bu yerda foydalanuvchi qanday operatsiyani bajarishni tanlaydi.`,
		az: 'Məlumat emalı prosesini aktivləşdirən ilkin addım. Burada istifadəçi hansı əməliyyatı yerinə yetirəcəyini seçir.',
		ka: 'შესავალი ნაბიჯი, რომელიც გააქტიურებს მონაცემთა დამუშავების პროცესს. აქ მომხმარებელი ირჩევს რომელ ოპერაციას შეასრულოს.',
		pt: 'O passo inicial que ativa o processamento de dados. Aqui o usuário escolhe qual operação realizar.'
	},
	START_DESCRIPTION_SHORT: {
		ru: 'Начальный шаг, который активирует процесс обработки данных',
		en: 'The initial step that activates the data processing',
		de: 'Der erste Schritt, der den Datenverarbeitungsprozess aktiviert',
		es: 'El paso inicial que activa el procesamiento de datos',
		tr: 'Veri işleme sürecini etkinleştiren ilk adım',
		kk: 'Деректер өңдеу процесін іске қосатын бастапқы қадам',
		uz: `Ma'lumotlarni qayta ishlash jarayonini faollashtiradigan dastlabki qadam`,
		az: 'Məlumat emalı prosesini aktivləşdirən ilkin addım',
		ka: 'დაწყებითი ნაბიჯი, რომელიც გააქტიურებს მონაცემთა დამუშავების პროცესს',
		pt: 'O passo inicial que ativa o processamento de dados'
	},
	SYMBOL_COUNT: {
		ru: 'Количество символов для подгрузки истории',
		en: 'Number of characters to load history',
		de: 'Anzahl der Zeichen zum Laden der Geschichte',
		es: 'Número de caracteres para cargar el historial',
		tr: 'Geçmişi yüklemek için karakter sayısı',
		kk: 'Тарихты жүктеу үшін символдар саны',
		uz: 'Tarixni yuklash uchun belgilar soni',
		az: 'Tarixi yükləmək üçün simvolların sayı',
		ka: 'ისტორიის ჩამოტვირთვისთვის სიმბოლოების რაოდენობა',
		pt: 'Número de caracteres para carregar o histórico'
	},
	SYMBOL_COUNT_ERROR: {
		ru: 'Количество символов для подгрузки истории должно быть больше нуля',
		en: 'The number of characters for loading history must be greater than zero',
		de: 'Die Anzahl der Zeichen zum Laden der Geschichte muss größer als null sein',
		es: 'El número de caracteres para cargar el historial debe ser mayor que cero',
		tr: 'Geçmişi yüklemek için karakter sayısı sıfırdan büyük olmalıdır',
		kk: 'Тарихты жүктеу үшін таңбалар саны нөлден көп болуы керек',
		uz: `Tarixni yuklash uchun belgi soni nolga teng bo'lmasligi kerak`,
		az: 'Tarixin yüklənməsi üçün simvolların sayı sıfırdan böyük olmalıdır',
		ka: 'ისტორიის დატვირთვისთვის სიმბოლოების რაოდენობა უნდა იყოს ნული',
		pt: 'O número de caracteres para carregar o histórico deve ser maior que zero'
	},
	TAG: {
		ru: 'Тег',
		en: 'Tag',
		de: 'Tag',
		es: 'Etiqueta',
		tr: 'Etiket',
		kk: 'Тег',
		uz: 'Teg',
		az: 'Etiket',
		ka: 'ჭდე',
		pt: 'Etiqueta'
	},
	TEST: {
		ru: 'Тестировать',
		en: 'Test',
		de: 'Testen',
		es: 'Probar',
		tr: 'Test Et',
		kk: 'Тестілеу',
		uz: 'Sinovdan o‘tkazish',
		az: 'Test etmək',
		ka: 'შემოწმება',
		pt: 'Testar'
	},
	TEST_TERMINAL: {
		ru: 'Тестовый терминал',
		en: 'Test terminal',
		de: 'Testterminal',
		es: 'Terminal de prueba',
		tr: 'Test terminali',
		kk: 'Тест терминалы',
		uz: 'Test terminali',
		az: 'Test terminali',
		ka: 'ტესტური ტერმინალი',
		pt: 'Terminal de teste'
	},
	TO: {
		ru: 'До',
		en: 'To',
		de: 'Bis',
		es: 'Hasta',
		tr: 'Bitiş',
		kk: 'Дейін',
		uz: 'Gacha',
		az: 'Son',
		ka: 'მდე',
		pt: 'Até'
	},
	TOKEN_COPIED: {
		ru: 'Токен сценария успешно скопирован в буфер обмена.',
		en: 'The script token has been successfully copied to the clipboard.',
		de: 'Das Skript-Token wurde erfolgreich in die Zwischenablage kopiert.',
		es: 'El token del script se ha copiado correctamente al portapapeles.',
		tr: `Script token'ı başarıyla panoya kopyalandı.`,
		kk: 'Сценарий тізбегінің белгісі сәтті буферге көшірілді.',
		uz: `Skript tokeni muvaffaqiyatli ravishda clipboard'ga nusxalandi.`,
		az: 'Ssenari tokeni müvəffəqiyyətlə kopyalandı.',
		ka: 'სკრიპტის ტოკენი წარმატებით შეიცვალა剪切ბურკვე.',
		pt: 'O token do script foi copiado com sucesso para a área de transferência.'
	},
	UPDATE: {
		ru: 'Обновить',
		en: 'Update',
		de: 'Aktualisieren',
		es: 'Actualizar',
		tr: 'Güncelle',
		kk: 'Жаңарту',
		uz: 'Yangilash',
		az: 'Yeniləyin',
		ka: 'განახლება',
		pt: 'Atualizar'
	},
	UPDATE_SCENARIO: {
		ru: 'Обновить настройки сценария',
		en: 'Update scenario settings',
		de: 'Szenarioeinstellungen aktualisieren',
		es: 'Actualizar configuración del escenario',
		tr: 'Senaryo ayarlarını güncelle',
		kk: 'Сценарий параметрлерін жаңарту',
		uz: 'Ssenariy sozlamalarini yangilash',
		az: 'Ssenari parametrlərini yeniləyin',
		ka: 'განახლეთ სცენარის პარამეტრები',
		pt: 'Atualizar configurações do cenário'
	},
	UPDATE_USER: {
		ru: 'Обновить настройки пользователя',
		en: 'Update user settings',
		de: 'Benutzereinstellungen aktualisieren',
		es: 'Actualizar la configuración del usuario',
		tr: 'Kullanıcı ayarlarını güncelle',
		kk: 'Пайдаланушы параметрлерін жаңарту',
		uz: 'Foydalanuvchi sozlamalarini yangilash',
		az: 'İstifadəçi parametrlərini yeniləyin',
		ka: 'მომხმარებლის პარამეტრების განახლება',
		pt: 'Atualizar configurações do usuário'
	},
	USER_CREATED: {
		ru: 'Пользователь успешно создан.',
		en: 'User successfully created.',
		de: 'Benutzer erfolgreich erstellt.',
		es: 'Usuario creado con éxito.',
		tr: 'Kullanıcı başarıyla oluşturuldu.',
		kk: 'Пайдаланушы сәтті құрылды.',
		uz: 'Foydalanuvchi muvaffaqiyatli yaratildi.',
		az: 'İstifadəçi uğurla yaradıldı.',
		ka: 'მომხმარებელი წარმატებით შეიქმნა.',
		pt: 'Usuário criado com sucesso.'
	},
	USER_DELETED: {
		ru: 'Пользователь успешно удалён.',
		en: 'User successfully deleted.',
		de: 'Benutzer erfolgreich gelöscht.',
		es: 'Usuario eliminado con éxito.',
		tr: 'Kullanıcı başarıyla silindi.',
		kk: 'Пайдаланушы сәтті жойылды.',
		uz: 'Foydalanuvchi muvaffaqiyatli o‘chirildi.',
		az: 'İstifadəçi uğurla silindi.',
		ka: 'მომხმარებელი წარმატებით წაიშალა.',
		pt: 'Usuário excluído com sucesso.'
	},
	USER_DELETION: {
		ru: 'Удаление пользователя',
		en: 'User deletion',
		de: 'Benutzerlöschung',
		es: 'Eliminación de usuario',
		tr: 'Kullanıcı silme',
		kk: 'Пайдаланушыны жою',
		uz: 'Foydalanuvchini o‘chirish',
		az: 'İstifadəçinin silinməsi',
		ka: 'მომხმარებლის წაშლა',
		pt: 'Exclusão de usuário'
	},
	USER_UPDATED: {
		ru: 'Пользователь успешно обновлён.',
		en: 'User successfully updated.',
		de: 'Benutzer erfolgreich aktualisiert.',
		es: 'Usuario actualizado con éxito.',
		tr: 'Kullanıcı başarıyla güncellendi.',
		kk: 'Пайдаланушы сәтті жаңартылды.',
		uz: 'Foydalanuvchi muvaffaqiyatli yangilandi.',
		az: 'İstifadəçi uğurla yeniləndi.',
		ka: 'მომხმარებელი წარმატებით განახლდა.',
		pt: 'Usuário atualizado com sucesso.'
	},
	USERNAME: {
		ru: 'Имя пользователя',
		en: 'Username',
		de: 'Benutzername',
		es: 'Nombre de usuario',
		tr: 'Kullanıcı adı',
		kk: 'Пайдаланушы аты',
		uz: 'Foydalanuvchi nomi',
		az: 'İstifadəçi adı',
		ka: 'მომხმარებლის სახელი',
		pt: 'Nome de usuário'
	},
	USERS: {
		ru: 'Пользователи',
		en: 'Users',
		de: 'Benutzer',
		es: 'Usuarios',
		tr: 'Kullanıcılar',
		kk: 'Пайдаланушылар',
		uz: 'Foydalanuvchilar',
		az: 'İstifadəçilər',
		ka: 'მომხმარებლები',
		pt: 'Usuários'
	},
	USERS_NOT_FOUND: {
		ru: 'Не найдено ни одного пользователя.',
		en: 'No users found.',
		de: 'Keine Benutzer gefunden.',
		es: 'No se encontraron usuarios.',
		tr: 'Kullanıcı bulunamadı.',
		kk: 'Бірде-бір пайдаланушы табылмады.',
		uz: 'Hech qanday foydalanuvchi topilmadi.',
		az: 'Heç bir istifadəçi tapılmadı.',
		ka: 'ვერც ერთი მომხმარებელი ვერ მოიძებნა.',
		pt: 'Nenhum usuário encontrado.'
	},
	VALUE: {
		ru: 'Значение',
		en: 'Value',
		de: 'Wert',
		es: 'Valor',
		tr: 'Değer',
		kk: 'Мән',
		uz: 'Qiymat',
		az: 'Dəyər',
		ka: 'მნიშვნელობა',
		pt: 'Valor'
	},
	VALUE_TO_SAVE: {
		ru: 'Значение для сохранения',
		en: 'Value to save',
		de: 'Wert zum Speichern',
		es: 'Valor para guardar',
		tr: 'Kaydedilecek Değer',
		kk: 'Сақталатын мән',
		uz: 'Saqlash uchun qiymat',
		az: 'Saxlanacaq dəyər',
		ka: 'შენახვისთვის მნიშვნელობა',
		pt: 'Valor para salvar'
	},
	VALUES: {
		ru: 'Значения',
		en: 'Values',
		de: 'Werte',
		es: 'Valores',
		tr: 'Değerler',
		kk: 'Мәндер',
		uz: 'Qiymatlar',
		az: 'Dəyərlər',
		ka: 'მნიშვნელობები',
		pt: 'Valores'
	},
	VARIABLE_DESC_CANNOT_BE_EMPTY: {
		ru: 'Описание переменной не может быть пустым',
		en: 'Variable description cannot be empty',
		de: 'Beschreibung der Variable darf nicht leer sein',
		es: 'La descripción de la variable no puede estar vacía',
		tr: 'Değişken açıklaması boş olamaz',
		kk: 'Айнымалының сипаттамасы бос болмауы керек',
		uz: `O'zgaruvchi tavsifi bo'sh bo'lmasligi kerak`,
		az: 'Dəyişənin təsviri boş ola bilməz',
		ka: 'მოცემის აღწერა ცარიელი არ შეიძლება იყოს',
		pt: 'A descrição da variável não pode estar vazia'
	},
	VARIABLE_EXAMPLE_CANNOT_BE_EMPTY: {
		ru: 'Пример переменной не может быть пустым',
		en: 'Variable example cannot be empty',
		de: 'Beispiel für die Variable darf nicht leer sein',
		es: 'El ejemplo de la variable no puede estar vacío',
		tr: 'Değişken örneği boş olamaz',
		kk: 'Айнымалының мысалы бос болмауы керек',
		uz: `O'zgaruvchi misoli bo'sh bo'lmasligi kerak`,
		az: 'Dəyişənin nümunəsi boş ola bilməz',
		ka: 'მოცემის მაგალითი ცარიელი არ შეიძლება იყოს',
		pt: 'O exemplo de variável não pode estar vazio'
	},
	VARIABLE_NAME_CANNOT_BE_EMPTY: {
		ru: 'Название переменной не может быть пустым',
		en: 'Variable name cannot be empty',
		de: 'Variablenname darf nicht leer sein',
		es: 'El nombre de la variable no puede estar vacío',
		tr: 'Değişken adı boş olamaz',
		kk: 'Айнымалының атауы бос болмауы керек',
		uz: `O'zgaruvchi nomi bo'sh bo'lmasligi kerak`,
		az: 'Dəyişən adı boş ola bilməz',
		ka: 'მოცემის სახელი ცარიელი არ შეიძლება იყოს',
		pt: 'O nome da variável não pode estar vazio'
	},
	VARIABLE_SAVE: {
		ru: 'Сохранение переменной',
		en: 'Variable Save',
		de: 'Variable speichern',
		es: 'Guardar variable',
		tr: 'Değişken Kaydet',
		kk: 'Айнымалыны сақтау',
		uz: 'O‘zgaruvchini saqlash',
		az: 'Dəyişəni yadda saxlamaq',
		ka: 'ცვლადის შენახვა',
		pt: 'Salvar Variável'
	},
	VERSION_DELETED: {
		ru: 'Версия сценария успешно удалена',
		en: 'The scenario version has been successfully deleted',
		de: 'Die Szenarioversion wurde erfolgreich gelöscht',
		es: 'La versión del escenario se ha eliminado con éxito',
		tr: 'Senaryo sürümü başarıyla silindi',
		kk: 'Сценарий нұсқасы сәтті жойылды',
		uz: 'Ssenariy versiyasi muvaffaqiyatli o‘chirildi',
		az: 'Ssenari versiyası uğurla silindi',
		ka: 'სცენარის ვერსია წარმატებით წაიშალა',
		pt: 'A versão do cenário foi excluída com sucesso'
	},
	VERSION_DELETE_QUESTION: {
		ru: 'Вы действительно хотите удалить версию сценария?',
		en: 'Are you sure you want to delete the scenario version?',
		de: 'Möchten Sie die Szenarioversion wirklich löschen?',
		es: '¿Estás seguro de que deseas eliminar la versión del escenario?',
		tr: 'Senaryo sürümünü silmek istediğinizden emin misiniz?',
		kk: 'Сценарий нұсқасын жоюды шынымен қалайсыз ба?',
		uz: 'Senariy versiyasini o‘chirishni xohlaysizmi?',
		az: 'Ssenari versiyasını silmək istədiyinizə əminsiniz?',
		ka: 'დარწმუნებული ხართ, რომ გსურთ სცენარის ვერსიის წაშლა?',
		pt: 'Tem certeza de que deseja excluir a versão do cenário?'
	},
	VERSION_DELETION: {
		ru: 'Удаление версии сценария',
		en: 'Deleting scenario version',
		de: 'Szenarioversion wird gelöscht',
		es: 'Eliminando versión del escenario',
		tr: 'Senaryo sürümü siliniyor',
		kk: 'Сценарий нұсқасы жойылуда',
		uz: 'Senariy versiyasi o‘chirilyapti',
		az: 'Ssenari versiyası silinir',
		ka: 'სცენარის ვერსიის წაშლა მიმდინარეობს',
		pt: 'Excluindo versão do cenário'
	},
	VERSION_MANAGE: {
		ru: 'Управление версиями',
		en: 'Version Management',
		de: 'Versionsverwaltung',
		es: 'Gestión de versiones',
		tr: 'Sürüm Yönetimi',
		kk: 'Нұсқаларды басқару',
		uz: 'Versiyalarni boshqarish',
		az: 'Versiya idarəetməsi',
		ka: 'ვერსიების მართვა',
		pt: 'Gerenciamento de Versões'
	},
	VERSION_SAVE: {
		ru: 'Сохранить версию',
		en: 'Save Version',
		de: 'Version speichern',
		es: 'Guardar versión',
		tr: 'Sürümü Kaydet',
		kk: 'Нұсқаны сақтау',
		uz: 'Versiyani saqlash',
		az: 'Versiyanı yadda saxla',
		ka: 'ვერსიის შენახვა',
		pt: 'Salvar Versão'
	},
	VIEW: {
		ru: 'Просмотр',
		en: 'View',
		de: 'Ansicht',
		es: 'Ver',
		tr: 'Görüntüle',
		kk: 'Көру',
		uz: `Ko'rish`,
		az: 'Baxış',
		ka: 'მოსახედვა',
		pt: 'Visualizar'
	},
	VIEW_EDIT: {
		ru: 'Просмотр / Изменение',
		en: 'View / Edit',
		de: 'Ansicht / Bearbeiten',
		es: 'Ver / Editar',
		tr: 'Görüntüle / Düzenle',
		kk: 'Көру / Өңдеу',
		uz: `Ko'rish / Tahrirlash`,
		az: 'Baxış / Düzəliş',
		ka: 'მოსახედვა / რედაქტირება',
		pt: 'Visualizar / Editar'
	},
	VIEW_RULES: {
		ru: 'Правила отображения',
		en: 'Display Rules',
		de: 'Anzeige-Regeln',
		es: 'Reglas de visualización',
		tr: 'Görüntüleme Kuralları',
		kk: 'Көрсету ережелері',
		uz: `Ko'rsatish qoidalari`,
		az: 'Göstərmə qaydaları',
		ka: 'მოჩვენების წესები',
		pt: 'Regras de exibição'
	},
	WAIT_UNTIL_PROCESS_END: {
		ru: 'Пожалуйста, подождите пока завершится текущий тестовый процесс.',
		en: 'Please wait until the current testing process is complete.',
		de: 'Bitte warten Sie, bis der aktuelle Testprozess abgeschlossen ist.',
		es: 'Por favor, espere hasta que termine el proceso de prueba actual.',
		tr: 'Lütfen mevcut test süreci tamamlanana kadar bekleyin.',
		kk: 'Ағымдағы тестілеу процесі аяқталғанша күтіңіз.',
		uz: 'Iltimos, joriy test jarayoni tugaguncha kuting.',
		az: 'Zəhmət olmasa, cari test prosesi başa çatana qədər gözləyin.',
		ka: 'გთხოვთ, დაელოდოთ მიმდინარე ტესტირების პროცესის დასრულებას.',
		pt: 'Por favor, aguarde até que o processo de teste atual seja concluído.'
	},
	WEBHOOK: {
		ru: 'Вебхук',
		en: 'Webhook',
		de: 'Webhook',
		es: 'Webhook',
		tr: 'Webhook',
		kk: 'Webhook',
		uz: 'Veb-xabarnoma',
		az: 'Vebhuq',
		ka: 'Webhook',
		pt: 'Webhook'
	},
	WEBHOOK_ADD: {
		ru: 'Добавление вебхука',
		en: 'Add Webhook',
		de: 'Webhook hinzufügen',
		es: 'Añadir Webhook',
		tr: 'Webhook Ekle',
		kk: 'Webhook қосу',
		uz: 'Webhook qo‘shish',
		az: 'Webhook əlavə etmək',
		ka: 'ვებჰუკის დამატება',
		pt: 'Adicionar Webhook'
	},
	WEBHOOK_CONDITIONAL: {
		ru: 'Условный вебхук',
		en: 'Conditional webhook',
		de: 'Bedingter Webhook',
		es: 'Webhook condicional',
		tr: 'Koşullu webhook',
		kk: 'Шартты вебхук',
		uz: 'Shartli webhook',
		az: 'Şərtli webhook',
		ka: 'პროგრამული ვებჰუკი',
		pt: 'Webhook condicional'
	},
	WEBHOOK_EDIT: {
		ru: 'Редактирование вебхука',
		en: 'Edit Webhook',
		de: 'Webhook bearbeiten',
		es: 'Editar Webhook',
		tr: `Webhook'u düzenle`,
		kk: 'Webhook-ты өңдеу',
		uz: 'Webhook-ni tahrirlash',
		az: 'Webhook-u redaktə etmək',
		ka: 'ვებჰუკის რედაქტირება',
		pt: 'Editar Webhook'
	},
	WEBHOOKS: {
		ru: 'Вебхуки',
		en: 'Webhooks',
		de: 'Webhooks',
		es: 'Webhooks',
		tr: `Webhook'lar`,
		kk: 'Вебхуктер',
		uz: 'Veb-xabarnomalar',
		az: 'Vebhuqlar',
		ka: 'ვებჰუქები',
		pt: 'Webhooks'
	},
	WEEKDAYS: {
		ru: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
		en: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
		de: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
		es: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
		tr: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
		kk: ['Дүйсенбі', 'Сейсенбі', 'Сәрсенбі', 'Бейсенбі', 'Жұма', 'Сенбі', 'Жексенбі'],
		uz: ['Dushanba', 'Seshanba', 'Chorshanba', 'Payshanba', 'Juma', 'Shanba', 'Yakshanba'],
		az: ['Bazar ertəsi', 'Çərşənbə axşamı', 'Çərşənbə', 'Cümə axşamı', 'Cümə', 'Şənbə', 'Bazar'],
		ka: ['ორშაბათი', 'სამშაბათი', 'ოთხშაბათი', 'ხუთშაბათი', 'პარასკევი', 'შაბათი', 'კვირა'],
		pt: ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo']
	},
	WELCOME: {
		ru: 'Добро пожаловать!',
		en: 'Welcome!',
		de: 'Willkommen!',
		es: '¡Bienvenido!',
		tr: 'Hoş geldiniz!',
		kk: 'Қош келдіңіз!',
		uz: 'Xush kelibsiz!',
		az: 'Xoş gəlmisiniz!',
		ka: 'კეთილი იყოს თქვენი მობრძანება!',
		pt: 'Bem-vindo!'
	},
	WORKING_TIME: {
		ru: 'Рабочее время',
		en: 'Working time',
		de: 'Arbeitszeit',
		es: 'Horario de trabajo',
		tr: 'Çalışma süresi',
		kk: 'Жұмыс уақыты',
		uz: 'Ish vaqti',
		az: 'İş vaxtı',
		ka: 'სამუშაო დრო',
		pt: 'Horário de trabalho'
	},
	YES: {
		ru: 'Да',
		en: 'Yes',
		de: 'Ja',
		es: 'Sí',
		tr: 'Evet',
		kk: 'Иә',
		uz: 'Ha',
		az: 'Bəli',
		ka: 'დიახ',
		pt: 'Sim'
	},
	YOU_DID_NOT_ADD_START_COMPONENT: {
		ru: 'Вы не добавили стартовый компонент.',
		en: 'You did not add the start component.',
		de: 'Sie haben den Startkomponenten nicht hinzugefügt.',
		es: 'No agregaste el componente de inicio.',
		tr: 'Başlangıç bileşenini eklemediniz.',
		kk: 'Сіз бастау компонентін қосқан жоқсыз.',
		uz: `Siz boshlang'ich komponentni qo'shmadingiz.`,
		az: 'Başlanğıc komponentini əlavə etmədiniz.',
		ka: 'თქვენ არ დაემატათ დაწყების კომპონენტი.',
		pt: 'Você não adicionou o componente de início.'
	}
}

export default LITERALS