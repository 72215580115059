import io from 'socket.io-client'

import { ACTION_TYPES } from './actions/socket'
import { extractJSONL } from './helpers/utils'

let socket = null
let timeout = null

const socketOnOpen = () => {
	clearTimeout(timeout)
}

const topics = {}

function connect(url, store) {
	socket = io(url, { transports: ['websocket'] })
	socket.on('connect', socketOnOpen)
	socket.on('disconnect', reason => {
		socket = null
		if (!['io client disconnect', 'io server disconnect'].includes(reason))
			timeout = setTimeout(function () { connect(url, store) }, 1000)
	})
	socket.on('message', payload => {
		try {
			if (topics.hasOwnProperty(payload.topic))
				topics[payload.topic] = `${topics[payload.topic]}${payload.message}`

			const { history, index } = extractJSONL(topics[payload.topic])
			if (index > -1)
				topics[payload.topic] = topics[payload.topic].slice(index + 1, topics[payload.topic].length)

			store.dispatch({ type: ACTION_TYPES.LOG_TRIGGERED, topic: payload.topic, history })
		} catch { }
	})
}

const socketFunction = store => next => action => {
	switch (action.type) {
		case ACTION_TYPES.CONNECT_SOCKET_IO:
			if (socket === null)
				connect(action.payload.url, store)
			break
		case ACTION_TYPES.DISCONNECT_SOCKET_IO:
			if (socket)
				socket.disconnect()
			break
		case ACTION_TYPES.SUBSCRIBE_TOPIC:
			if (socket) {
				topics[action.topic] = ''
				socket.emit('subscribe', action.topic, _ => { })
			}
			break
		case ACTION_TYPES.UNSUBSCRIBE_TOPIC:
			if (socket) {
				delete topics[action.topic]
				socket.emit('unsubscribe', action.topic, _ => {})
			}
			break
		default:
			break
	}
	return next(action)
}

export default socketFunction