import React, { useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material'
import { Close, DeleteForever, FileCopyRounded, FolderRounded, OpenInNewRounded, SaveRounded } from '@mui/icons-material'

import CircularBackdrop from '../custom/circular-backdrop'
import LITERALS from '../../helpers/literals'

const FilesDialog = ({
	canWrite,
	language,
	cancel,
	files,
	loading,
	updating,
	deleting,
	upload,
	update,
	remove
}) => {
	const ref = useRef(null)

	const uploadData = e => {
		if (e.target.files.length > 0)
			upload([...e.target.files])
		e.target.value = ''
	}

	return (
		<Dialog open={true} maxWidth='md' fullWidth={true}>
			<DialogTitle sx={styles.title}>
				<Typography variant='title' fontWeight='bold'>{LITERALS.FILE_MANAGER[language]}</Typography>
				<IconButton aria-label='close' onClick={cancel} sx={styles.closeIcon}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Box sx={styles.box}>
					{
						files.length === 0
							?
							<Box sx={styles.noData}>
								<FolderRounded sx={styles.noDataIcon} />
								<Typography variant='title' sx={styles.noDataText} fontWeight='bold'>{LITERALS.NOTHING_HERE[language]}</Typography>
								{canWrite && <UploadButton language={language} inputRef={ref} upload={uploadData} />}
							</Box>
							:
							<Box sx={styles.box}>
								{canWrite && <Box sx={styles.items}>
									<UploadButton language={language} inputRef={ref} upload={uploadData} />
								</Box>}
								<Box sx={styles.files}>
									{files
										.sort((a, b) => new Date(a.createdAt || 0) < new Date(b.createdAt || 0) ? 1 : -1)
										.map((file, key) => (
											<File
												key={key}
												canWrite={canWrite}
												language={language}
												{...file}
												update={update}
												updating={updating.includes(file.id)}
												remove={remove}
												deleting={deleting.includes(file.id)}
											/>
										))}
								</Box>
							</Box>
					}
				</Box>
				<CircularBackdrop open={loading} position='absolute' />
			</DialogContent>
		</Dialog>
	)
}

const UploadButton = ({ language, inputRef, upload }) => <>
	<input ref={inputRef} type='file' id='upload-files' multiple accept='*' style={styles.input} onChange={upload} />
	<label htmlFor='upload-files'>
		<Box sx={styles.uploadButton}>
			<Typography variant='pageTitle'>{LITERALS.CHOOSE_FILES[language]}</Typography>
			<FileCopyRounded sx={styles.icon} />
		</Box>
	</label>
</>

const File = ({ canWrite, language, id, tag, name, link, update, updating, remove, deleting }) => {
	const [_tag, setTag] = useState(tag)

	useEffect(() => { setTag(tag) }, [tag])

	return <Box sx={styles.row}>
		<Typography sx={styles.name} noWrap>{name}</Typography>
		{
			canWrite
				?
				<TextField sx={styles.tag} placeholder={LITERALS.TAG[language]} value={_tag} onChange={e => setTag(e.target.value)} />
				:
				<Typography sx={styles.tag} noWrap>{_tag}</Typography>
		}

		<IconButton color='success' onClick={() => { window.open(link, '_blank') }}>
			<OpenInNewRounded />
		</IconButton>

		{canWrite && <IconButton color='success' onClick={() => { update(id, _tag) }} disabled={ _tag.length < 3 || _tag === tag || deleting}>
			{updating ? <CircularProgress color='inherit' size={24} /> : <SaveRounded />}
		</IconButton>}
		{canWrite && <IconButton color='error' onClick={() => { remove(id) }} disabled={!canWrite || updating}>
			{deleting ? <CircularProgress color='inherit' size={24} /> : <DeleteForever />}
		</IconButton>}
	</Box>
}

const styles = {
	title: {
		color: theme => theme.palette.primary.heading,
		m: 0,
		p: 2
	},
	closeIcon: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: theme => theme.palette.primary.heading
	},
	box: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	noData: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		p: 2,
		mb: 5
	},
	noDataText: {
		mb: 2
	},
	noDataIcon: {
		mb: 2,
		fontSize: 40,
		color: theme => theme.palette.primary.gray
	},
	input: {
		display: 'none'
	},
	uploadButton: {
		cursor: 'pointer',
		width: 200,
		height: 32,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme => theme.palette.primary.resultColor,
		color: theme => theme.palette.primary.contrastText,
		fontSize: 14,
		lineHeight: '16px',
		borderRadius: '100px'
	},
	icon: {
		fontSize: 18,
		margin: '0 0 0 8px !important'
	},
	items: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
		pb: 2,
		overflowX: 'auto'
	},
	files: {
		width: '100%',
		display: 'flex',
		px: 3,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 2
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		mt: 2,
		px: 2.5,
		py: 1.5,
		border: theme => `1px solid ${theme.palette.primary.border}`,
		borderRadius: '10px'
	},
	name: {
		width: 'calc(50% - 100px)',
		fontWeight: 'bold'
	},
	tag: {
		width: 'calc(50% - 52px)'
	}
}

export default FilesDialog