import { ACTION_TYPES } from '../actions/files'

const initialState = {
	files: [],
	message: '',
	error: '',
	fetching: false,
	uploading: false,
	updating: [],
	deleting: []
}

const scenarios = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_FILES_INIT:
			return {
				...state,
				files: [],
				fetching: true,
				message: '',
				error: ''
			}
		case ACTION_TYPES.FETCH_FILES_SUCCESS:
			return {
				...state,
				files: JSON.parse(JSON.stringify(action.data)),
				fetching: false
			}
		case ACTION_TYPES.FETCH_FILES_FAILED:
			return {
				...state,
				error: action.error,
				fetching: false
			}
		case ACTION_TYPES.UPLOAD_FILES_INIT:
			return {
				...state,
				uploading: true,
				message: '',
				error: ''
			}
		case ACTION_TYPES.UPLOAD_FILES_SUCCESS:
			return {
				...state,
				files: JSON.parse(JSON.stringify([...state.files, ...action.data])),
				uploading: false,
				message: action.message
			}
			case ACTION_TYPES.UPLOAD_FILES_FAILED:
			return {
				...state,
				uploading: false,
				error: action.error
			}
		case ACTION_TYPES.UPDATE_FILE_INIT:
			return {
				...state,
				error: '',
				updating: [...state.updating, action.fileId],
			}
		case ACTION_TYPES.UPDATE_FILE_SUCCESS:
			return {
				...state,
				files: JSON.parse(JSON.stringify([...state.files.map(f => ({ ...f, tag: f.id === action.fileId ? action.tag : f.tag }))])),
				updating: [...state.updating.filter(f => f !== action.fileId)]
			}
		case ACTION_TYPES.UPDATE_FILE_FAILED:
			return {
				...state,
				updating: [...state.updating.filter(f => f !== action.fileId)],
				error: action.error,
			}
		case ACTION_TYPES.DELETE_FILES_INIT:
			return {
				...state,
				error: '',
				message: '',
				deleting: [...state.deleting, action.fileId],
			}
		case ACTION_TYPES.DELETE_FILES_SUCCESS:
			return {
				...state,
				message: action.message,
				files: JSON.parse(JSON.stringify([...state.files.filter(f => f.id !== action.fileId)])),
				deleting: [...state.deleting.filter(f => f !== action.fileId)]
			}
		case ACTION_TYPES.DELETE_FILES_FAILED:
			return {
				...state,
				error: action.error,
				deleting: [...state.deleting.filter(f => f !== action.fileId)]
			}
		case ACTION_TYPES.RESET_MESSAGES:
			return {
				...state,
				message: '',
				error: ''
			}
		default:
			return state
	}
}

export default scenarios