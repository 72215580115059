import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { AccessTime } from '@mui/icons-material'

import NodeTitle from '../custom/node-title'
import NodeBody from '../custom/node-body'
import NodeButton from '../custom/node-button'
import NodeHandles from '../custom/node-handles'
import { inputHandles, outputHandles } from '../../helpers/constants'
import LITERALS from '../../helpers/literals'

const DelayNode = ({ data }) => {
	const theme = useTheme()

	const styles = useCallback(() => {
		return {
			main: {
				width: 240,
				minHeight: 80,
				borderRadius: '16px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				boxShadow: `0px 8px 10px 0px ${theme.palette.primary.black}1A`,
				p: 1,
				opacity: data.focused ? 1 : 0.3,
				border: `2px solid ${data.logged ? theme.palette.success.main : `${theme.palette.primary.black}33`}`,
				background: data.logged ? `${theme.palette.success.main}33` : theme.palette.primary.delayColor,
			},
			mainInError: {
				width: 240,
				minHeight: 80,
				borderRadius: '16px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				p: 1,
				opacity: data.focused ? 1 : 0.3,
				border: `2px solid ${theme.palette.primary.border}`,
				background: `${theme.palette.error.main}33`,
				animation: 'blink 1s',
				animationIterationCount: 'infinite',
				'@keyframes blink ': {
					'50%': {
						background: `${theme.palette.error.main}99`,
						borderColor: theme => theme.palette.error.main
					}
				}
			}
		}
	}, [data.focused, data.logged, theme])

	return <Box sx={data.inError ? styles().mainInError : styles().main}>
		<NodeTitle
			title={LITERALS.DELAY[data.language]}
			color={(data.logged && !data.inError) ? 'primary.black' : 'primary.contrastText'}
		/>
		<NodeBody
			Icon={data.description ? null : AccessTime}
			title={data.description || ''}
			color={theme.palette.primary.black}
		/>
		<NodeHandles
			background={theme.palette.primary.delayColor}
			handles={[...inputHandles, ...outputHandles]}
		/>
		<NodeButton
			innerColor={theme.palette.primary.delayColor}
			outerColor={theme.palette.primary.contrastText}
		/>
	</Box>
}

export default DelayNode