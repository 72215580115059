import axios from 'axios'
import { logout } from './login'
import LITERALS from '../helpers/literals'

export const ACTION_TYPES = {
	FETCH_FILES_INIT: 'FETCH_FILES_INIT',
	FETCH_FILES_SUCCESS: 'FETCH_FILES_SUCCESS',
	FETCH_FILES_FAILED: 'FETCH_FILES_FAILED',
	UPLOAD_FILES_INIT: 'UPLOAD_FILES_INIT',
	UPLOAD_FILES_SUCCESS: 'UPLOAD_FILES_SUCCESS',
	UPLOAD_FILES_FAILED: 'UPLOAD_FILES_FAILED',
	UPDATE_FILE_INIT: 'UPDATE_FILE_INIT',
	UPDATE_FILE_SUCCESS: 'UPDATE_FILE_SUCCESS',
	UPDATE_FILE_FAILED: 'UPDATE_FILE_FAILED',
	DELETE_FILES_INIT: 'DELETE_FILES_INIT',
	DELETE_FILES_SUCCESS: 'DELETE_FILES_SUCCESS',
	DELETE_FILES_FAILED: 'DELETE_FILES_FAILED',
	RESET_MESSAGES: 'RESET_MESSAGES'
}

export const fetch = (scenarioId, historyPusher) => {
	return async (dispatch) => {
		dispatch({ type: ACTION_TYPES.FETCH_FILES_INIT })
		try {
			const response = await axios({
				method: 'get',
				url: `/scenarios/${scenarioId}/files`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
			})
			dispatch({ type: ACTION_TYPES.FETCH_FILES_SUCCESS, data: response.data.data })
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.FETCH_FILES_FAILED, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const upload = (scenarioId, files, historyPusher) => {
	return async (dispatch, getState) => {
		dispatch({ type: ACTION_TYPES.UPLOAD_FILES_INIT })
		try {
			const data = new FormData()
			for (let i = 0; i < files.length; i++) {
				data.append('files', files[i])
			}

			const response = await axios({
				method: 'post',
				url: `/scenarios/${scenarioId}/files`,
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
					'Content-Type': 'multipart/form-data'
				},
				data
			})

			dispatch({
				type: ACTION_TYPES.UPLOAD_FILES_SUCCESS,
				data: response.data,
				message: LITERALS.FILES_UPLOADED[getState().profile.language]
			})

			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.UPLOAD_FILES_FAILED, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const update = (scenarioId, fileId, tag, historyPusher) => {
	return async dispatch => {
		try {
			dispatch({ type: ACTION_TYPES.UPDATE_FILE_INIT, fileId })
			await axios({
				method: 'put',
				url: `/scenarios/${scenarioId}/files/${fileId}`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`
				},
				data: { tag }
			})

			dispatch({ type: ACTION_TYPES.UPDATE_FILE_SUCCESS, fileId, tag })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		} catch (err) {
			if (err?.response?.status === 401)
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.UPDATE_FILE_FAILED, fileId, error: err?.response?.data?.message || err.message })
		}
	}
}

export const remove = (scenarioId, fileId, historyPusher) => {
	return async (dispatch, getState) => {
		dispatch({ type: ACTION_TYPES.DELETE_FILES_INIT, fileId })
		try {
			await axios({
				method: 'delete',
				url: `/scenarios/${scenarioId}/files/${fileId}`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` },
			})
			dispatch({ type: ACTION_TYPES.DELETE_FILES_SUCCESS, fileId, message: LITERALS.FILES_DELETED[getState().profile.language] })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.DELETE_FILES_FAILED, fileId, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}