import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Dataflow from '../../components/dataflow'
import { check, generate } from '../../actions/ai'
import { get, reset, test, update, getVersions, getVersion, upsertVersion, removeVersion } from '../../actions/scenarios'
import { fetch as fetchFiles, upload, update as updateFile, remove as removeFile } from '../../actions/files'

const mapStateToProps = state => {
	return {
		language: state.profile.language,
		code: state.ai.code,
		generating: state.ai.generating,
		aiError: state.ai.error,
		aiMessage: state.ai.message,
		optimizedCode: state.ai.optimizedCode,
		checking: state.ai.checking,
		scenarioPrivileges: state.profile.scenarios,
		scenarios: state.scenarios.scenarios,
		scenario: state.scenarios.scenario,
		assistants: state.scenarios.assistants,
		models: state.scenarios.models,
		fetching: state.scenarios.fetching,
		updating: state.scenarios.updating,
		recipient: state.scenarios.recipient,
		testHistory: state.scenarios.history,
		testing: state.scenarios.testing,
		versionLoading: state.scenarios.versionLoading,
		versions: state.scenarios.versions,
		error: state.scenarios.error,
		message: state.scenarios.message,
		files: state.files.files,
		filesMessage: state.files.message,
		filesError: state.files.error,
		filesFetching: state.files.fetching,
		filesUploading: state.files.uploading,
		filesUpdating: state.files.updating,
		filesDeleting: state.files.deleting
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		generate: data => dispatch(generate(data, ownProps.history.push)),
		check: data => dispatch(check(data, ownProps.history.push)),
		get: id => dispatch(get(id, ownProps.history.push)),
		update: data => dispatch(update(data, ownProps.history.push)),
		fetchFiles: id => dispatch(fetchFiles(id, ownProps.history.push)),
		upload: (id, files) => dispatch(upload(id, files, ownProps.history.push)),
		updateFile: (scenarioId, id, tag) => dispatch(updateFile(scenarioId, id, tag, ownProps.history.push)),
		removeFile: (scenarioId, id) => dispatch(removeFile(scenarioId, id, ownProps.history.push)),
		getVersions: id => dispatch(getVersions(id, ownProps.history.push)),
		getVersion: (scenarioId, id) => dispatch(getVersion(scenarioId, id, ownProps.history.push)),
		upsertVersion: (id, data) => dispatch(upsertVersion(id, data, ownProps.history.push)),
		removeVersion: (scenarioId, id) => dispatch(removeVersion(scenarioId, id, ownProps.history.push)),
		test: data => dispatch(test(data, ownProps.history.push)),
		reset: () => dispatch(reset())
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dataflow))