import { createTheme } from '@mui/material/styles'

const theme = createTheme({
	breakpoints: { values: { xs: 0, sm: 860, md: 1080, lg: 1440, xl: 2000 } },
	shape: { borderRadius: 4 },
	typography: {
		button: { fontWeight: 400 },
		fontFamily: 'Nunito',
		body1: {
			fontSize: 16,
			lineHeight: '22.5px'
		},
		body2: {
			fontSize: 14,
			lineHeight: '20.5px'
		},
		body3: {
			fontSize: 12,
			lineHeight: '15.6px'
		},
		moduleTitle: {
			fontSize: 16,
			lineHeight: '26px'
		},
		title: {
			fontSize: 20,
			lineHeight: '24px'
		},
		h1: {
			fontSize: 32.5,
			lineHeight: '37.263px'
		},
		h2: {
			fontSize: 26,
			lineHeight: '30.3662px'
		},
		h3: {
			fontSize: 22.8,
			lineHeight: '26.9179px'
		},
		h4: {
			fontSize: 18,
			lineHeight: '20px'
		},
		h5: {
			fontSize: 16.25,
			lineHeight: '19.5px'
		},
		h6: {
			fontSize: 14,
			lineHeight: '16px'
		}
	},
	palette: {
		mode: 'light',
		primary: {
			main: '#0014ff',
			dark: '#364574',
			light: '#F3F6F9',
			contrastText: '#FFFFFF',
			border: '#CDD0D5',
			disabled: '#868C98',
			divider: '#E2E4E9',
			chat: '#ECECEC',
			black: '#000000',
			gray: '#525866',
			gray2: '#F3F4F6',
			avatarBox: '#C2D6FF',
			avatar: '#375DFB',
			portShadow: '#1B1C1D',
			startColor1: '#162664',
			startColor2: '#CAC2FF',
			startColor3: '#EEEBFF',
			scenarioColor1: '#6E330C',
			scenarioColor2: '#FFDAC2',
			scenarioColor3: '#FEF3EB',
			apiColor1: '#620F6C',
			apiColor2: '#F9C2FF',
			apiColor3: '#FDEBFF',
			runApiColor: '#0E9384',
			checkConditionsColor: '#7839EE',
			checkParamsColor: '#088AB2',
			resultColor: '#E04F16',
			chatAIColor1: '#EA507C',
			chatAIColor2: '#5B61FF',
			filterColor: '#D444F1',
			mapperColor: '#D92D20',
			delayColor: '#CEA07E',
			saveVariableColor: '#2E90FA'
		},
		secondary: {
			main: '#3577F1',
			dark: '#878A99',
			light: '#FFFFFF',
			contrastText: '#FFFFFF'
		},
		background: {
			default: '#FFFFFF',
			paper: '#F3F6F9'
		},
		error: {
			main: '#E31B54'
		},
		success: {
			main: '#0AB39C'
		},
		info: {
			main: '#299CDB'
		},
		warning: {
			main: '#F7B84B'
		},
		text: {
			primary: '#0A0D14',
			secondary: '#14151A',
			success: '#099885',
			info: '#2385BA',
			warning: '#D29C40',
			danger: '#CC563D',
			light: '#CED4DA',
			dark: '#343A40'
		}
	},
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true
			},
			styleOverrides: {
				root: {
					textTransform: 'none'
				},
				sizeSmall: {
					padding: '4px 8px',
					fontSize: 11.375,
					lineHeight: '17.0625px',
					whiteSpace: 'nowrap'
				},
				sizeMedium: {
					padding: '12px 16px',
					fontSize: 14,
					lineHeight: '16px',
					whiteSpace: 'nowrap',
					'& .MuiSvgIcon-root': {
						fontSize: 16,
						marginRight: '8px'
					}
				},
				sizeLarge: {
					padding: '11.2px 19.2px',
					fontSize: 16.25,
					lineHeight: '24.375px',
					whiteSpace: 'nowrap'
				},
				textSizeSmall: {
					padding: '4px 8px'
				},
				textSizeMedium: {
					padding: '8.5px 14.4px'
				},
				textSizeLarge: {
					padding: '11.2px 19.2px'
				},
				containedPrimary: {
					backgroundColor: '#0014ff',
					color: '#FFFFFF',
					'&:hover': {
						backgroundColor: '#0014ffbf'
					},
					'&:active': {
						backgroundColor: '#0014ff'
					}
				},
				containedSecondary: {
					backgroundColor: '#FFFFFF',
					color: '#525866',
					'&:hover': {
						backgroundColor: '#E2E4E9'
					},
					'&:active': {
						backgroundColor: '#FFFFFF'
					}
				},
				containedInherit: {
					border: '1px solid #E2E4E9',
					backgroundColor: '#FFFFFF',
					color: '#525866',
					'&:hover': {
						backgroundColor: '#FFFFFF'
					},
					'&:active': {
						backgroundColor: '#FFFFFF'
					}
				}
			}
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					padding: '8px'
				}
			}
		},
		MuiCssBaseline: {
			styleOverrides: {
				'*': {
					boxSizing: 'border-box',
					margin: 0,
					padding: 0
				},
				html: {
					MozOsxFontSmoothing: 'grayscale',
					WebkitFontSmoothing: 'antialiased',
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100%',
					width: '100%',
					minWidth: '600px'
				},
				'#root': {
					display: 'flex',
					flex: '1 1 auto',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
					'&::backdrop': {
						background: 'rgba(0,0,0,0)'
					}
				},
				body: {
					display: 'flex',
					flex: '1 1 auto',
					flexDirection: 'column',
					minHeight: '100%',
					width: '100%',
					backgroundColor: `#F3F6F9`,
					userSelect: 'none',
					'&::-webkit-scrollbar, & *::-webkit-scrollbar': {
						width: 5,
						height: 5
					},
					'&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
						borderRadius: 5,
						backgroundColor: `#CED4DA`,
						minHeight: 24
					},
					'&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
						backgroundColor: `#CED4DA`
					}
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'&.MuiInputBase-root': {
						fontSize: 14,
						lineHeight: '18px',
						fontWeight: 400,
						'& input': {
							height: 18,
							padding: '11px 12px'
						},
						'input, textarea': {
							'&::placeholder': {
								color: '#868C98'
							}
						},
						'& fieldset': {
							borderColor: '#E2E4E9',
							borderRadius: 12
						}
					}
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: '#FFFFFF'
				}
			}
		}
	}
})

export default theme