import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Switch, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

import LITERALS from '../../helpers/literals'
import Dropdown from './dropdown'
import { modelLangs } from '../../helpers/constants'
import TextArea from './text-area'

const ResultAIDialog = ({ language, assistants, models, config, cancel, update }) => {
	const [enabled, setEnabled] = useState(true)
	const [lang, setLang] = useState('auto')
	const [assistant, setAssistant] = useState('')
	const [promt, setPromt] = useState('')
	const [_models, setModels] = useState([])

	useEffect(() => {
		setEnabled(!!config.enabled)
		setLang(config.lang || 'auto')
		setAssistant(config.assistant || '')
		setPromt(config.promt || '')
		setModels(config.models || [])
	}, [config])

	return (
		<Dialog open={true} maxWidth='sm' fullWidth={true}>
			<DialogTitle sx={styles.title}>
				<Typography variant='title' fontWeight='bold'>{LITERALS.AI_SETTINGS[language]}</Typography>
				<IconButton aria-label='close' onClick={cancel} sx={styles.closeIcon}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Box sx={styles.box}>
					{
						models.length > 0 && <>
							<Box sx={styles.items}>
								<Typography variant='h4' fontWeight='bold'>{LITERALS.KNOWLEDGE_BASES[language]}</Typography>
							</Box>

							<Box sx={styles.box}>
								<Grid container>
									{
										models.map((model, key) => (
											<Grid item xs={12} md={12} lg={6} xl={4} key={key}>
												<Box sx={styles.checkbox}>
													<Checkbox
														checked={_models.includes(model)}
														onChange={() => setModels(_models.includes(model) ? _models.filter(m => m !== model) : [..._models, model])}
													/>
													<Typography variant='body2'>{model}</Typography>
												</Box>
											</Grid>
										))
									}
								</Grid>

								<Box sx={styles.divider} />
							</Box>
						</>
					}

					<Box sx={styles.items}>
						<Typography variant='h4' fontWeight='bold'>{LITERALS.ASSISTANT_SETTINGS[language]}</Typography>
					</Box>

					<Box sx={styles.box}>
						<Grid container>
							{
								assistants.map((assist, key) => (
									<Grid item xs={12} md={12} lg={6} xl={4} key={key}>
										<Box sx={styles.checkbox}>
											<Checkbox
												checked={assistant === assist}
												onChange={() => setAssistant(assistant === assist ? '' : assist)}
											/>
											<Typography variant='body2'>{assist}</Typography>
										</Box>
									</Grid>
								))
							}
						</Grid>
					</Box>

					<Box sx={styles.row}>
						<Typography sx={styles.text}>{LITERALS.LANGUAGE[language]}</Typography>
						<Dropdown selected={lang} items={modelLangs(language)} onChange={item => setLang(item)} />
					</Box>

					<Box sx={styles.row}>
						<TextArea
							rows={10}
							placeholder={`${LITERALS.PROMPT[language]}: Here is the additional information "\${this.info}".`}
							value={promt}
							onChange={e => setPromt(e.target.value)}
						/>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions sx={styles.actions}>
				<Box sx={styles.switch}>
					<Switch checked={enabled} onChange={e => { setEnabled(e.target.checked) }} />
					<Typography variant='h6'>{LITERALS.ACTIVATE[language]}</Typography>
				</Box>

				<Button variant='contained' onClick={() => { update({ enabled, lang, assistant, promt, models: _models }) }}>
					{LITERALS.SAVE[language]}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const styles = {
	title: {
		color: theme => theme.palette.primary.heading,
		m: 0,
		p: 2
	},
	actions: {
		p: 2,
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between'
	},
	switch: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'start'
	},
	divider: {
		width: '100%',
		my: 2,
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	checkbox: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		pl: 4
	},
	closeIcon: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: theme => theme.palette.primary.heading
	},
	box: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	items: {
		width: '100%',
		height: 49,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 1,
		px: 1.5,
		overflowX: 'auto'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		px: 2.5,
		pt: 1.5,
		pb: 2.5,
		borderRadius: '10px'
	},
	text: {
		mr: 2
	}
}

export default ResultAIDialog